<template>
  <div>
    <a-modal
      :open="isOpen"
      @cancel="handleCancel"
      :footer="null"
      :maskClosable="false"
      :destroyOnClose="true"
      :width="350"
    >
      <template #title>
        <div :class="$style.title">签到成功：</div>
      </template>
      <div :class="$style.bgcWrap">钱包：{{ amountInfo?.amount }}元</div>
      <div :class="$style.centerText">
        <div :class="$style.textBold">恭喜你今天签到成功，获得{{ amountInfo?.sign_dkj }}元</div>
        <div>您还有{{ amountInfo?.deduction_amount }}元没有使用</div>
      </div>
      <div :class="$style.bgcWrap">抵扣金直接用于购买游戏</div>
    </a-modal>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

// 接收父组件传递的 props
defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  amountInfo: {
    type: Object,
    required: true,
  },
});

// 定义事件用于通知父组件
const emit = defineEmits(["update:isOpen"]);

// 关闭弹窗的操作
const handleCancel = () => {
  emit("update:isOpen", false); // 通知父组件关闭弹窗
};
</script>

<style module>
  .title {
    font-weight: 600;
    font-size: 20px;
  }
  .bgcWrap {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #faeede;
  }
  .centerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .textBold {
    font-weight: bold;
    margin-bottom: 10px;
  }
</style>
