<template>
  <!-- <el-carousel height="400px"> -->
  <el-carousel>
    <el-carousel-item v-for="item in items" :key="item">
      <img :src="item" alt="carousel image" style="width: 100%; height: 100%;" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      items: [
        'https://via.placeholder.com/1200x600?text=Slide+1',
        'https://via.placeholder.com/1200x600?text=Slide+2',
        'https://via.placeholder.com/1200x600?text=Slide+3'
      ]
    };
  }
};
</script>
