<template>
  <div>
    <a-modal
      :open="isOpen"
      title="临时cdk延期"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <template #footer>
        <div :class="$style.modalBtnW">
          <a-button @click="onClickNew" :loading="isNewLoading"
            >生成新账号</a-button
          >
          <a-button type="primary" @click="onClickOld" :loading="isOldLoading"
            >原有账号续期</a-button
          >
        </div>
      </template>
      <a-form
        :model="formState"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
      >
        <a-form-item label="兑换cdk" name="CDK" v-bind="validateInfos.CDK">
          <a-input v-model:value="formState.CDK" placeholder="请输入CDK" />
        </a-form-item>
      </a-form>
    </a-modal>
    <DelaySuccessModal
      :is-open="isDelaySuccessOpen"
      :end-time="endTime"
      :set-isOpen="
        (flag) => {
          isDelaySuccessOpen = flag;
        }
      "
    />
  </div>
</template>
<script setup>
import { reactive, ref } from "vue";
import { Form } from "ant-design-vue";
import axios from "@/axios";

import DelaySuccessModal from "./DelaySuccessModal.vue";

const props = defineProps({
  isOpen: Boolean,
  setIsOpen: Function,
  resetList: Function,
  rentPkId: Number,
});

const useForm = Form.useForm;
const isNewLoading = ref(false);
const isOldLoading = ref(false);
const isDelaySuccessOpen = ref(false);
const endTime = ref();

const formState = reactive({
  CDK: "",
});
const rulesRef = reactive({
  CDK: [
    {
      required: true,
      message: "请输入CDK",
    },
  ],
});

const { validate, validateInfos, resetFields } = useForm(formState, rulesRef);

const onClickNew = async () => {
  const values = await validate();
  isNewLoading.value = true;
  const res = await axios
    .post("web/all_cdk_use_gen_new/", {
      cdkey: values?.CDK,
    })
    .catch(() => {});
  isNewLoading.value = false;
  if (res?.data?.status === 10000) {
    resetFields();
    props?.resetList?.();
    props?.setIsOpen?.(false);
    // isDelaySuccessOpen.value = true;
  }
};

const onClickOld = async () => {
  const values = await validate();
  isOldLoading.value = true;
  const res = await axios
    .post("web/account/yanqi_lins_xq/", {
      yqid: props?.rentPkId,
      cdkey: values?.CDK,
    })
    .catch(() => {});
  isOldLoading.value = false;
  if (res?.data?.status === 10000) {
    resetFields();
    props?.resetList?.();
    props?.setIsOpen?.(false);
    endTime.value = res?.data?.data?.endtime;
    isDelaySuccessOpen.value = true;
    
  }
};

const handleCancel = async () => {
  resetFields();
  props?.setIsOpen?.(false);
};
</script>
<style module>
.modalBtnW {
  margin-left: auto;
}
</style>
<style scoped>
:deep .ant-form-item {
  margin: 30px 0;
}
</style>