<!-- cdk兑换记录 -->
<template>
  <div>
    <div :class="$style.wrap">
      <div :class="$style.content">
        <MyGameLib />
        <div :class="$style.conDir"></div>
        <div :class="$style.right">
          <div :class="$style.rentWrap">
            <img :class="$style.rentIcon" :src="listIcon" alt="" />
            <div>cdk兑换记录</div>
          </div>
          <div :class="$style.tableW">
            <a-table
              :columns="columns"
              :row-key="record => record.id"
              :data-source="dataSource?.list || []"
              :pagination="pagination"
              :loading="loading"
              @change="handleTableChange"
            />
          </div>
        </div>
      </div>
      <Recommend />
    </div>
  </div>
</template>

<script setup>
  import { computed } from "vue";
  import axios from "@/axios";
  import { usePagination } from 'vue-request';

  import Recommend from '@/views/homeView/Recommend.vue';
  import MyGameLib from '@components/MyGameLib.vue';
  import listIcon from "@assets/listIcon.png";

  const columns = [
    {
      title: 'cdkey',
      dataIndex: 'cknb',
      key: 'cknb',
    },
    {
      title: '游戏名',
      dataIndex: 'game',
      key: 'game',
    },
    {
      title: 'cdk类型',
      dataIndex: 'morg_display',
      key: 'morg_display',
    },
    {
      title: '时长',
      dataIndex: 'otime_display',
      key: 'otime_display',
    },
    {
      title: '会员级别',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: '兑换时间',
      dataIndex: 'used_time',
      key: 'used_time',
    },
  ];

  // 获取租用记录
  const queryRentData = async (params) => {
    try {
      const limit = Number(params?.limit) || 5;
      const page = Number(params?.page) || 1;

      // 发送 POST 请求，获取历史租用记录
      const res = await axios.post('web/cdkeys/', { limit, page });
      return res;
    } catch (error) {
      console.error('Error fetching rent data:', error);
      throw error;
    }
  };

  // 使用分页 hook 获取数据
  const {
    data: dataSource,  // 表格数据
    run,               // 触发数据请求
    loading,           // 加载状态
    current,           // 当前页码
    pageSize,          // 每页条数
    total,
  } = usePagination(queryRentData, {
    formatResult: (res) => ({
      list: res?.data?.data?.items || [],
      total: res?.data?.data?.total || 0,
    }),
    defaultParams: [{
      limit: 7,
    }],
    pagination: {
      currentKey: 'page',
      pageSizeKey: 'limit',
    },
  });

  const pagination = computed(() => ({
    total: total.value,
    current: current.value,
    pageSize: pageSize.value || 5,
    pageSizeOptions: ['5', '10', '20', '50'], // 可选择的分页条数
    showSizeChanger: true, // 允许用户改变每页条数
    showTotal: total => `共 ${total} 条`,
  }));

  const handleTableChange = (
    pag,
    filters,
  ) => {
    run({
      limit: pag.pageSize,
      page: pag?.current,
      ...filters,
    });
  };
</script>

<style module>
  .wrap {
    padding: 13px 230px 0;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    display: flex;
    padding: 28px 0;
    margin-bottom: 20px;
  }
  .conDir {
    width: 1px;
    background-color: #E8E8E8;
    margin-left: 26px;
    margin-right: 22px;
  }
  .rentWrap {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .rentIcon {
    width: 32px;
    margin-right: 18px;
  }
  .right {
    flex-grow: 1;
  }
  .tableW {
    width: 100%;
    padding-right: 22px;
  }
</style>
