<!-- components/LoginModal.vue -->
<!-- 弹窗使用方法
import { useStore } from 'vuex';  // 引入 useStore 钩子

// 使用 useStore 获取 Vuex Store 实例
const store = useStore();

// 定义 showLogin 方法，触发 Vuex 中的 action
const showLogin = () => {
  store.dispatch('authModal/showLoginModal');  // 触发命名空间 'authModal' 中的 'showLoginModal' action
}; 
-->
<template>
  <!-- 登录弹窗 -->
  <a-modal
    v-model:open="isLoginModalVisible"
    @cancel="handleCancel"
    :destroyOnClose="true"
    width="100%"
    :footer="null"
    :wrapClassName="$style.modal"
  >
    <a-tabs :activeKey="activeKey" @change="onChangeTab">
      <a-tab-pane key="login" tab="登陆">
        <a-form
          :model="loginFormState"
          name="login_form"
          @finish="onLoginFinish"
          @finishFailed="onFinishFailed"
        >
          <a-form-item
            name="username"
            :rules="[{ required: true, message: $t('login.usernameRequired') }]"
          >
            <a-input
              v-model:value="loginFormState.username"
              placeholder="请输入手机号码"
            >
              <template #prefix>
                <img :class="$style.phoneImg" :src="phoneIcon" />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item
            name="password"
            :rules="[{ required: true, message: $t('login.passwordRequired') }]"
          >
            <a-input-password
              v-model:value="loginFormState.password"
              placeholder="请输入登陆密码"
            >
              <template #prefix>
                <img :class="$style.passwordImg" :src="passwordIcon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Image Captcha -->
          <a-form-item
            name="captcha"
            :rules="[{ required: true, message: $t('login.captchaRequired') }]"
          >
            <div :class="$style.captchaWrap">
              <a-input
                :class="$style.captchaInput"
                v-model:value="loginFormState.captcha"
                placeholder="请输入图片验证码"
              />
              <img
                :src="captchaUrl"
                @click="refreshCaptcha"
                :class="$style.captchaImg"
              />
            </div>
          </a-form-item>

          <a-form-item>
            <a-checkbox
              :class="$style.rememberFont"
              v-model:checked="loginFormState.remember"
            >
              {{ $t("login.remember") }}
            </a-checkbox>
            <a :class="$style.rememberFont" href="#" @click="onRecover">{{
              $t("login.forgot")
            }}</a>
          </a-form-item>
          <a-button
            :disabled="loginDisabled"
            type="primary"
            html-type="submit"
            :class="$style.registerFormButton"
          >
            {{ $t("login.login") }}
          </a-button>
          <!-- <a-form-item>
            <a-button
              :disabled="loginDisabled"
              type="primary"
              html-type="submit"
              class="login-form-button"
            >
              {{ $t("login.login") }}
            </a-button>
            {{ $t("login.or") }}
            <a @click="switchToRegister">{{ $t("login.register") }}</a>
          </a-form-item> -->
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="register" tab="注册">
        <a-form
          :model="formState"
          name="normal_register"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
        >
          <!-- Phone Number -->
          <a-form-item
            name="phone"
            :rules="[{ required: true, message: $t('login.usernameRequired') }]"
          >
            <a-input
              v-model:value="formState.phone"
              placeholder="请输入手机号码"
            >
              <template #prefix>
                <img :class="$style.phoneImg" :src="phoneIcon" />
              </template>
            </a-input>
          </a-form-item>

          <!-- Password -->
          <a-form-item
            name="password"
            :rules="[{ required: true, message: $t('login.passwordRequired') }]"
          >
            <a-input-password
              v-model:value="formState.password"
              placeholder="请输入登陆密码"
            >
              <template #prefix>
                <img :class="$style.passwordImg" :src="passwordIcon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Confirm Password -->
          <a-form-item
            name="confirmPassword"
            :rules="[{ required: true, message: '请确认密码!' }]"
          >
            <a-input-password
              v-model:value="formState.confirmPassword"
              placeholder="请确认登陆密码"
            >
              <template #prefix>
                <img :class="$style.querenImg" :src="querenIcon" />
              </template>
            </a-input-password>
          </a-form-item>

          <!-- Invite Code -->
          <!-- <a-form-item label="邀请码(可不填)" name="inviteCode">
            <a-input v-model:value="formState.inviteCode">
              <template #prefix>
                <UserOutlined class="site-form-item-icon" />
              </template>
            </a-input>
          </a-form-item> -->

          <!-- Image Captcha -->
          <a-form-item
            name="captcha"
            :rules="[{ required: true, message: '请输入图片验证码!' }]"
          >
            <div :class="$style.captchaWrap">
              <a-input
                :class="$style.captchaInput"
                v-model:value="formState.captcha"
                placeholder="请输入图片验证码"
              />
              <img
                :src="captchaUrl"
                @click="refreshCaptcha"
                :class="$style.captchaImg"
              />
            </div>
          </a-form-item>

          <!-- SMS Code -->
          <a-form-item
            name="smsCode"
            :rules="[{ required: true, message: '请输入短信验证码!' }]"
          >
            <div :class="$style.captchaWrap">
              <a-input
                :class="$style.captchaInput"
                v-model:value="formState.smsCode"
                placeholder="请输入短信验证码"
              />
              <a-button
                :class="$style.smsCodeBtn"
                type="primary"
                @click="getSmsCode"
                :disabled="smsButtonDisabled"
                >{{ smsButtonText }}</a-button
              >
            </div>
          </a-form-item>

          <!-- Agree Terms -->
          <a-form-item>
            <a-checkbox
              :class="$style.agreeTerm"
              v-model:checked="formState.agreeTerms"
              >我已阅读并同意
              <a href="/news/43.html" target="_blank">《平台服务协议》</a>
              和<a href="/news/74.html" target="_blank">《隐私协议》</a></a-checkbox
            >
          </a-form-item>

          <!-- Submit Button -->
          <a-button
            :disabled="isRegistering"
            type="primary"
            html-type="submit"
            :class="$style.registerFormButton"
          >
            立即注册，领取{{ amount || "-" }}元现金红包
          </a-button>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script setup>
import { computed, reactive, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from 'vue-router';
import { LockOutlined, PictureOutlined } from "@ant-design/icons-vue";
import axios from "@/axios";
import axios_blob from "@/axios_blob";
import { message } from "ant-design-vue";

import phoneIcon from "@assets/phoneIcon.png";
import passwordIcon from "@assets/passwordIcon.png";
import querenIcon from "@assets/querenIcon.png";

const router = useRouter();
const { t } = useI18n();
const store = useStore();
const activeKey = computed(() => store.state.loginModalType);
const smsButtonDisabled = ref(false);
const smsButtonText = ref("获取验证码");
const amount = ref();
const isRegistering = ref(false);


// 获取礼包金额
onMounted(async () => {
  const res = await axios.post("web/settings_rule/", {}).catch(() => { });
  if (res?.data?.status === 10000) {
    console.log(res?.data, 9090909);
    amount.value = res?.data?.data?.user_reg_dkj;
  }
});

// 登录表单数据
const loginFormState = reactive({
  username: "",
  password: "",
  captcha: "",
  remember: true,
});
const formState = reactive({
  phone: "",
  password: "",
  confirmPassword: "",
  inviteCode: "",
  captcha: "",
  smsCode: "",
  agreeTerms: false,
});

// 是否显示登录/注册弹窗
const isLoginModalVisible = computed(
  () => store.state.authModal.isLoginModalVisible
);

// 验证码 URL
const captchaUrl = ref("");
const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(
      `userauth/get_image_captcha/?source=login&time=${Date.now()}`
    );
    captchaUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error(t("login.captchaRefreshFailed"), error);
  }
};

watch(isLoginModalVisible, (newValue, oldValue) => {
  if (newValue) {
    // 当登录模态框可见时刷新验证码
    refreshCaptcha();
  }
});


// 登录表单提交处理
const onLoginFinish = async () => {
  try {
    const response = await axios.post("userauth/login/", loginFormState);
    if (response.data.status !== 10000) {
      refreshCaptcha();
      return;
    }
    const accessToken = response.data.data.token;
    localStorage.setItem("access_token", accessToken);

    // 获取用户信息
    const userInfoResponse = await axios.post("web/user_info/");
    const userInfo = userInfoResponse.data.data;

    // 更新 Vuex 状态
    store.commit("SET_AUTHENTICATED", true);
    store.commit("SET_USER", userInfo);

    // 关闭弹窗
    store.dispatch("authModal/hideLoginModal");
    window.location.reload();
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

// 注册表单提交处理
const onFinish = async () => {
  if (isRegistering.value) {
    return; // 如果正在注册，直接返回
  }
  isRegistering.value = true; // 设置锁

  try {
    if (formState.password !== formState.confirmPassword) {
      message.error("密码和确认密码不一致");
      return;
    }

    if (!formState.agreeTerms) {
      message.error("请同意相关条款《用户协议》");
      return;
    }

    const storedInviteCode = localStorage.getItem('user_invitation_code');
    if (storedInviteCode) {
      formState.inviteCode = storedInviteCode;
    }

    const response = await axios.post("userauth/register/phone/", formState);
    if (response.data.status !== 10000) {
      refreshCaptcha();
      return;
    }

    const accessToken = response.data.data.token;
    localStorage.setItem("access_token", accessToken);

    // 获取用户信息
    const userInfoResponse = await axios.post("web/user_info/");
    const userInfo = userInfoResponse.data.data;

    // 更新 Vuex 状态
    store.commit("SET_AUTHENTICATED", true);
    store.commit("SET_USER", userInfo);

    // 关闭弹窗
    store.dispatch("authModal/hideLoginModal");
    window.location.reload();
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  } finally {
    isRegistering.value = false; // 无论成功还是失败，都解锁
  }
};

// 表单提交失败处理
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

// Function to get SMS code
const getSmsCode = async () => {
  if (!formState.captcha || !formState.phone) {
    message.error("请先输入图片验证码或手机号");
    return;
  }
  try {
    const response = await axios.post("userauth/get_sms_code/", {
      phone: formState.phone,
      source: "reg",
      captcha: formState.captcha,
    });
    if (response.data.status == 10000) {
      smsButtonDisabled.value = true;
      smsButtonText.value = "120秒后重新获取";
      let countdown = 120;
      const interval = setInterval(() => {
        countdown -= 1;
        smsButtonText.value = `${countdown}秒后重新获取`;
        if (countdown <= 0) {
          clearInterval(interval);
          smsButtonDisabled.value = false;
          smsButtonText.value = "获取验证码";
        }
      }, 1000);
    } else {
      refreshCaptcha();
    }
  } catch (error) {
    console.error(error);
    refreshCaptcha();
  }
};

// 关闭弹框
const handleCancel = () => {
  store.dispatch("authModal/hideLoginModal");
};

// 是否禁用提交按钮
const loginDisabled = computed(() => {
  return !(
    loginFormState.username &&
    loginFormState.password &&
    loginFormState.captcha
  );
});

// 忘记密码点击
const onRecover = (e) => {
  // 处理忘记密码
  window.open('/recover', '_blank');
  store.dispatch("authModal/hideLoginModal");
};

const onChangeTab = (activeKey) => {
  store.commit("SET_LOGINMODALTYPE", activeKey);
};
</script>

<style module>
.modal {
  width: 372px;
  margin: 0 auto;
}
.phoneImg {
  width: 12px;
  height: 18px;
}
.passwordImg {
  width: 12px;
  height: 16px;
}
.querenImg {
  width: 12px;
  height: 16px;
}
.captchaWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
}
.captchaInput {
  width: 180px;
}
.captchaImg {
  cursor: pointer;
  width: 86px;
  height: 28px;
}
.smsCodeBtn {
  width: 89px;
  height: 28px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 13px;
}
.agreeTerm {
  font-size: 11px !important;
}
.registerFormButton {
  width: 100%;
  height: 37px;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 8px 10px rgba(50, 109, 255, 0.7);
}
.rememberFont {
  font-size: 14px !important;
}


@media (max-width: 480px ) {
  .modal {
    width: 1972px;
  }
  .phoneImg {
    width: 60px;
    height: 90px;
  }
  .passwordImg {
    width: 60px;
    height: 90px;
  }
  .querenImg {
    width: 60px;
    height: 80px;
  }
  .captchaWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 185px;
  }
  .captchaInput {
    width: 900px;
  }
  .captchaImg {
    cursor: pointer;
    width: 430px;
    height: 140px;
  }
  .smsCodeBtn {
    width: 450px;
    height: 140px;
    border-radius: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    line-height: 65px;
  }
  .agreeTerm {
    font-size: 55px !important;
  }
  .registerFormButton {
    width: 100%;
    height: 185px;
    font-size: 80px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 8px 10px rgba(50, 109, 255, 0.7);
  }
  .rememberFont {
    font-size: 70px !important;
  }
}
</style>

