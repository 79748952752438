const gobalMessageModule = {
  namespaced: true, // 使用命名空间
  state: () => ({
    isGobalMessageModalVisible: false,
    payload: {
      tmp: null,
      alterType: null,
      msg: "",
      level: "info", // 默认级别
      title: "",
    },
  }),
  mutations: {
    SHOW_GobalMessage_MODAL(state, payload) {
      state.isGobalMessageModalVisible = true;
      state.payload = payload;
    },
    HIDE_GobalMessage_MODAL(state) {
      state.isGobalMessageModalVisible = false;
      state.payload = {
        tmp: null,
        alterType: null,
        msg: "",
        level: "info", // 默认级别
        title: "",
      }
    },
  },
  actions: {
    showModal({ commit }, payload) {
      commit("SHOW_GobalMessage_MODAL", payload);
    },
    hideModal({ commit }) {
      commit("HIDE_GobalMessage_MODAL");
    },
  },
};

export default gobalMessageModule;
