<template>
  <div>
    <h1>支付弹窗测试页面</h1>

    <!-- 支付方式按钮 -->
    <div>
      <button @click="openSkuPayment">SKU 支付</button>
      <button @click="openVipPayment">VIP 支付</button>
      <button @click="openCdkPayment">CDK 充值</button>
      <button @click="openThirdPartyPayment">三方供应商租号玩</button>
      <button @click="openSkuPaymentV2">sku免选择直接支付-首页</button>
    </div>
    <div>
      <button @click="openSignInModal">打开签到弹窗</button>
      <!-- 引入 SignInModal 组件 -->
      <SignInModal ref="signInModalRef" />
    </div>
  </div>
  <PaymentModal
    :isOpen="isOpen"
    :isOrderOpen="isOrderOpen"
    :curSku="curSku"
    :paymentType="paymentType"
    :orderInfo="orderInfo"
    @setIsOpen="(value) => (isOpen = value)"
    @setIsOrderOpen="(value) => (isOrderOpen = value)"
  />
</template>

<script setup>
  import { ref } from "vue";
  import PaymentModal from "@/components/BuyPlanModalv3.vue";
  import SignInModal from "@/components/SignInModal.vue";


  const signInModalRef = ref(null);

  // 方法：打开签到弹窗
  const openSignInModal = () => {
    if (signInModalRef.value) {
      signInModalRef.value = true;
    }
  };

  // 模态框状态
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const curSku = ref(null);
  const orderInfo = ref(null);

  // 模拟 SKU 支付
  const openSkuPayment = () => {
    paymentType.value = 0;
    curSku.value = {
      sku_name: "3小时租号",
      show_price_info: {
        price: 20.0,
        use_dkj_amount: 5.0,
        use_dkj_price: 15.0,
      },
      gname: "英雄联盟",
    };
    isOpen.value = true;
  };

  // 模拟 VIP 支付
  const openVipPayment = () => {
    paymentType.value = 1;
    curSku.value = null;
    isOpen.value = true;
  };

  // 模拟 CDK 充值
  const openCdkPayment = () => {
    paymentType.value = 2;
    curSku.value = null;
    isOpen.value = true;
    // isOrderOpen.value = true;
  };

  // 模拟三方定额支付
  const openThirdPartyPayment = () => {
    paymentType.value = 3;
    orderInfo.value = {
      realPrice: 0.04,
      Price: 0.4,
      deduction: 0.36,

      time_count: 1,
      time_remark: "1小时",
      game_id: 2088,
      gys_account_number: 13993399,
      gname: "王者荣耀"
    };
    isOrderOpen.value = true;
    isOpen.value = false;
  };

  // 模拟直接支付
  const openSkuPaymentV2 = () => {
    paymentType.value = 4;
    orderInfo.value = {
      realPrice: 100.0,
      Price: 120.0,
      deduction: 20.0,
      time_remark: "30天",
      gname: "和平精英",
      skuId: 1,
      vip_sku_value: null,
      
    };
    isOrderOpen.value = true;
  };

</script>

  <style scoped>
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  </style>
  