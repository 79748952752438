// store/modules/signModal.js
const state = {
    isModalVisible: false, // 弹窗显示状态
    rewardAmount: 0,      // 签到奖励金额
    totalDiscount: 0      // 可抵扣总额
}

const mutations = {
    // 打开弹窗
    open_model(state, payload) {
        state.isModalVisible = true
        state.rewardAmount = payload.rewardAmount || 0
        state.totalDiscount = payload.totalDiscount || 0
    },

    // 关闭弹窗
    close_model(state) {
        state.isModalVisible = false
    }
}

export default {
namespaced: true,
state,
mutations
}