<template>
  <div :class="$style.wrap">
    <div :class="$style.left">
      <div :class="$style.leftHeader">
        <img :class="$style.leftHeaderI" :src="userIcon" alt="" />
        <div :class="$style.leftHeaderT">用户名：{{ user?.username || '未知用户' }}</div>
      </div>
      <div :class="$style.divder"></div>
      <div style="overflow: hidden;">
        <div :class="$style.leftCon">
          <div :class="$style.leftConL">抵扣金</div>
          <div :class="$style.leftConP">
            <div :class="$style.leftConPP">{{ user?.deduction_amount || '0.00' }}</div>
            <div :class="$style.leftConPD">元</div>
          </div>
        </div>
      </div>
      <div :class="$style.leftCon">
        <div :class="$style.leftConL">小时版抵扣金</div>
        <div :class="$style.leftConP">
          <div :class="$style.leftConPP">{{ user?.gys_deductions_amount || '0.00' }}</div>
          <div :class="$style.leftConPD">元</div>
        </div>
      </div>
      <div :class="$style.leftCon">
        <div :class="$style.leftConL">提现余额</div>
        <div :class="$style.leftConP">
          <div :class="$style.leftConPP">{{ user?.amount || '0.00' }}</div>
          <div :class="$style.leftConPD">元</div>
        </div>
      </div>
      <div :class="$style.leftCon">
        <div :class="$style.leftConL">支付宝账号</div>
        <a-tooltip v-if="user?.alipay_account" :title="!user?.is_allow_tixian ? `最低可提现金额为：${user?.allow_tixian_amount}` : ''">
        <a-button
          :class="$style.leftConB"
          type="primary"
          shape="round"
          :disabled="!user?.is_allow_tixian"
          @click="onClickT"
        >
          提现
        </a-button>
      </a-tooltip>
        
      </div>
      <div :class="$style.leftConAli">
        <div v-if="user?.alipay_account" :class="$style.leftConAliA">{{ user.alipay_account }}</div>
        <a-button v-else :class="[$style.leftConB, $style.leftConBA]" type="primary" shape="round" @click="onBindA">
          绑定支付宝账号
        </a-button>
      </div>
      <div :class="$style.leftCon">
        <div :class="$style.leftConL">CDKEY余额</div>
      </div>
      <div :class="$style.leftF">
        <div :class="$style.leftFP">
          <div :class="$style.leftFPP">{{ user?.cdk_amount || '0.00' }}</div>
          <div :class="$style.leftFPD">元</div>
        </div>
        <a-button :class="$style.leftFB" type="primary" shape="round" @click="openCdkPayment">
          充值
        </a-button>
      </div>
    </div>
    <div :class="$style.right">
      <!-- 折扣金明细 -->
      <!-- <DetailTable :leftIcon="zkjIcon" title="折扣金明细" :columns="dkjColumns" :searchParams="params0" @update:pagination="handlePaginationUpdate0" :fetch-data="fetchDKJData"/> -->
      <!-- <DetailTable :leftIcon="zkjIcon" title="手游折扣金明细" :columns="dkjColumns" :searchParams="params1" @update:pagination="handlePaginationUpdate1" :fetch-data="fetchGYSDKJData"/> -->
      <!-- CDK钱包明细 -->
      <DetailTable :leftIcon="cdkIcon" title="CDK钱包明细" :columns="cdkColumns" :searchParams="params2" @update:pagination="handlePaginationUpdate2" :fetch-data="fetchCDKData"/>
      <!-- 返佣明细 -->
      <DetailTable :leftIcon="fyIcon" title="返佣明细" :columns="fyColumns" :searchParams="params3" @update:pagination="handlePaginationUpdate3" :fetch-data="fetchFYData"/>
      <!-- 钱包明细 -->
      <DetailTable :leftIcon="qbIcon" title="钱包明细" :columns="qbColumns" :searchParams="params4" @update:pagination="handlePaginationUpdate4" :fetch-data="fetchQBData"/>
      <!-- 提现记录 -->
      <DetailTable :leftIcon="txIcon" title="提现记录" :columns="txColumns" :searchParams="params5" @update:pagination="handlePaginationUpdate5" :fetch-data="fetchTXData"/>
      <!-- 邀请用户 -->
      <DetailTable :leftIcon="yqIcon" title="邀请用户" :columns="yqColumns" :searchParams="params6" @update:pagination="handlePaginationUpdate6" :fetch-data="fetchYQData"/>
    </div>
  </div>
    <!-- 提现弹窗 -->
    <WithdrawModal 
      :isOpen="isWithdrawModalVisible" 
      @setIsOpen="closeWithdrawModal"
      :user="user" 
    />

    <!-- 支付宝绑定弹窗 -->
    <BindAlipayModal 
      :isOpen="isBindAlipayModalVisible" 
      @setIsOpen="closeBindAlipayModal"
      :user="user" 
    />
    
    <PaymentModal
      :isOpen="isOpen"
      :isOrderOpen="isOrderOpen"
      :curSku="curSkuPaymentModal"
      :paymentType="paymentType"
      :orderInfo="orderInfo"
      @setIsOpen="(value) => (isOpen = value)"
      @setIsOrderOpen="(value) => (isOrderOpen = value)"
    />
</template>

<script setup>
  import { ref, computed, onMounted } from "vue";
  import axios from "@/axios";

  import DetailTable from '@/components/DetailTable.vue';
  import userIcon from "@assets/userIcon.png";
  import zkjIcon from "@assets/zkjIcon.png";
  import cdkIcon from "@assets/cdkIcon.png";
  import fyIcon from "@assets/fyIcon.png";
  import qbIcon from "@assets/qbIcon.png";
  import txIcon from "@assets/txIcon.png";
  import yqIcon from "@assets/yqIcon.png";

  import WithdrawModal from '@/components/WithdrawModal.vue';
  import BindAlipayModal from '@/components/BindAlipayModal.vue';
  import PaymentModal from "@/components/BuyPlanModalv3.vue";
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const orderInfo = ref(null);
  const curSkuPaymentModal = ref(null);

  import { useStore } from "vuex";
  const store = useStore();
  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

  const params0 = ref({
    page: 1,
    limit: 5,
  });

  const params1 = ref({
    page: 1,
    limit: 5,
  });

  const params2 = ref({
    page: 1,
    limit: 5,
  });

  const params3 = ref({
    page: 1,
    limit: 5,
  });

  const params4 = ref({
    page: 1,
    limit: 5,
  });

  const params5 = ref({
    page: 1,
    limit: 5,
  });

  const params6 = ref({
    page: 1,
    limit: 5,
  });


  const handlePaginationUpdate0 = (newParams) => {
    params0.value = { ...params0.value, ...newParams };
  };

  const handlePaginationUpdate1 = (newParams) => {
    params1.value = { ...params0.value, ...newParams };
  };

  const handlePaginationUpdate2 = (newParams) => {
    params2.value = { ...params0.value, ...newParams };
  };

  const handlePaginationUpdate3 = (newParams) => {
    params3.value = { ...params0.value, ...newParams };
  };

  const handlePaginationUpdate4 = (newParams) => {
    params4.value = { ...params0.value, ...newParams };
  };

  const handlePaginationUpdate5 = (newParams) => {
    params5.value = { ...params0.value, ...newParams };
  };

  const handlePaginationUpdate6 = (newParams) => {
    params6.value = { ...params0.value, ...newParams };
  };
  
  // 模拟 CDK 充值
  const openCdkPayment = () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    paymentType.value = 2;
    curSkuPaymentModal.value = null;
    isOpen.value = true;
    // isOrderOpen.value = true;
  };

  // 控制弹窗的显示状态
  const isWithdrawModalVisible = ref(false);
  const isBindAlipayModalVisible = ref(false);

  // 打开提现弹窗的函数
  const onClickT = () => {
    isWithdrawModalVisible.value = true;
  };
  
  // 打开支付宝绑定弹窗
  const onBindA = () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    isBindAlipayModalVisible.value = true;
  };

  // 关闭提现弹窗
  const closeWithdrawModal = () => {
    isWithdrawModalVisible.value = false;
  };

  // 关闭支付宝绑定弹窗
  const closeBindAlipayModal = () => {
    isBindAlipayModalVisible.value = false;
  };

  // 折扣金明细columns
  const dkjColumns = [
    { title: '事件', dataIndex: 'type' },
    { title: '抵扣金额', dataIndex: 'amount' },
    { title: '抵扣前金额', dataIndex: 'pre_amount' },
    { title: '抵扣后金额', dataIndex: 'after_amount' },
    { title: '时间', dataIndex: 'created' },
  ];

  // CDK钱包明细columns
  const cdkColumns = [
    { title: '事件', dataIndex: 'type' },
    { title: '抵扣金额', dataIndex: 'amount' },
    { title: '抵扣前金额', dataIndex: 'pre_amount' },
    { title: '抵扣后金额', dataIndex: 'after_amount' },
    { title: '时间', dataIndex: 'created' },
  ];

  // 返佣明细columns
  const fyColumns = [
    { title: '订单编号', dataIndex: 'order' },
    { title: '待结算金额', dataIndex: 'amount' },
    { title: '下单用户', dataIndex: 'user' },
    { title: '状态', dataIndex: 'status' },
    { title: '时间', dataIndex: 'created' },
  ];

  // 钱包明细columns
  const qbColumns = [
    { title: '事件', dataIndex: 'type' },
    { title: '抵扣金额', dataIndex: 'amount' },
    { title: '抵扣前金额', dataIndex: 'pre_amount' },
    { title: '抵扣后金额', dataIndex: 'after_amount' },
    { title: '时间', dataIndex: 'created' },
  ];

  // 提现记录columns
  const txColumns = [
    { title: '提现状态', dataIndex: 'status' },
    { title: '金额', dataIndex: 'amount' },
    { title: '备注', dataIndex: 'remark' },
    { title: '提现时间', dataIndex: 'created' },
  ];

  // 邀请用户columns
  const yqColumns = [
    { title: '用户名', dataIndex: 'username' },
    { title: '注册时间', dataIndex: 'rtiem' },
  ];

  // 获取折扣金明细
  const fetchDKJData = (params) => {
    return axios.post('web/user_dkj_logs/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  const fetchGYSDKJData = (params) => {
    return axios.post('web/user_gys_dkj_logs/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  // 获取CDK钱包明细
  const fetchCDKData = (params) => {
    return axios.post('web/user_cdk_wallet_logs/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  // 获取返佣明细
  const fetchFYData = (params) => {
    return axios.post('web/user_share_order_rates/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  // 获取钱包明细
  const fetchQBData = (params) => {
    return axios.post('web/user_wallet_logs/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  // 获取提现记录
  const fetchTXData = (params) => {
    return axios.post('web/user_wallet_withdraws/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  // 获取邀请用户
  const fetchYQData = (params) => {
    return axios.post('web/user_yaoqing_logs/', { limit: params?.limit || 5, page: params?.page || 1 });
  };

  // 使用 Vuex 的 getter 获取用户信息
  const user = computed(() => store.getters.getUser);

  // 页面挂载时调用 fetchUserInfo action
  onMounted(() => {
    store.dispatch('fetchUserInfo');
  });
</script>

<style module>
  .wrap {
    display: flex;
    padding: 13px 230px 0;
  }
  .left {
    flex-shrink: 0;
    width: 264px;
    background-color: #FAFBFA;
    border-radius: 10px;
    padding: 20px 20px 26px;
    color: #1A1A1A;
    margin-right: 46px;
  }
  .right {
    flex-grow: 1;
  }
  .leftHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .leftHeaderI {
    width: 36px;
    height: 36px;
  }
  .leftHeaderT {
    font-size: 16px;
  }
  .divder {
    background-color: #E3E3E3;
    width: 100%;
    height: 1px;
    margin-top: 17px;
    margin-bottom: 3px;
  }
  .leftCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: 26px;
  }
  .leftConL {
    font-size: 14px;
  }
  .leftConP {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  .leftConPP {
    font-size: 18px;
    color: #FF2727;
    margin-right: 8px;
  }
  .leftConPD {
    font-size: 16px;
    color: #383838;
  }
  .leftConB {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 24px;
    font-size: 13px;
    color: #fff;
    background-color: #237AFF;
  }
  .leftConAli {
    height: 28px;
    line-height: 28px;
    margin-top: 14px;
  }
  .leftConAliA {
    font-size: 16px;
  }
  .leftConBA {
    width: 100% !important;
    height: 28px !important;
  }
  .leftF {
    height: 33px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .leftFP {
    display: flex;
    align-items: center;
  }
  .leftFPP {
    font-size: 22px;
    color: #FF2727;
    margin-right: 5px;
  }
  .leftFPD {
    font-size: 16px;
    color: #383838;
  }
  .leftFB {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 24px;
    font-size: 13px;
    color: #fff;
    background-color: rgba(255, 147, 94, 1);
  }
  .leftFB:hover {
    background-color: rgba(255, 147, 94, .7) !important;
  }
</style>
