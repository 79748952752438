<!-- 活动 -->
<template>
  <div :class="$style.wrap">
    <img :class="$style.liwuIconImg" :src="liwuIcon" alt="" />
    <div>活动期间，新用户注册即送{{ amount || "-" }}元现金红包，先到先得</div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "@/axios";
import liwuIcon from "@assets/liwuIcon.png";

const amount = ref();

// 获取礼包金额
onMounted(async () => {
  const res = await axios.post("web/settings_rule/", {}).catch(() => {});
  if (res?.data?.status === 10000) {
    console.log(res?.data, 9090909);
    amount.value = res?.data?.data?.user_reg_dkj;
  }
});
</script>

<style module>
.wrap {
  width: 100%;
  height: 80px;
  background-image: url("../assets/huodongbgc2.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #ffffff;
  border-radius: 12px;
}
.liwuIconImg {
  width: 74px;
  height: 67px;
  margin-right: 44px;
}
</style>
