<template>
  <div class="change-phone-container">
    <a-card class="change-phone-card">
      <a-steps :current="currentStep" class="steps">
        <a-step title="验证原手机号" />
        <a-step title="设置新手机号" />
      </a-steps>

      <!-- Step 1: Verify old phone -->
      <div v-if="currentStep === 0" class="step-content">
        <a-form :model="oldPhoneForm" :rules="oldPhoneRules" ref="oldPhoneFormRef">
          <a-form-item label="原手机号">
            <a-input v-model:value="oldPhoneForm.phone" disabled />
          </a-form-item>
          <a-form-item label="图片验证码">
            <div class="captcha-container">
              <a-input v-model:value="oldPhoneForm.captcha" placeholder="请输入图片验证码" />
              <img :src="captchaUrl" @click="refreshCaptcha" class="captcha-img" alt="验证码" />
            </div>
          </a-form-item>
          <a-form-item label="短信验证码">
            <div class="sms-container">
              <a-input v-model:value="oldPhoneForm.smsCode" placeholder="请输入短信验证码" />
              <a-button 
                @click="sendOldPhoneCode"
                :disabled="!!countdown"
                type="primary"
              >
                {{ countdown ? `${countdown}s后重试` : '获取验证码' }}
              </a-button>
            </div>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="verifyOldPhone" :loading="loading">
              下一步
            </a-button>
          </a-form-item>
        </a-form>
      </div>

      <!-- Step 2: Set new phone -->
      <div v-if="currentStep === 1" class="step-content">
        <a-form :model="newPhoneForm" :rules="newPhoneRules" ref="newPhoneFormRef">
          <a-form-item label="新手机号">
            <a-input v-model:value="newPhoneForm.phone" placeholder="请输入新手机号" />
          </a-form-item>
          <a-form-item label="图片验证码">
            <div class="captcha-container">
              <a-input v-model:value="newPhoneForm.captcha" placeholder="请输入图片验证码" />
              <img :src="captchaUrl" @click="refreshCaptcha" class="captcha-img" alt="验证码" />
            </div>
          </a-form-item>
          <a-form-item label="短信验证码">
            <div class="sms-container">
              <a-input v-model:value="newPhoneForm.smsCode" placeholder="请输入短信验证码" />
              <a-button 
                :disabled="!!countdown" 
                @click="sendNewPhoneCode"
                type="primary"
              >
                {{ countdown ? `${countdown}s后重试` : '获取验证码' }}
              </a-button>
            </div>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="submitChange" :loading="loading">
              确认修改
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from '@/axios'
import axios_blob from '@/axios_blob'

const router = useRouter()
const store = useStore()
const currentStep = ref(0)
const loading = ref(false)
const countdown = ref(0)
const captchaUrl = ref('')
const captchaUuid = ref('')

const oldPhoneForm = reactive({
  phone: '',
  captcha: '',
  smsCode: ''
})

const newPhoneForm = reactive({
  phone: '',
  captcha: '',
  smsCode: ''
})

const oldPhoneRules = {
  captcha: [{ required: true, message: '请输入图片验证码' }],
  smsCode: [{ required: true, message: '请输入短信验证码' }]
}

const newPhoneRules = {
  phone: [
    { required: true, message: '请输入新手机号' },
    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' }
  ],
  captcha: [{ required: true, message: '请输入图片验证码' }],
  smsCode: [{ required: true, message: '请输入短信验证码' }]
}

const oldPhoneFormRef = ref(null)
const newPhoneFormRef = ref(null)

const refreshCaptcha = async () => {
  try {
    const response = await axios_blob.get(`userauth/get_image_captcha/?source=login&time=${Date.now()}`);
    captchaUrl.value = URL.createObjectURL(response.data);
    captchaUuid.value = response.headers['x-captcha-uuid'];
  } catch (error) {
    console.error('验证码刷新失败', error);
  }
};

onMounted(refreshCaptcha);

const startCountdown = () => {
  countdown.value = 60
  const timer = setInterval(() => {
    countdown.value--
    if (countdown.value <= 0) {
      clearInterval(timer)
    }
  }, 1000)
}

const sendCode = async (phone, captcha, type) => {
  if (!phone.length) {
    message.error('请输入手机号')
    refreshCaptcha()
    return
  }
  if (!captcha.length) {
    message.error('请输入图片验证码')
    refreshCaptcha()
    return
  }
  try {
    const response = await axios.post('/userauth/send_phone_change_code/', {
      phone,
      captcha,
      type
    }, {
      headers: {
        'x-captcha-uuid': captchaUuid.value
      }
    })
    if (response.data.status === 10000) {
      startCountdown()
    } else {
      refreshCaptcha()
    }
  } catch (error) {
    refreshCaptcha()
  }
}

const sendOldPhoneCode = async () => {
  await sendCode(oldPhoneForm.phone, oldPhoneForm.captcha, 'old')
}

const sendNewPhoneCode = async () => {
  await sendCode(newPhoneForm.phone, newPhoneForm.captcha, 'new')
}

const verifyOldPhone = async () => {
  try {
    await oldPhoneFormRef.value.validate()
    if (!oldPhoneForm.smsCode.length) {
      message.error('短信验证码错误')
      refreshCaptcha()
      return
    }
    loading.value = true
    
    // Then verify the SMS code
    const response = await axios.post('/userauth/verify_phone_code/', {
      phone: oldPhoneForm.phone,
      code: oldPhoneForm.smsCode
    })

    if (response.data.status !== 10000) {
      refreshCaptcha()
      return
    }

    // If both verifications pass, proceed to next step
    currentStep.value = 1
    refreshCaptcha()
  } catch (error) {
    if (error.name === 'ValidationError') {
      message.error('请填写完整信息')
      refreshCaptcha()
    } else {
      message.error(error.response?.data?.msg || '验证失败')
      refreshCaptcha()
    }
  } finally {
    loading.value = false
  }
}

const submitChange = async () => {
  try {
    await newPhoneFormRef.value.validate()
    loading.value = true
    if (!newPhoneForm.phone.length) {
      message.error('请输入新手机号')
      refreshCaptcha()
      return
    }
    if (!newPhoneForm.captcha.length) {
      message.error('请输入图片验证码')
      refreshCaptcha()
      return
    }
    // 验证短信验证码
    if (!newPhoneForm.smsCode.length) {
      message.error('短信验证码错误')
      refreshCaptcha()
      return
    }

    // If both verifications pass, update the phone number
    const response = await axios.post('/userauth/update_phone/', {
      oldPhone: oldPhoneForm.phone,
      newPhone: newPhoneForm.phone,
      oldCode: oldPhoneForm.smsCode,
      newCode: newPhoneForm.smsCode
    })

    if (response.data.status !== 10000) {
      refreshCaptcha()
      return
    }
    // 获取用户信息
    const userInfoResponse = await axios.post('web/user_info/');
    const userInfo = userInfoResponse.data.data;

    // 更新 Vuex 状态
    store.commit('SET_AUTHENTICATED', true);
    store.commit('SET_USER', userInfo);

    router.push('/gameStore')
  } catch (error) {
    if (error.name === 'ValidationError') {
      message.error('请填写完整信息')
    } else {
      message.error(error.response?.data?.msg || '修改失败')
    }
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  try {
    const response = await axios.post('/web/user_info/', {})
    oldPhoneForm.phone = response.data?.data?.mobile
    refreshCaptcha()
  } catch (error) {
    message.error('获取用户信息失败')
  }
})
</script>

<style scoped>
.change-phone-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 0 20px;
}

.change-phone-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.steps {
  margin-bottom: 40px;
}

.step-content {
  padding: 20px 0;
}

.captcha-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.captcha-img {
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.sms-container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 24px;
}

.ant-input {
  border-radius: 4px;
}

.ant-btn {
  border-radius: 4px;
}

.ant-steps-item-title {
  font-size: 16px;
}

.ant-card {
  border-radius: 8px;
}

.ant-form-item-label > label {
  font-weight: 500;
}
</style>