
<!-- 验证码展示弹窗 -->
<template>
  <a-modal
    :open="isOpen"
    @cancel="handleCancel"
    :maskClosable="false"
    :destroyOnClose="true"
    width="100%"
    :wrapClassName="[$style.modal, 'verification-code-modal']"
  >
    <template #title>
      <div :class="$style.title">网站显示</div>
    </template>
    <template #footer>
      <div :class="$style.modalBtnW">
        <a-button :class="$style.modalBtnL" type="primary" @click="onCopy"
          >复制验证码</a-button
        >
        <a-button :class="$style.modalBtnR" type="primary" @click="onClickBtn"
          >确认</a-button
        >
      </div>
    </template>
    <div :class="$style.content">
      {{ codeInfo?.msg || "-" }}
    </div>
  </a-modal>
</template>
<script setup>
import { message } from "ant-design-vue";
import useClipboard from "vue-clipboard3";

const props = defineProps({
  isOpen: Boolean,
  setIsOpen: Function,
  codeInfo: Object,
});

const { toClipboard } = useClipboard();

const handleCancel = () => {
  props?.setIsOpen?.(false);
};
const onClickBtn = () => {
  props?.setIsOpen?.(false);
};
const onCopy = async () => {
  if (!props?.codeInfo?.code) return;
  try {
    await toClipboard(props?.codeInfo?.code);
    message.success("复制成功");
  } catch (e) {
    console.error(e);
  }
};
</script>
<style module>
.modal {
  width: 599px;
  margin: 0 auto;
}
.title {
  font-size: 19px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 32px;
  color: #242424;
}
.modalBtnW {
  display: flex;
  justify-content: space-between;
}
.modalBtnL {
  font-size: 15px;
  width: 125px;
  height: 40px;
  color: #434343;
  background-color: rgba(227, 227, 227, 1);
  border-radius: 40px;
}
.modalBtnL:hover {
  color: #434343 !important;
  background-color: rgba(227, 227, 227, 0.7) !important;
}
.modalBtnR {
  font-size: 15px;
  width: 81px;
  height: 40px;
  border-radius: 40px;
}
.content {
  font-size: 20px;
  font-weight: 400;
  color: #2e2e2e;
}
</style>
<style>
.verification-code-modal .ant-modal-content {
  padding: 21px 38px 31px !important;
}
</style>