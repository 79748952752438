<template>
  <div :class="$style.wrap">
    <div :class="$style.wrapTop">
      <!-- 平台筛选 -->
      <div :class="$style.gameList">
        <div
          :class="[
            $style.gameListI,
            searchValue.platform === null && $style.gameListIActive,
          ]"
          @click="onChangePlatform(null)"
        >
          所有游戏
        </div>
        <div
          v-for="platform in titlePlatforms"
          :key="platform.id"
          :class="[
            $style.gameListI,
            searchValue.platform === platform.id && $style.gameListIActive,
          ]"
          @click="onChangePlatform(platform.id)"
        >
          <!-- <img :class="$style.gameListII" :src="getPlatformIcon(platform.name)" alt="" /> -->
          <div>{{ platform.name }}</div>
        </div>
        <!-- <div :class="[$style.gameListI, searchValue?.gamePingtai === 'phone' && $style.gameListIActive]" @click="onChangeGType('phone')">
          <img :class="$style.gameListIP" :src="gamePIcon" alt="" />
          <div>手机游戏</div>
        </div> -->
      </div>
      <!-- 游戏小方块列表 -->
      <div :class="$style.gameCurList">
        <div :class="[$style.gameCurListL, gameEnums?.length === 16 && $style.gameCurListLm]">
          <div
            v-for="item of gameEnums"
            :class="[
              $style.gameCurListI,
              gameEnums?.length === 16 && $style.gameCurListIm,
              // curPageGame === item?.game_name && $style.gameCurListIActive,
            ]"
            @click="$onClickGame(item)"
          >
            <div
              :class="[
                $style.gameCurListII,
                // curPageGame === item?.game_name && $style.gameCurListIIActive,
              ]"
            >
              <Image
                :class="$style.gameCurListIII"
                :src="getFullImageUrl(item?.game_icon_img)"
                :preview="false"
              />
            </div>
            <a-tooltip>
              <template #title>{{ item?.game_name || "-" }}</template>
              <div :class="$style.gameCurListIT">
                {{ item?.game_name || "-" }}
              </div>
            </a-tooltip>
          </div>
        </div>
        <div
          :class="$style.gameCurListIAll"
          style="user-select: none"
          @click="
            () => {
              isShowAllGameList = !isShowAllGameList;
            }
          "
        >
          <div
            :class="$style.gameCurListII"
            style="background-color: #237aff; border: none"
          >
            <img :class="$style.allGameImg" :src="allGameImg" alt="" />
          </div>
          <div :class="$style.gameCurListIT">全部游戏</div>
        </div>
      </div>
      <div v-show="isShowAllGameList">
        <div :class="$style.gameAllList">
          <div
            v-for="item of letters_level1"
            :class="[
              $style.gameAllListI,
              curLetterLevel1 === item && $style.gameAllListIActive,
            ]"
            @click="onChangeLetterLeve1(item)"
          >
            {{ item }}
          </div>
        </div>
        <div :class="$style.gameAllList">
          <div
            v-for="item of availableLetters"
            :class="[
              $style.gameAllListI,
              curLetter === item && $style.gameAllListIActive,
            ]"
            @click="onChangeLetter(item)"
          >
            {{ item }}
          </div>
        </div>
        <div :class="$style.filterTopGameW">
          <div
            v-for="item of filterTopGame"
            :class="$style.filterTopGameWI"
            @click="
              () => {
                $onClickGame(item);
                // isShowAllGameList = false;
                // curLetter = '';
                // filterTopGame = [];
              }
            "
          >
            {{ item?.game_name || "-" }}
          </div>
        </div>
      </div>
    </div>

    <!-- 排序筛选 -->
    <div :class="$style.sortType">
      <div
        :class="[
          $style.sortTypeI,
          searchValue.sort === 'default' && $style.sortTypeIActive,
        ]"
        @click="onChangeSort('default')"
      >
        <div>默认</div>
        <img :class="$style.sortTypeII" :src="getSortIcon('default')" alt="" />
      </div>
      <div
        :class="[
          $style.sortTypeI,
          searchValue.sort === 'new' && $style.sortTypeIActive,
        ]"
        @click="onChangeSort('new')"
      >
        <div>最新</div>
        <img :class="$style.sortTypeII" :src="getSortIcon('new')" alt="" />
      </div>
      <div
        :class="[
          $style.sortTypeI,
          searchValue.sort === 'sale' && $style.sortTypeIActive,
        ]"
        @click="onChangeSort('sale')"
      >
        <div>销量</div>
        <img :class="$style.sortTypeII" :src="getSortIcon('sale')" alt="" />
      </div>

    </div>
    <div :class="$style.gameRes">
      <div :class="$style.gameResLeft">
        <div :class="$style.gameListContainer">

          <div
            v-for="item in games"
            :key="item.id"
            :class="$style.gameResItem"
            @click="
              () => {
                $onClickGame(item);
              }
            "
          >
            <!-- zhanghaoguanjia游戏列表 -->
            <div
              :class="$style.gameResItemIn"
              v-if="item.provider === gameProvider.zuhaoguanjia"
            >
              <img
                :class="$style.gameResItemInI"
                :src="getFullImageUrl(item.img)"
                alt=""
              />
              <div :class="$style.gameResItemInfo">
                <a-tooltip>
                  <template #title>{{ item.gname || "-" }}</template>
                  <div :class="$style.gameResItemInfoG">
                    {{ item.gname || "-" }}
                  </div>
                </a-tooltip>
                <a-tooltip>
                  <template #title>{{ item.ename || "-" }}</template>
                  <div :class="$style.gameResItemInfoE">
                    【永久版】永久使用
                  </div>
                </a-tooltip>
                <div :class="$style.gameResItemInfoT">
                  <!-- 会员免费提示 -->
                  <div 
                    v-if="item?.is_allow_vip_free && item?.provider === gameProvider.zuhaoguanjia"
                    :class="$style.gameResItemInfoTIVIP"
                  >
                    <span>会员免费</span>
                  </div>

                  <div 
                    v-if="item?.is_allow_vip_free && item?.provider === gameProvider.zuhaoguanjia"
                    v-for="category in item.categories.slice(0, 2) || []"
                    :key="category"
                    :class="$style.gameResItemInfoTI"
                  >
                    {{ category || "未知类型" }}
                  </div>
                  <div 
                    v-else
                    v-for="category1 in item.categories.slice(0, 3) || []"
                    :key="category1"
                    :class="$style.gameResItemInfoTI"
                  >
                    {{ category1 || "未知类型" }}
                  </div>
                </div>

                <div :class="$style.gameResItemInfoTLast">
                  <div :class="$style.gameResItemInPriceP">
                    ¥{{ item?.sku_price_info?.show_price_info?.use_dkj_price.toFixed(2) }}
                  </div>
                  <div :class="$style.gameResItemInPriceBR">
                    {{
                      item?.sku_price_info?.show_price_info?.game_discount_rate
                        ? Math.floor(
                            item?.sku_price_info?.show_price_info
                              ?.game_discount_rate
                          )
                        : 0
                    }}%OFF
                  </div>
                  <a-button
                      :class="[$style.gameResItemInBtn, $style.gameResItemInBtnB]"
                      type="primary"
                      @click="
                        (e) => {
                          // e.stopPropagation();
                          // $onClickGame(item);
                        }
                      "
                    >立即购买
                  </a-button>
                </div>

              </div>

            </div>

            <div
              :class="$style.gameResItemIn"
              v-if="item.provider === gameProvider.zuhaowan"
            >
              <img
                :class="$style.gameResItemInI"
                :src="getFullImageUrl(item.img)"
                alt=""
              />
              <div :class="$style.gameResItemInfo">
                <a-tooltip>
                  <template #title>{{ item.gname || "-" }}</template>
                  <div :class="$style.gameResItemInfoG">
                    {{ item.gname || "-" }}
                  </div>
                </a-tooltip>
                <a-tooltip>
                  <template #title>{{ item.ename || "-" }}</template>
                  <div :class="$style.gameResItemInfoE">
                    【小时版】一小时起租
                  </div>
                </a-tooltip>
                <div :class="$style.gameResItemInfoT">
                  <!-- 会员免费提示 -->
                  <div :class="$style.gameResItemInfoTIVIP">
                    <span>免押金</span>
                  </div>

                  <div 
                    v-for="category1 in item.categories.slice(0, 2) || []"
                    :key="category1"
                    :class="$style.gameResItemInfoTI"
                  >
                    {{ category1 || "未知类型" }}
                  </div>

                </div>

                <div :class="$style.gameResItemInfoTLast">
                  <div :class="$style.gameResItemInPriceP">
                    ¥{{ item?.sku_price_info?.show_price_info?.use_dkj_price.toFixed(2) }}
                    
                  </div>
                  <span :class="$style.gameResItemInPriceBR"> 元起/时</span>

                  <a-button
                      :class="[$style.gameResItemInBtn, $style.gameResItemInBtnB]"
                      type="primary"
                      @click="
                        (e) => {
                          // e.stopPropagation();
                          // $onClickGame(item);
                        }
                      "
                    >立即租用
                  </a-button>
                </div>

              </div>

            </div>
          </div>
        </div>
        <!-- 分页 -->
        <a-pagination
          :current="searchValue.page"
          :pageSize="searchValue.limit"
          :total="total"
          @change="onPageChange"
          @showSizeChange="onPageSizeChange"
        />
      </div>

      <!-- 筛选条件 -->
      <div :class="['gameResRight', $style.gameResRight]">
        <a-collapse
          v-model:activeKey="activeKey"
          expand-icon-position="end"
          :bordered="false"
        >
          <a-collapse-panel key="1" header="游戏平台">
            <a-radio-group
              v-model:value="searchValue.platform"
              style="width: 100%"
              @change="onClearSearch"
            >
              <a-row>
                <a-col :span="12">
                  <a-radio :value="null">全部</a-radio>
                </a-col>
                <a-col
                  :span="12"
                  v-for="platform in platforms"
                  :key="platform.id"
                >
                  <a-radio :value="platform.id">{{ platform.name }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="游戏级别">
            <a-radio-group
              v-model:value="searchValue.level"
              style="width: 100%"
              @change="onClearSearch"
            >
              <a-row>
                <a-col :span="12">
                  <a-radio value="all">全部</a-radio>
                </a-col>
                <a-col :span="12" v-for="level in levels" :key="level.id">
                  <a-radio :value="level.id">{{ level.name }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="游戏类型">
            <a-checkbox-group
              v-model:value="searchValue.category"
              style="width: 100%"
              @change="onClearSearch"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="category in categories"
                  :key="category.id"
                >
                  <a-checkbox :value="category.id">{{
                    category.name
                  }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@/axios";
import Image from "@components/Image.vue";
import { getFullImageUrl } from "@/util/utils.js";
import pinyinUtil from "@/util/pinyinUtil";

// 图标
import steamIcon from "@assets/steamIcon.png";
import epicIcon from "@assets/epicIcon.png";
import ybIcon from "@assets/ybIcon.png";
import downSIcon from "@assets/downSIcon.png";
import upSIcon from "@assets/upSIcon.png";
import gamePIcon from "@assets/gamePIcon.png";
import allGameImg from "@assets/allGameImg.png";
import vipBtnIcon from "@assets/vipBtnIcon.png";

const activeKey = ref(["1", "2", "3", "4"]);
const gameProvider = {
  zuhaowan: "zuhaowan",
  zuhaoguanjia: "zuhaoguanjia",
};

const props = defineProps({
  qrCategory: String, // 路由传递的游戏类型
});
const store = useStore();
const searchGname = computed(() => store.state.searchGname);
const letters_level1 = [
  "所有游戏",
  "Steam",
  "手游",
  "端游",
];

const baseLetters = [
  "热门",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "W",
  "X",
  "Y",
  "Z",
];

// 响应式数据
const router = useRouter();
const recommendedGames = ref([]);
const games = ref([]);
const gameEnums = ref([]);
const gameAllEnums = ref([]);
// const curPageGame = ref("");
const isShowAllGameList = ref(false);
const platforms = ref([]);
const titlePlatforms = ref([]);
const levels = ref([]);
const categories = ref([]);
const total = ref(0);
const curLetter = ref("热门");
const curLetterLevel1 = ref("所有游戏")
const filterTopGame = ref([]);
const is_first_load = ref(false);

const searchValue = reactive({
  platform: null,
  level: null,
  category: props?.qrCategory ? [+props?.qrCategory] : [],
  sort: "default",
  page: 1,
  limit: 25,
  q: "",
  sort1: "-weight", // 默认排序为权重降序
  sort2: "created", // 默认排序为创建时间降序
  sort3: "sale_count" // 默认排序为销量降序
});


// 修改后的filteredGames计算属性
const filteredGames = computed(() => {
  if (curLetterLevel1.value === '所有游戏') {
    return gameAllEnums.value;
  }
  const selectedPlatform = titlePlatforms.value.find(p => p.name === curLetterLevel1.value);
  return selectedPlatform 
    ? gameAllEnums.value.filter(game => game.platform === selectedPlatform.id)
    : gameAllEnums.value;
});

// availableLetters计算属性保持原样，因为它基于filteredGames
const availableLetters = computed(() => {
  if (curLetterLevel1.value === '所有游戏') return baseLetters;
  const lettersSet = new Set();
  filteredGames.value.forEach(item => {
    const firstChar = item.game_name.charAt(0);
    let letter = /^[a-zA-Z]$/.test(firstChar) 
      ? firstChar.toUpperCase() 
      : pinyinUtil.getFirstLetter(firstChar)?.[0]?.toUpperCase() || '';
    if (letter) lettersSet.add(letter);
  });
  return ['热门', ...Array.from(lettersSet).sort()];
});

// filterTopGame保持原逻辑，但此时filteredGames已正确过滤平台
watchEffect(() => {
  filterTopGame.value = filteredGames.value.filter(item => {
    if (curLetter.value === '热门') return item.is_hot;
    const firstChar = item.game_name.charAt(0);
    const pyChar = pinyinUtil.getFirstLetter(firstChar)?.[0]?.toUpperCase();
    return curLetter.value === pyChar || firstChar.toUpperCase() === curLetter.value;
  });
});


// 获取游戏枚举数据
const fetchGameEnum = async () => {
  const res = await axios.post("web/get_game_show_rule_list/", { type: "store_show_ids" });
  gameEnums.value = res?.data?.data?.splice(0, 16) || [];

  const _res = await axios.post("web/get_game_enum/", {});
  gameAllEnums.value = _res?.data?.data || [];
};

// 获取枚举数据
const fetchPlatforms = async () => {
  const res = await axios.post("web/get_game_platforms/");
  platforms.value = res?.data?.data || [];
  titlePlatforms.value = platforms.value.filter(item => ![2, 3, 4, 5].includes(item.id));
};

const fetchLevels = async () => {
  const res = await axios.post("web/members/");
  levels.value = res?.data?.data || [];
};

const fetchCategories = async () => {
  const res = await axios.post("web/game_categories/");
  categories.value = res?.data?.data || [];
};

// 获取推荐游戏
const fetchRecommendedGames = async () => {
  const res = await axios.post("web/games-recommend/", { method: "new_games_recommendations_ids" });
  recommendedGames.value = res?.data?.data || [];
};

// 获取游戏列表
const fetchGames = async () => {  
    const res = await axios.post("web/games/", {
      is_collection: true,
      ...searchValue,
      platform: searchValue.platform,
      level: searchValue.level,
      category: searchValue.category,
      page: searchValue.page,
      limit: searchValue.limit,
      sort1: searchValue.sort1 || "-weight", // 默认值为 `-weight`
      sort2: searchValue.sort2 || "-created", // 默认值为 `-created`
      sort3: searchValue.sort3 || "-sale_count", // 默认值为 `-sale_count`
    });
    games.value = res?.data?.data?.items || [];
    total.value = res?.data?.data?.total || 0;
};


// 页面加载时调用
onMounted(() => {
  is_first_load.value = true;
  fetchGameEnum();
  fetchPlatforms();
  fetchLevels();
  fetchCategories();
  fetchRecommendedGames();
  fetchGames();
});

// 监听搜索条件变化
watch(
  () => ({ ...searchValue }), // 只监听必要属性
  (newVal, oldVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      is_first_load.value = false;
      console.log("searchValue", JSON.stringify(newVal));
      console.log("searchValue", JSON.stringify(oldVal));
      fetchGames();
    }
  },
  { deep: true }
);


watchEffect(() => {
  searchGname && (searchValue.q = searchGname);
});

// watch(curPageGame, () => {
//   curPageGame && (searchValue.q = curPageGame);
// });

// 清空顶部搜索
const onClearSearch = () => {
  store.commit("SET_SEARCHGNAME", "");
  store.commit("SET_SEARCHGNAMEINPUT", "");
};

// 修改筛选条件
const onChangePlatform = (platform) => {
  onClearSearch();
  searchValue.platform = platform;
};

const onChangeSort = (sort) => {
  // 根据点击的排序类型更新对应的排序字段
  if (sort === "default") {
    searchValue.sort1 = searchValue.sort1 === "-weight" ? "weight" : "-weight";
  } else if (sort === "new") {
    searchValue.sort2 = searchValue.sort2 === "-created" ? "created" : "-created";
  } else if (sort === "sale") {
    searchValue.sort3 = searchValue.sort3 === "-sale_count" ? "sale_count" : "-sale_count";
  }

  // 更新当前选中的排序类型
  searchValue.sort = sort;
};


const getSortIcon = (sort) => {
  if (sort === "default") {
    return searchValue.sort1 === "-weight" ? downSIcon : upSIcon;
  } else if (sort === "new") {
    return searchValue.sort2 === "-created" ? downSIcon : upSIcon;
  } else if (sort === "sale") {
    return searchValue.sort3 === "-sale_count" ? downSIcon : upSIcon;
  }
  return downSIcon; // 默认降序图标
};



const onPageChange = (page) => {
  searchValue.page = page;
};

const onPageSizeChange = (current, size) => {
  searchValue.page = current;
  searchValue.limit = size;
};


const onChangeLetterLeve1 = (cur) => {
  curLetterLevel1.value = cur;
  curLetter.value = '热门'; // 重置字母筛选
  isShowAllGameList.value = true; // 保持列表展开
};


const onChangeLetter = (cur) => {
  curLetter.value = cur;
};

// 获取平台图标
const getPlatformIcon = (name) => {
  if (name === "Steam") return steamIcon;
  if (name === "Epic") return epicIcon;
  if (name === "Uplay") return ybIcon;
  return null;
};
</script>

<style module>
.wrap {
  padding: 13px 230px 0;
}

.wrapTop {
  padding: 16px 16px 20px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  border-radius: 8px;
}

.gameList {
  display: flex;
  margin-bottom: 13px;
}

.gameAllList {
  /* padding: 16px 0; */
  display: flex;
  margin-top: 16px;
}
.gameAllListI {
  margin-right: 8px;
  line-height: 28px;
  font-size: 14px;
  padding: 0 7px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
}
.gameAllListIActive {
  background-color: #237aff;
  color: #ffffff;
}
.filterTopGameW {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}
.filterTopGameWI {
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}
.filterTopGameWI:hover {
  color: #237aff;
}

.gameListI {
  width: 96px;
  height: 36px;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  cursor: pointer;
  color: #393939;
}

.gameListIActive {
  background-color: #237aff;
  color: #fff;
}

.gameListII {
  width: 33px;
  height: 33px;
  margin-right: 18px;
}

.gameListIP {
  width: 56px;
  height: 56px;
  margin-right: 9px;
}

.gameListIYB {
  width: 42px;
  height: 40px;
  margin-right: 12px;
}

.gameListIEP {
  width: 36px;
  height: 41px;
  margin-right: 19px;
}

.gameCurList {
  display: flex;
  justify-content: space-between;
}
.gameCurListL {
  flex-grow: 1;
  display: flex;
}
.gameCurListI {
  width: 70px;
  cursor: pointer;
  margin-right: 15px;
  font-size: 13px;
  color: #2b2b2b;
}
.gameCurListLm {
  justify-content: space-between;
}
.gameCurListIm {
  margin-right: 0 !important;
}
.gameCurListIAll {
  width: 70px;
  margin-left: 15px;
  font-size: 13px;
  color: #2b2b2b;
}

.gameCurListII {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e3e3e3;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
}

.gameCurListIIActive {
  border: 2px solid #237aff;
}

.gameCurListIActive {
  color: #237aff;
}

.gameCurListIII {
  width: 100% !important;
  height: auto; /* 高度自动调整，保持比例 */
  object-fit: contain; /* 确保图片完整显示，保持比例，可能会有空白区域 */
  display: block; /* 避免图片被当作行内元素，影响布局 */
}

.gameCurListIT {
  width: 70px;
  text-align: center;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allGameImg {
  width: 36px;
  height: 36px;
}

.sortType {
  display: flex;
  align-items: center;
  margin: 7px 15px;
}

.sortTypeI {
  font-size: 16px;
  font-weight: 400;
  color: #393939;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-right: 65px;
  cursor: pointer;
}

.sortTypeIActice {
  background-color: #f5f7fa;
  /* color: #fff; */
}

.sortTypeII {
  width: 13px;
  height: 8px;
  margin-left: 15px;
}

.topPic {
  display: flex;
  margin-bottom: 25px;
}

.topPicI {
  flex-shrink: 0;
  margin-right: 35px;
  cursor: pointer;
}

.topPicII {
  width: 264px;
  height: 123px;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 9px;
}

.topPicGN {
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  color: #363636;
  margin-bottom: 2px;
}

.topPicGE {
  line-height: 10px;
  font-size: 7px;
  color: #363636;
  margin-bottom: 10px;
}

.divDir {
  width: 100%;
  height: 1px;
  background-color: #f8f8f8;
}
.contentTit {
  line-height: 35px;
  font-size: 24px;
  color: #ff870f;
  margin: 15px 0;
}

.gameRes {
  display: flex;
}

/* 移除原来的固定宽度 */
.gameResLeft {
  width: 1119px;
  flex-shrink: 0;
  margin-right: 21px;
}

.gameResRight {
  flex-grow: 1;
  flex-shrink: 0;
  width: 0;
  border-radius: 8px;
  padding: 0 18px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
}

.gameListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 卡片间距 */
  margin-bottom: 30px;
}

.gameResItem {
  flex: 0 1 calc(50% - 5px); /* 两列布局 */
  max-width: calc(50% - 5px);
  box-sizing: border-box;
  margin-bottom: 1px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  cursor: pointer;
}

.gameResItemIn {
  display: flex;
  align-items: center;
}

.gameResItemInI {
  width: 232px;
  height: 108px;
  border-radius: 5px;
  margin-right: 21px;
}
.gameResItemInIO {
  width: 121px;
  height: 110px;
  border-radius: 5px;
  margin-right: 9px;
}

.gameResItemInfo {
  width: calc(100% - 232px);
  overflow: hidden;
  /* margin-right: 73px; */
}
.gameResItemInfoO {
  width: 410px;
  overflow: hidden;
  margin-right: 95px;
}

.gameResItemInfoG {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  color: #363636;
  line-height: 15px;
  margin-bottom: 4px;
}
.gameResItemInfoGO {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  font-weight: bold;
  color: #363636;
  line-height: 25px;
  margin-bottom: 16px;
}

.gameResItemInfoE {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  font-size: 13px;
  color: #363636;
  margin-bottom: 5px;
}
.gameResItemOtherA {
  display: flex;
  font-size: 13px;
  margin-bottom: 15px;
}
.gameResItemOtherAa {
  color: #237aff;
  margin-right: 24px;
}
.gameResItemOtherAb {
  color: #ff2121;
  margin-right: 24px;
}
.gameResItemOtherAc {
  color: #2c2c2c;
}

.gameResItemInfoT {
  display: flex;
  margin-bottom: 7px;
}

.gameResItemInfoTLast {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.gameResItemInfoTIVIP {
  height: 23px;
  line-height: 23px;
  padding: 0 8px;
  margin-right: 4px;
  font-size: 15px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #F5B812;
}

.gameResItemInfoTI {
  height: 23px;
  line-height: 23px;
  padding: 0 8px;
  margin-right: 4px;
  font-size: 13px;
  border-radius: 4px;
  color: #58595a;
  background-color: #e3e3e3;
}
.gameResItemOtherB {
  display: flex;
}
.gameResItemOtherBI {
  width: 59px;
  height: 24px;
  border: 1px solid #ff870f;
  line-height: 24px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  color: #ff870f;
  background-color: rgba(255, 135, 15, 0.19);
  margin-right: 13px;
}

.gameResItemInP {
  align-self: flex-start;
  width: 100%;
  height: 29px;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 400;
  color: #363636;
}

.gameResItemInPw {
  width: 240px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.gameResItemInPrice {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.gameResItemInPriceB {
  height: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.gameResItemInPriceBzhw {
  font-size: 15px;
  font-weight: 400;
  color: #363636;
  line-height: 21px;
  margin-bottom: 11px;
}

.gameResItemInPriceI {
  width: 71px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  padding-right: 8px;
  font-size: 16px;
  color: #934c05;
  background-image: url("../../assets/jhbgImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 9px;
}

.gameResItemInPriceP {
  display: flex;
  font-size: 25px;
  font-weight: bold;
  color: #ff3636;
  margin-right: 5px;
}
.gameResItemInPricePO {
  font-size: 20px;
  font-weight: bold;
  color: #ff3636;
}
.gameResItemInPricePD {
  font-size: 23px;
}
.gameResItemInPricePDO {
  font-size: 16px;
}

.gameResItemInPriceBL {
  font-size: 18px;
  color: #e3e3e3;
  text-decoration: line-through;
  /* margin-left: 12px; */
  margin-right: 30px;
}

.gameResItemInPriceBR {
  font-size: 16px;
  color: #07b200;
  font-weight: bold;
}
.gameResItemInBtnW {
  flex-grow: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.gameResItemInBtn {
  height: 31px;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
  margin-left: auto;
}
.gameResItemInBtnB {
  background-color: rgba(255, 135, 15, 1);
}

.gameResItemInBtnB:hover {
  background-color: rgba(255, 135, 15, 0.7) !important;
}
.gameResItemInBtnI {
  width: 16px;
  height: 15px;
  margin-right: 7px;
}
.gameResItemInBtnV {
  color: #71400e;
  background-color: #ffffff !important;
  background-image: url("../../assets/vipBtnBgc.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none !important;
  border: none !important;
}
.gameResItemInBtnV:hover {
  color: #71400e !important;
  background-color: #ffffff !important;
}
.gameResItemInBtnS {
  background-color: rgba(255, 135, 15, 1);
}
.gameResItemInBtnS:hover {
  background-color: rgba(255, 135, 15, 0.7) !important;
}
</style>
<style>
.gameResRight .ant-collapse-borderless {
  background-color: #fff !important;
}

.gameResRight .ant-collapse-header {
  align-items: center !important;
  padding: 28px 0 21px !important;
  border-bottom: 2px solid #eaeaea;
}

.gameResRight .ant-collapse-header-text {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.gameResRight .ant-collapse-header .ant-collapse-arrow {
  font-size: 16px !important;
}

.gameResRight .ant-collapse-content-box {
  padding: 0 !important;
  margin-top: 30px !important;
}

.gameResRight .ant-collapse-item {
  border-bottom: none !important;
}

.gameResRight .ant-radio-wrapper,
.gameResRight .ant-checkbox-wrapper {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin-bottom: 30px !important;
}
</style>
