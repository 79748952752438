<template>
  <div :class="$style.headerWrap">
    <div :class="$style.left">
      <img :class="$style.icon" :src="zuhaoguanjiaImg" alt="" @click="onClickImg" />
      <a-menu
        class="cuMenu"
        :selectedKeys="navSelectedKeys"
        mode="horizontal"
        :items="items"
        :disabledOverflow="true"
        @click="handleClick"
      />
    </div>
    <div :class="$style.right">
      <a-auto-complete
        :value="searchGnameInput"
        :options="gameOptions"
        :filter-option="false"
        @search="handleSearchGame"
        @select="handleSelectGame"
        @keydown="handleKeyDown"
        :defaultActiveFirstOption="false"
      >
        <a-input
          :value="searchGnameInput"
          placeholder="游戏搜索"
          :class="$style.input"
          allow-clear
          @input="handleInput"
          @compositionstart="isComposing = true"
          @compositionend="handleCompositionEnd"
        >
          <template #suffix>
            <a-button :class="$style.inputBtn" type="primary" shape="round" @click="onClicksearch">
              搜索
            </a-button>
          </template>
        </a-input>
      </a-auto-complete>
      <div :class="$style.cdkBtn" @click="onCdkC">CDK兑换</div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import { ref, h, useCssModule, computed, onMounted } from "vue";
  import zuhaoguanjiaImg from "@assets/zuhaoguanjia.png";
  import { useRouter } from "vue-router";
  import axios from "@/axios";
  import { SearchOutlined } from '@ant-design/icons-vue';

  const store = useStore();
  const searchGnameInput = computed(() => store.state.searchGnameInput);
  const styles = useCssModule();
  const router = useRouter();
  const gameOptions = ref([]);
  const originalGameOptions = ref([]);
  const isComposing = ref(false);

  const handleInput = (event) => {
    if (isComposing.value) {
      return; // 如果正在组合输入，忽略 handleInput 的处理
    }
    console.log('handleInput', searchGnameInput.value, event.key)
    store.commit("SET_SEARCHGNAMEINPUT", event.target.value || "");
    handleSearchGame(event.target.value);
  }

  const handleCompositionEnd = (event) => {
    console.log('handleCompositionEnd', searchGnameInput.value, event.key)
    isComposing.value = false; // 输入法结束
    handleInput(event);
    // handleSearchGame(event.target.value); // 手动触发搜索
  }

  // 本地模糊搜索
  const handleSearchGame = (val) => {
    const searchValue = val.toLowerCase(); // 转为小写以实现大小写不敏感搜索
    if (!searchValue) {
      gameOptions.value = [];
      return;
    }
    gameOptions.value = originalGameOptions.value.filter((item) =>
      item.label.toLowerCase().includes(searchValue)
    );
  };

  // 当选择一个游戏时，清空 SKU 并加载对应的 SKU 数据
  const handleSelectGame = (val) => {
    store.commit("SET_SEARCHGNAMEINPUT", val || "");
    store.dispatch("setSearchGname", val || "");
    router.push(`/gameStore`);
  };

  // 自定义提示框内容
  const dropdownRender = (menu) => {
    if (gameOptions.value.length === 0) {
      return h("div", { style: { padding: "10px", textAlign: "center" } }, "未找到相关游戏");
    }
    return menu;
  };

  // 节流处理，避免频繁请求
  const fetchGameData = async () => {
    const res = await axios.post("web/get_game_enum_v2/", {}).catch(() => {});
    if (res?.data?.status === 10000) {
      originalGameOptions.value = res?.data?.data?.map(item => ({
        value: item,
        label: item,
      }));
    }
  };

  // 页面挂载时初始化游戏选项
  onMounted(() => {
    fetchGameData();
  });

  const navSelectedKeys = computed(() => store.state.navSelectedKeys);

  const items = ref([
    { key: "/", label: h("div", { class: styles.itemLabel }, "首页") },
    { key: "/gameStore", label: h("div", { class: styles.itemLabel }, "租号商城") },
    { key: "/myGame", label: h("div", { class: styles.itemLabel }, "我的游戏") },
    { key: "/myMember", label: h("div", { class: styles.itemLabel }, "会员中心") },
    { key: "/news/54.html", label: h("div", { class: styles.itemLabel }, "加入赚钱") },
    { 
      key: "/rendResult", 
      label: h("div", { class: styles.itemLabel }, "正在租用"),
      // children: [
      //   { key: "/rendResult", label: h("div", { class: styles.itemLabel }, "正在租用") },
      //   { key: "/gysRendResult", label: h("div", { class: styles.itemLabel }, "供应商正在租用") },
      // ],
    },

  ]);

  const onClickImg = () => {
    router.push("/");
  };

  // 切换导航
  const handleClick = (e) => {
    if (e.key.startsWith("/news")) {
      // 如果路由以 /news 开头，则在新窗口打开
      window.open(e.key, "_blank");
    } else {
      store.dispatch("setNavSelectedKeys", [e.key]);
      store.dispatch("setSearchGname", null);
      router.push(e.key);
    }
  };

  // 游戏搜索
  const handleKeyDown = (event) => {
    console.log('handleKeyDown', searchGnameInput.value, event.key)
    if (event.key === "Enter") {
      // event.preventDefault(); // 阻止默认行为
      // store.dispatch("setSearchGname", null); // 设置为临时值
      store.dispatch("setSearchGname", searchGnameInput.value); // 重新设置为目标值
      // store.dispatch("setSearchGname", searchGnameInput.value);
      router.push(`/gameStore`);
    }
  };

  const onClicksearch = () => {
    console.log('gameName', searchGnameInput.value);
    // store.dispatch("setSearchGname", null); // 设置为临时值
    store.dispatch("setSearchGname", searchGnameInput.value);
    router.push(`/gameStore`);
  };

  // cdk兑换
  const onCdkC = () => {
    router.push("/cdk");
  };
</script>

<style module>
  .headerWrap {
    height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 230px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  .icon {
    width: 185px;
    margin-right: 38px;
    cursor: pointer;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .itemLabel {
    font-size: 16px;
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .input {
    width: 295px;
    height: 36px;
    font-size: 15px;
    border-radius: 36px;
  }
  .inputBtn {
    height: 30px;
    width: 70px;
    font-size: 15px;
    padding: 0;
    padding-inline: 0 !important;
    text-align: center;
  }
  .searchIcon {
    font-size: 18px;
    color: #D3D3D3;
    cursor: pointer;
  }
  .cdkBtn {
    margin-left: 20px;
    width: 107px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 36px;
    cursor: pointer;
    background: linear-gradient(rgba(147, 160, 255), rgba(38, 62, 247));
  }
</style>

<style scoped>
  .cuMenu .ant-menu-item {
    padding-inline: 16px !important;
    line-height: 46px !important;
  }
  .cuMenu {
    line-height: 46px !important;
  }
  :deep .ant-input-affix-wrapper {
    background-color: #F1F1F1;
    padding: 4px 3px 4px 11px;
  }
  :deep .ant-input {
    background-color: #F1F1F1;
  }
</style>
