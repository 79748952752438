<template>
  <div :class="$style.wrapOut">
    <div :class="$style.wrap">
      <div :class="$style.gameInfo">
        <div :class="$style.gameInfoI">
        <div :class="$style.infoLeft">
          <SplideCarousel :images="gameData?.lunbo || []" />
        </div>
        <div :class="$style.infoRight">
          <div :class="$style.rightTitle">{{ gameData?.gname || '-' }}</div>
          <div :class="$style.rightEtitle">
            <div :class="$style.collectL">{{'上号器上号'}}
            </div>
            <div :class="$style.collect" @click="$openInNewTab('/news/71.html')">{{'闲置帐号赚钱'}}
            </div>
          </div>
          <div :class="$style.rightSeriesWrap">
            <div :class="$style.rightSeries">
              <div :class="$style.series">
                <div :class="$style.seriesTitle">游戏分类</div>
                <div :class="$style.seriesConMI" v-for="item in gameData?.categories.slice(0,2)">{{ item }}</div>
              </div>
              <div :class="$style.series">
                <div :class="$style.seriesTitle">商家编号</div>
                <div :class="$style.seriesCon">
                  {{ gameData?.provider_number || "-" }}
                </div>
              </div>
            </div>
            <div :class="$style.rightSeries">
              <div :class="$style.series">
                <div :class="$style.seriesTitle">租号限制</div>
                <div :class="$style.seriesConM">
                  <div :class="$style.seriesConMI">押金0.00元</div>
                  <div :class="$style.seriesConMI">
                    {{ activeSku?.gys_min_rent_count }}小时起租
                  </div>
                  <div :class="$style.seriesConMI">可租时段：00:00-24:00</div>
                </div>
              </div>
            </div>
          </div>

          <!-- SKU 价格信息 -->
          <div :class="$style.rightSkuWrap">
            <div
              v-for="item in activeSku?.skus_prices || []"
              :key="item.id"
              :class="[
                $style.rightSku,
                curSku?.id === item?.id && $style.activeSku,
              ]"
              @click="setCurSku(item)"
            >
              <div
                :class="[
                  $style.rightPrice,
                  curSku?.id === item?.id && $style.activePrice,
                ]"
              >
                ¥{{ item?.show_price_info?.use_dkj_price?.toFixed(2) || '-' }}
              </div>
              <div :class="$style.rightDePrice">
                已抵扣：{{
                  item?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'
                }}
              </div>
              <div
                :class="[
                  $style.rightSkuName,
                  curSku?.id === item?.id && $style.acticeSkuName,
                ]"
              >
                {{ item?.sku_name || '-' }}
              </div>
            </div>
          </div>

          <!-- 自定义时间选择 -->
          <div
            v-show="curSku?.is_first_sku"
            :class="$style.rightText"
          >
            <div :class="$style.rightTexto">选择时间</div>
            <div :class="$style.rightTextt">
              <div
                v-for="item of activeSku?.rent_count_enums"
                :key="item"
                :class="$style.rightTexttI"
                @click="onClickTime(item)"
              >
                <img
                  v-show="timeRef === item"
                  :class="$style.rightTexttII"
                  :src="seleIcon"
                  alt=""
                />
                <div
                  v-show="timeRef !== item"
                  :class="$style.rightTexttIID"
                ></div>
                <div>{{ item }}小时</div>
              </div>
            </div>
            <!-- <a-input-number v-model:value="timeRef" :min="1" /> -->
            <el-input-number v-model="timeRef" :min="activeSku?.gys_min_rent_count" :max="72" />
          </div>
          <div
            :class="$style.rightText"
          >
            <div :class="$style.rightTexto">当前选择</div>
            <div :class="$style.rightTextt">
              <div :class="$style.seriesConMI">{{ activeTag?.tag_name }}</div> >
              <div :class="$style.seriesConMI">{{ activeSku?.sku_name }}</div> >
              <div :class="$style.seriesConMI">{{ timeRef }}小时</div>

            </div>
          </div>
          <div :class="$style.rightBtn">
            <a-button
              type="primary"
              :class="$style.rightBtnB"
              @click="openThirdPartyPayment"
              >立即租用</a-button
            >
            <a-button :class="$style.rightBtnV" @click="onClickCollect"
              >{{isCollect ? '取消收藏' : '点击收藏'}}</a-button
            >
          </div>
        </div>
      </div>
      <div>
        <!-- 标签按钮列表 -->
        <div :class="$style.tagsWrap">
          <div :class="$style.tags">
            <div :class="$style.tagTitle">游戏大区：</div>
            <div
              v-for="tag in skuTags"
              :key="tag.tag_id"
              :class="[
                $style.tagItem,
                activeTag?.tag_id === tag.tag_id && $style.activeTag,
              ]"
              @click="setActiveTag(tag)"
            >
              {{ tag.tag_name }}
            </div>
          </div>
        </div>

        <!-- SKU 按钮列表 -->
        <div :class="$style.skusWrap">
          <div :class="$style.tagTitle">账号等级：</div>
          <div
            v-for="sku in activeTag?.skus || []"
            :key="sku.sku_id"
            :class="[
              $style.skuItem,
              activeSku?.sku_id === sku.sku_id && $style.activeSku,
            ]"
            @click="setActiveSku(sku)"
          >
            {{ sku.sku_name }}
          </div>
        </div>

      </div>
      </div>
      

      <!-- 新游推荐 -->
      <div :class="$style.new">
        <GamesReBanner
          :left-icon="newIcon"
          :left-img="leftImg"
          :right-img="rightImg"
          left-title="新游推荐"
          :game-infos="getGameInfos(newGames)"
          @on-click-more="onClickMoreNew"
        />
      </div>
      <!-- Tab 页面 -->
      <div :class="$style.gameDetail">
        <a-tabs defaultActiveKey="1" :class="$style.tabWrap">
          <a-tab-pane key="1">
            <template #tab>
              <span style="font-size: 20px;">
                游戏介绍
              </span>
            </template>
            <div :class="$style.gameDetailCon" v-html="gameData?.introduction || ''"></div>
          </a-tab-pane>

          <a-tab-pane key="2">
            <!-- <div :class="$style.gameDetailTit">游戏存档</div> -->
            <template #tab>
              <span style="font-size: 20px;">
                游戏存档
              </span>
            </template>
            <div :class="$style.gameDetailCon">存档说明：并不是每个游戏都支持存档移档和替换操作，只支持大部分的情况。</div>
            <div :class="$style.gameDetailCon">存档位置：{{gameData?.web_archives_video_info?.title || '-'}}</div>
            
            <div :class="$style.videoWrap" v-if="gameData?.web_archives_video_info?.video_url">
              <div :class="$style.gameDetailCon">↓移档视频如下↓</div>
              <video controls style="width: 100%; height: auto;">
                <source :src="gameData?.web_archives_video_info?.video_url" type="video/mp4">
                您的浏览器不支持视频播放。
              </video>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <PaymentModal
      :isOpen="isOpen"
      :isOrderOpen="isOrderOpen"
      :curSku="curSku"
      :paymentType="paymentType"
      :orderInfo="orderInfo"
      @setIsOpen="backClosePayment"
      @setIsOrderOpen="(value) => (isOrderOpen = value)"
    />

    <RentAccountDialog
      :isOpen="isRentAccountDialog"
      :pkId="rentPkId"
      :rentType="'vip'"
      :curGame="gameData?.gname"
      @setIsOpen="setIsRentAccountDialog"
      @successCallBack="onRentAccountSuccess"
    />
    <RentalSuccessModal
      :is-open="isRentalSuccessOpen"
      :set-isOpen="(flag) => { isRentalSuccessOpen = flag}"
      :end-time="rentalEndTime"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { useRoute, useRouter } from 'vue-router'
  import axios from "@/axios";
  import { DownloadOutlined } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';

  import { getFullImageUrl, openLink } from "@/util/utils.js";
  import GamesReBanner from "@/views/homeView/components/GamesReBanner.vue";
  import BuyPlanModal from '@components/BuyPlanModal.vue';
  import RentAccountDialog from '@components/SelectAccountModal.vue';
  import RentalSuccessModal from '@components/RentalSuccessModal.vue';
  import SplideCarousel from '@components/splideCarousel.vue';
  import deLLeft from "@assets/deLLeft.png";
  import deLRight from "@assets/deLRight.png";
  import huiyuan from "@assets/huiyuan.png";
  import newIcon from "@assets/newIcon.png";
  import leftImg from "@assets/leftImg.png";
  import rightImg from "@assets/rightImg.png";
  import seleIcon from "@assets/seleIcon.png";

  const skuTags = ref([]); // 存储所有的 tags 数据
  const activeTag = ref(null); // 当前选中的 tag
  const activeSku = ref(null); // 当前选中的 sku
  const curSku = ref(null); // 当前选中的具体 sku 购买方案
  const timeRef = ref(1); // 自定义时间选择的默认值

  const route = useRoute();
  const router = useRouter();
  const gameData = ref();
  const skuData = ref();
  const is_allow_click_pay = ref(true);
  const vipData = ref();
  const newGames = ref([]);
  const isCollect = ref(false); // 是否收藏

  import PaymentModal from "@/components/BuyPlanModalv3.vue";
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const orderInfo = ref(null);
  const isRentalSuccessOpen = ref(false);
  const rentalEndTime = ref();

  import { useStore } from "vuex";
  const store = useStore();
  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
  const tmp = ref();

  // 设置选中的标签 (tag)
  const setActiveTag = (tag) => {
    activeTag.value = tag;
    setActiveSku(tag.skus[0]); // 默认选择第一个 sku
  };

  // 设置选中的 SKU
  const setActiveSku = (sku) => {
    activeSku.value = sku;
    curSku.value = sku?.skus_prices[0]; // 默认选择第一个价格方案
    timeRef.value = sku?.gys_min_rent_count;
  };

  // 设置选中的具体 SKU 购买方案
  const setCurSku = (sku) => {
    curSku.value = sku;
    if (sku?.is_first_sku) {
      timeRef.value = sku.gys_min_rent_count || 1; // 重置自定义时间选择
    } else {
      timeRef.value = sku.time_count;
    }

  };

  // 自定义时间选择
  // 自定义时间选择
  const onClickTime = (time) => {
    timeRef.value = time; // 更新选中的时间
    // curSku.value = null; // 清空当前 SKU 选择，避免干扰
  };


  // 获取 SKU 数据
  const fetchSkuData = async (gameId) => {
    const res = await axios
      .post("web/gys/zhw_get_sku_info_view_v2/", { game_id: gameId })
      .catch(() => {});
    if (res?.data?.status === 10000) {
      skuTags.value = res?.data?.data || [];
      if (skuTags.value.length > 0) {
        setActiveTag(skuTags.value[0]); // 默认选中第一个 tag
      }
    }
  };

  // 监听路由变化，重新获取数据
  watch(async () => {
    const gameId = route.params?.id;
    if (gameId) {
      await fetchSkuData(gameId);
    }
  });

  const backClosePayment = (value) => {
    tmp.value = Date.now();
    isOpen.value = value;
  }

  // 获取游戏详情
  watch(async () => {
    if (!route.params?.id) return;
    const res = await axios.post("web/game_detail/", { pk: route.params?.id}).catch(() => {});
    if (res?.data?.status === 10000) {
      gameData.value = res?.data?.data || {};
      isCollect.value = res?.data?.data?.is_collect || false;
      is_allow_click_pay.value = res?.data?.data?.is_allow_click_pay || 0;
    }
  })

  // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'new_games_recommendations_ids', limit: 12 }).catch(() => {});
    if (res?.data?.status === 10000) {
      newGames.value = res?.data?.data || [];
    }
  });

  const getGameInfos = (infos) => {
    const arr = [];
    const step = Math.ceil(infos?.length / 4);
    for(let i = 0; i < step; i++) {
      arr.push(infos?.slice(i * 4, (i + 1) * 4))
    }
    return arr;
  }

  // 点击新游推荐更多
  const onClickMoreNew = () => {
    router.push(`/gameStore`);
  }

  // 点击购买
  const openThirdPartyPayment = async () => {
    if (!curSku?.value) {
      message.warning("请选择购买方式");
      return;
    }
    let timeCount;
    let realPrice = curSku.value?.show_price_info?.use_dkj_price;
    let Price = curSku.value?.show_price_info?.price;
    let deduction = curSku.value?.show_price_info?.use_dkj_amount;
    let sku_gys_tpl_id = activeSku.value?.sku_gys_tpl_id;
    if (curSku.value?.is_first_sku) {
      timeCount = timeRef.value;
      if (timeCount < activeSku.value?.gys_min_rent_count) {
        return message.error(`最少租用${activeSku.value?.gys_min_rent_count}小时`);
      }
      const res = await axios
        .post("web/gys/eval_one_sku_info_view_v2/", {
          sku_gys_tpl_id: sku_gys_tpl_id,
          game_id: route.params?.id,
          sku_id: activeSku.value?.sku_id,
          time_count: timeRef.value,
        })
        .catch(() => {
          return;
        });
      realPrice = res?.data?.data?.use_dkj_price;
      Price = res?.data?.data?.price;
      deduction = res?.data?.data?.use_dkj_amount;
    } else {
      timeCount = curSku.value?.time_count;
    }
    paymentType.value = 3;
    orderInfo.value = {
      realPrice,
      Price,
      deduction,
      time_count: timeCount,
      time_remark: `${timeCount}小时`,
      game_id: route.params?.id,
      skuId: activeSku.value?.sku_id,
      // gys_account_number: route.params?.id,
      gname: curSku.value?.gname,
      sku_gys_tpl_id: sku_gys_tpl_id,
    };
    isOrderOpen.value = true;
    isOpen.value = false;
  };

  // 收藏 || 取消收藏
  const onClickCollect = async () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    if (isCollect.value) {
      const res = await axios.post("web/del_collect_game/", { game_id: route.params?.id }).catch(() => {});
      if (res?.data?.status === 10000) {
        isCollect.value = false;
      }
      return;
    }
    const res = await axios.post("web/collect_game/", { game_id: route.params?.id }).catch(() => {});
    if (res?.data?.status === 10000) {
      isCollect.value = true;
    }
  }


</script>

<style module>
.tagsWrap {
  margin-top: 20px;
}
.tags {
  display: flex;
  gap: 10px;
}
.tagItem {
  padding: 8px 16px;
  background-color: #f8f8f8;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.activeTag {
  background-color: #fde4d3;
  border-color: #f56912;
  color: #f56912;
}

.tagsWrap {
  margin-top: 20px;
}
.tags {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px;
  align-items: center; /* 垂直居中 */
}
.tagTitle {
  font-size: 20px;
  color: #2f2f2f;
  margin-bottom: 10px; /* 给标题留空间 */
  margin-top: 10px;
}

.skusWrap {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px;
  align-items: center; /* 垂直居中 */
}
.skuItem {
  padding: 8px 16px;
  background-color: #f8f8f8;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.activeSku {
  background-color: #fde4d3;
  border-color: #f56912;
  color: #f56912;
}

.wrapOut {
  padding-top: 10px;
}
.wrap {
  padding: 10px 230px;
  transform: scale(0.85);
  transform-origin: 50% 0;
  /* margin-bottom: -500px; */
}
.gameInfo {
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 18px 28px;
}
.gameInfoI {
  display: flex;
  /* border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 18px 28px; */
}
.infoLeft {
  flex-shrink: 0;
  width: 656px;
  height: 305px;
  margin-right: 30px;
}
.infoRight {
  flex-grow: 1;
  width: 0;
}
.rightTitle {
  font-size: 34px;
  color: #2f2f2f;
}
.rightEtitle {
  margin-top: 10px;
  cursor: pointer;
  display: flex;
}
.collect {
  text-align: end;
  /* flex-shrink: 0; */
  font-size: 19px;
  font-weight: 400;
  color: #ff870f;
  margin-left: auto;
}
.collectL {
  /* text-align: end; */
  /* flex-shrink: 0; */
  font-size: 19px;
  font-weight: 800;
  color: #fa0000;
}

.rightSeriesWrap {
  background-color: #fafafa;
  padding: 20px;
  margin-top: 20px;
}
.rightSeries {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.rightSeries:last-child {
  margin-bottom: 0;
}
.series {
  height: 25px;
  display: flex;
  align-items: center;
}
.seriesTitle {
  color: #767676;
  margin-right: 42px;
}
.seriesCon {
  color: #2b2b2b;
  font-weight: 400;
  white-space: nowrap; /* 防止内容换行 */
}
.seriesConI {
  font-weight: 400;
  width: 130px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  color: #237aff;
  border-radius: 2px;
  background-color: rgba(35, 122, 255, 0.16);
  cursor: pointer;
}
.seriesConM {
  display: flex;
  align-items: center;
}
.seriesConMI {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0 12px;
  background-color: rgba(201, 201, 201, 0.19);
  margin-right: 9px;
}
.seriesConMII {
  width: 77px;
  height: 30px;
  line-height: 30px;
  border-radius: 2px;
  background-color: rgba(255, 135, 15, 0.19);
  color: rgba(255, 135, 15, 1);
  margin-right: 16px;
  text-align: center;
  border: 1px solid rgba(255, 135, 15, 1);
}
.rightSkuWrap {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  /* overflow-x: auto; */
}
.rightSku {
  flex: 1;
  flex-shrink: 0;
  /* width: 171px; */
  height: 110px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  background-color: #f8f8f8;
  position: relative;
  margin-right: 18px;
  cursor: pointer;
}
.rightSku:last-child {
  margin-right: 0;
}
.rightPrice {
  font-size: 24px;
  color: #171c61;
  text-align: center;
  margin-top: 15px;
}
.rightDePrice {
  font-size: 14px;
  color: #c6c6c6;
  text-align: center;
  margin-top: 8px;
}
.rightSkuName {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  margin-top: auto;
  color: #272727;
  background-color: #eaeaea;
}
.activeSku {
  background-color: #fffbf8;
  border: 2px solid #f56912;
}
.activePrice {
  color: #ff1d1d;
}
.acticeSkuName {
  color: #ff1d1c;
  background-color: #fde4d3;
}
.rightText {
  display: flex;
  align-items: center;
  margin-top: 15px;
  height: 42px;
  line-height: 42px;
  background-color: #fffbf8;
  font-size: 18px;
}
.rightTexto {
  color: #767676;
  margin-left: 17px;
  margin-right: 29px;
}
.rightTextt {
  display: flex;
  align-items: center;
}
.rightTexttI {
  flex-shrink: 0;
  margin-right: 15px;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.rightTexttII {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}
.rightTexttIID {
  width: 17px;
  height: 17px;
  margin-right: 9px;
  border-radius: 17px;
  background-color: rgba(255, 135, 15, 0.31);
}
.rightBtn {
  margin-top: 15px;
}
.rightBtnB {
  font-size: 19px;
  width: 177px;
  height: 51px;
  background-color: rgba(255, 73, 49, 1);
  border-radius: 5px;
  margin-right: 16px;
}
.rightBtnB:hover {
  background-color: rgba(255, 73, 49, 0.7) !important;
}
.rightBtnV {
  font-size: 19px;
  width: 177px;
  height: 51px;
  border-radius: 5px;
  border: none;
  color: rgba(35, 122, 255, 1);
  background-color: rgba(234, 242, 253, 1);
}
.rightBtnV:hover {
  background-color: rgba(234, 242, 253, 0.7) !important;
  color: rgba(35, 122, 255, 0.7) !important;
}
.rightTip {
  margin-top: 21px;
  font-size: 16px;
  color: #5a5a5a;
}
.rightTip div {
  line-height: 30px;
}
.new {
  margin-top: 27px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 16px;
}
.gameDetail {
  margin-top: 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 16px;
}
.gameDetailT {
  width: 119px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 5px;
  background-color: #237aff;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}
.gameDetailCD {
  font-size: 21px;
  font-weight: 400;
  color: #5a5a5a;
  padding-bottom: 20px;
}
.gameDetailC {
  display: flex;
  flex-wrap: wrap;
}
.gameDetailCI {
  display: flex;
  align-items: center;
  width: 25%;
  font-size: 21px;
  font-weight: 400;
  color: #5a5a5a;
  margin-bottom: 20px;
}
.gameDetailCID {
  color: #ff870f;
}
.gameDetailCIT {
  flex-shrink: 0;
  height: 30px;
  line-height: 30px;
}
.gameDetailCIC {
  flex-grow: 1;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gameDetailCSp {
  padding: 0 50px;
  height: 800px;
  padding-bottom: 200px;
}
.videoWrap {
  margin-top: 10px;
}
.gameDetailCon {
    font-size: 22px;
  }
.custom-tab-title {
  font-size: 30px;
}
</style>
<style scoped>
:deep .ant-input-number {
  margin-left: auto;
}
:deep .ant-input-number-input {
  height: 35px;
  line-height: 35px;
  font-size: 15px;
}
</style>