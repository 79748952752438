<template>
  <div>
    <div :class="$style.wrap">
      <div :class="$style.content">
        <MyGameLib />
        <div :class="$style.conDir"></div>
        <div :class="$style.right">
          <div :class="$style.rentWrap">
            <img :class="$style.rentIcon" :src="listIcon" alt="" />
            <div>我的独享号</div>
          </div>
          <div :class="$style.tableW">
            <a-table
              :columns="columns"
              :row-key="record => record.id"
              :data-source="dataSource?.list || []"
              :pagination="pagination"
              :loading="loading"
              @change="handleTableChange"
            >
              <template #bodyCell="{ text, column, record }">
                <template v-if="column.key === 'operation'">
                  <button @click="fetchYzma(record.id)">验证码</button>
                </template>
              </template>
          </a-table>
          </div>
        </div>
      </div>
      <Recommend />
    </div>
    <!-- <a-modal
      v-model:visible="idVerificationCodeOpen"
      title="验证码"
      footer={null}
    >
      <p>验证码：{{ codeInfo?.code }}</p>
      <p>消息：{{ codeInfo?.msg }}</p>
    </a-modal> -->
  </div>
</template>

<script setup>
  import { ref, h, computed } from "vue";
  import axios from "@/axios";
  import { usePagination } from "vue-request";

  import Recommend from "@/views/homeView/Recommend.vue";
  import MyGameLib from "@components/MyGameLib.vue";
  import listIcon from "@assets/listIcon.png";

  const idVerificationCodeOpen = ref(false); // 控制弹窗显示
  const codeInfo = ref({ code: "", msg: "" }); // 验证码信息

  const fetchYzma = async (recordId) => {
    try {
      const response = await axios.post("web/account/yzma/", { yzid: recordId });
      if (response?.data?.status === 10000) {
        idVerificationCodeOpen.value = true;
        codeInfo.value = {
          code: response?.data?.data?.verification_code,
          msg: response?.data?.msg,
        };
      } else {
        console.error("Failed to fetch verification code:", response?.data?.msg);
      }
    } catch (error) {
      console.error("Error fetching verification code:", error);
    }
  };

  // 定义表格列
  const columns = [
    {
      title: "游戏名称",
      dataIndex: "gname",
      key: "gname",
    },
    {
      title: "游戏平台",
      dataIndex: "pingtai",
      key: "pingtai",
    },
    {
      title: "游戏账号",
      dataIndex: "saccount",
      key: "saccount",
    },
    {
      title: "密码",
      dataIndex: "spassword",
      key: "spassword",
    },
    {
      title: "邮箱",
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: "邮箱密码",
      dataIndex: "mpasword",
      key: "mpasword",
    },
    {
      title: "邮箱登陆地址",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "备注信息",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "令牌/验证码",
      key: "operation",
      width: '15%',
    },
  ];

  // 获取租用记录
  const queryRentData = async (params) => {
    try {
      const limit = Number(params?.limit) || 5;
      const page = Number(params?.page) || 1;
      // 发送 POST 请求，获取历史租用记录
      const res = await axios.post("web/duxiang_accounts/", { limit, page });
      return res;
    } catch (error) {
      console.error("Error fetching rent data:", error);
      throw error;
    }
  };

  // 使用分页 hook 获取数据
  const {
    data: dataSource, // 表格数据
    run, // 触发数据请求
    loading, // 加载状态
    current, // 当前页码
    pageSize, // 每页条数
    total,
  } = usePagination(queryRentData, {
    formatResult: (res) => ({
      list: res?.data?.data?.items || [],
      total: res?.data?.data?.total || 0,
    }),
    defaultParams: [
      {
        limit: 5,
      },
    ],
    pagination: {
      currentKey: "page",
      pageSizeKey: "limit",
    },
  });

  const pagination = computed(() => ({
    total: total.value,
    current: current.value,
    pageSize: pageSize.value || 5,
    pageSizeOptions: ["5", "10", "20", "50"], // 可选择的分页条数
    showSizeChanger: true, // 允许用户改变每页条数
    showTotal: (total) => `共 ${total} 条`,
  }));

  const handleTableChange = (pag, filters) => {
    run({
      limit: pag.pageSize,
      page: pag?.current,
      ...filters,
    });
  };
</script>

<style module>
  .wrap {
    padding: 13px 230px 0;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
    display: flex;
    padding: 28px 0;
    margin-bottom: 20px;
  }
  .conDir {
    width: 1px;
    background-color: #e8e8e8;
    margin-left: 26px;
    margin-right: 22px;
  }
  .rentWrap {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .rentIcon {
    width: 32px;
    margin-right: 18px;
  }
  .right {
    width: 0;
    flex-grow: 1;
  }
  .tableW {
    width: 100%;
    padding-right: 22px;
  }
</style>
<style scoped>
:deep .ant-table-cell {
  word-wrap: break-word; 
  word-break: break-all; 
}
</style>