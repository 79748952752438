
<!-- 租号成功弹窗 -->
<template>
  <a-modal
    :open="isOpen"
    :maskClosable="false"
    :destroyOnClose="true"
    :closable="false"
    width="100%"
    :wrapClassName="[$style.modal, 'rental-success']"
  >
    <template #footer>
      <div :class="$style.modalBtnW">
        <a-button :class="$style.modalBtn" type="primary" @click="onClickBtn"
          >知道了</a-button
        >
      </div>
    </template>
    <div :class="$style.header">
      <img :class="$style.headerImg" :src="successIcon" />
      <div :class="$style.headerText">租号成功</div>
    </div>
    <div :class="$style.topTip">请在到期前进行延长时间操作</div>
    <div :class="$style.content1">
      本次租号到期时间<span>（永久版用户可无限延时）</span>
    </div>
    <div :class="$style.content2">{{ endTime || "-" }}</div>
    <div :class="$style.content3">1、请在到期前操作延长时间</div>
    <div :class="$style.content3">
      2、租到号后记得设置 <span>“防顶号”</span>
    </div>
    <div :class="$style.content3">
      3、玩游戏的时候不要开启 <span>“云存档”</span>
    </div>
  </a-modal>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import successIcon from "@assets/successIcon.png";

const props = defineProps({
  isOpen: Boolean,
  setIsOpen: Function,
  endTime: String,
});

const router = useRouter();
const amount = ref();

const onClickBtn = () => {
  props?.setIsOpen?.(false);
  router.push("/rendResult");
};
</script>
<style module>
.modal {
  width: 441px;
  margin: 0 auto;
}
.modalBtn {
  display: block;
  font-size: 17px;
  width: 309px;
  height: 40px;
  border-radius: 40px;
  margin: 40px auto 0;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.headerImg {
  width: 58px;
  height: 58px;
  margin-right: 39px;
}
.headerText {
  font-size: 30px;
  font-weight: 400;
  color: #06a325;
}
.topTip {
  font-size: 15px;
  font-weight: 400;
  color: #383838;
  text-align: center;
  height: 41px;
  width: 236px;
  line-height: 41px;
  border-radius: 41px;
  background-color: #f8f8f8;
  margin: 0 auto 23px;
}
.content1 {
  font-size: 17px;
  font-weight: 400;
  color: #242424;
  line-height: 25px;
  text-align: center;
  margin-bottom: 25px;
}
.content1 span {
  color: #ff2121;
}
.content2 {
  font-size: 24px;
  font-weight: 400;
  color: #ff870f;
  line-height: 35px;
  text-align: center;
  margin-bottom: 41px;
}
.content3 {
  font-size: 17px;
  font-weight: 400;
  color: #242424;
  line-height: 25px;
  margin-bottom: 10px;
}
.content3 span {
  color: #ff870f;
}
</style>
<style>
.rental-success .ant-modal-content {
  padding: 45px 50px 52px !important;
}
</style>