<template>
  <div>
    <a-modal
      :open="isGobalMessageModalVisible"
      :title="modalTitle"
      @ok="handleOk"
      @cancel="handleCancel"
      cancel-text="取消"
      ok-text="我知道了"
      :maskClosable="false"
      :confirmLoading="isLoading"
      :destroyOnClose="false"
    >
      <template #title>
        <!-- <div :class="modalTitleClass">{{ modalTitle }}</div> -->
        <div :class="$style.modal-title-info">{{ modalTitle }}</div>
      </template>
      <div v-html="msg"></div>
    </a-modal>
  </div>
</template>

<script setup>
// 此处不能写任何请求！！！！！！！！，否则有概率循环调用！！
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

// Vuex Store
const store = useStore();
const gobalMessageModal = computed(() => store.state.gobalMessageModal);
const isGobalMessageModalVisible = computed(() => gobalMessageModal.value.isGobalMessageModalVisible);
const payload = computed(() => gobalMessageModal.value.payload);

const alterType = computed(() => payload.value.alterType);
const msg = computed(() => payload.value.msg);

// 状态管理
const isLoading = ref(false);
const modalTitle = computed(() => payload.value.title);

const modalTitleClass = computed(() => {
  switch (payload.value.level) {
    case "success":
      return "modal-title-success";
    case "warning":
      return "modal-title-warning";
    case "error":
      return "modal-title-error";
    default:
      return "modal-title-info";
  }
});


// 处理确认按钮点击事件
const handleOk = () => {
  store.dispatch("gobalMessageModal/hideModal");
};

// 处理取消按钮点击事件
const handleCancel = () => {
  store.dispatch("gobalMessageModal/hideModal");
};

</script>

<style scoped>
/* 定义不同级别的标题样式 */
.modal-title-info {
  color: #1890ff; /* 蓝色 */
  font-size: 17px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 27px;
}

.modal-title-success {
  color: #52c41a; /* 绿色 */
  font-size: 17px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 27px;
}

.modal-title-warning {
  color: #faad14; /* 黄色 */
  font-size: 17px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 27px;
}

.modal-title-error {
  color: #f5222d; /* 红色 */
  font-size: 17px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 27px;
}
</style>
