<template>
  <div :class="$style.wrapOut">
    <!-- <h1>{{ newsContent.title }}</h1> -->
    <div class="content" v-if="newsContent">
        <div v-html="newsContent.introduction|| ''"></div>
    </div>


  </div>

</template>
  
<script setup>
  import { ref, onMounted, watch } from "vue";
  import axios from "@/axios";
  import { message } from "ant-design-vue";
  import { useRoute, useRouter } from 'vue-router';
  import { setNewsSeoInfo } from '@/utils/seo'; // 导入SEO辅助函数

  const route = useRoute();
  const router = useRouter();
  
  
  const newsContent = ref("");
  
  // 请求新闻详情
  const fetchNewsDetail = async (pk) => {
    try {
      const res = await axios.post("/web/news/", { pk });
      if (res?.data?.status === 10000) {
        newsContent.value = res?.data?.data;
        
        // 设置SEO信息
        setNewsSeoInfo({
          title: newsContent.value.title,
          summary: newsContent.value.introduction?.substring(0, 100).replace(/<[^>]*>/g, ''), // 去除HTML标签
          tags: newsContent.value.tags || '游戏资讯,游戏新闻'
        });
      } else {
        message.error(res?.data?.msg || "获取新闻失败");
      }
    } catch (error) {
      console.error(error);
      message.error("获取新闻失败");
    }
  };
  
  onMounted(() => {
    if (route.params.id) {
      fetchNewsDetail(route.params.id);
    }
  });
  
  watch(() => route.params.id, (newId) => {
    if (newId) {
      fetchNewsDetail(newId);
    }
  });
</script>

<style module lang="scss">
.wrapOut {
  padding: 20px;
  margin: 20px auto;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

:global(.content) {
  line-height: 1.6;
  font-size: 16px;
  
  h1, h2, h3, h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
}
</style>
  