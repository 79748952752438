<template>
  <a-modal
    v-model:open="props.open"
    :title="null"
    :footer="null"
    :maskClosable="false"
    :width="'90%'"
    :style="{ maxWidth: '420px' }"
    class="upgrade-modal"
    :destroyOnClose="true"
    @cancel="handleCancel"
  >
    <!-- 头部区域 -->
    <div :class="$style.header">
      <div :class="$style.titleGroup">
        <div :class="$style.iconContainer">
          <img 
            :src="UpYongjiuIcon01" 
            :class="$style.rocketIcon"
            alt="升级图标"
          />
        </div>
        <h1 :class="$style.title">升级永久版</h1>
      </div>
    </div>

    <!-- 提示条 -->
    <div :class="$style.tipBar">
      可永久无限次租用此游戏，无后续费
    </div>

    <!-- 游戏封面 -->
    <div :class="$style.coverContainer">
      <img 
        :src="gameData.cover" 
        :class="$style.coverImage" 
        alt="游戏封面"
      />
    </div>

    <!-- 价格信息 -->
    <div :class="$style.priceSection">
      <div :class="$style.couponRow">
        <div :class="$style.iconContainer">
          <img 
            :src="UpYongjiuIcon02" 
            :class="$style.couponIcon"
            alt="优惠券"
          />
        </div>
        <span>首单优惠券</span>
        <span :class="$style.discount">-{{ gameData.discount }}元</span>
        <span :class="$style.originalPrice">原价：<del>￥{{ gameData.originalPrice }}</del></span>
      </div>
    </div>

    <!-- 支付按钮 -->
    <button 
      :class="$style.payButton"
      @click="handlePayment"
    >
      支付{{ gameData.payPrice }}元，直接升级永久版
    </button>

    <!-- CDK输入区域 -->
    <div :class="$style.cdkSection">
      <a-input
        placeholder="输入延长时间CDK"
        :class="$style.cdkInput"
        v-model:value="cdkey"
      />
      <button :class="$style.cdkButton" @click="onUseOld">
        原有账号续期
      </button>
    </div>

    <!-- 备注信息 -->
    <div :class="$style.note">
      <!-- <ExclamationCircleFilled :class="$style.warningIcon" /> -->
      <span>注：勿去商家店铺退款CDK，会导致账号自动锁定</span>
    </div>
  </a-modal>

  <PaymentModal
    :isOpen="isOpen"
    :isOrderOpen="isOrderOpen"
    :curSku="curSku"
    :paymentType="paymentType"
    :orderInfo="orderInfo"
    @setIsOpen="backClosePayment"
    @setIsOrderOpen="(value) => (isOrderOpen = value)"
    @paymentSuccess="handlePaymentSuccess"
  />


</template>

<script setup>
import { ExclamationCircleFilled } from '@ant-design/icons-vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import UpYongjiuIcon01 from '@/assets/UpYongjiuIcon01.png'
import UpYongjiuIcon02 from '@/assets/UpYongjiuIcon02.png'
import { getFullImageUrl, openLink } from "@/util/utils.js";
import axios from "@/axios"
import { message } from 'ant-design-vue'


const router = useRouter()


import PaymentModal from "@/components/BuyPlanModalv3.vue";
const isOpen = ref(false);
const isOrderOpen = ref(false);
const paymentType = ref(0);
const curSku = ref(null);
const skuData = ref([]);
const orderInfo = ref(null);
const tmp = ref(null);

const backClosePayment = (value) => {
    tmp.value = Date.now();
    isOpen.value = value;
    if (!isOpen) {
      handlePayment()
    }
}



const props = defineProps({
  recordId: {
    type: String,
    required: true
  },
  open: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['update:open', 'close', 'refresh'])
const cdkey = ref('')
const gameData = ref({
  cover: '',
  discount: 0,
  originalPrice: 0,
  payPrice: 0,
  gameId: 0
})

const fetchData = async (id) => {
  try {
    const res = await axios.post("web/rentno/get_up_yongjiu_price_info/", { uid: id })
    return {
      cover: getFullImageUrl(res?.data?.data?.cover || ''),
      discount: res?.data?.data?.discount || 0,
      originalPrice: res?.data?.data?.originalPrice || 0,
      payPrice: res?.data?.data?.payPrice || 0,
      gameId: res?.data?.data?.gameId || 0
    }
  } catch (error) {
    message.error('获取数据失败')
    return {}
  }
}

watch(
  [() => props.open, () => props.recordId],
  async ([isOpen, recordId]) => {
    if (isOpen && recordId) {
      gameData.value = await fetchData(recordId)
    }
  },
  { immediate: true }
)

const handlePayment = async() => {
  const res = await axios.post("web/skus/", { game_id: gameData.value.gameId}).catch(() => {});
  if (res?.data?.status === 10000) {
    const skuData = (res?.data?.data?.items || []).slice(0, 4);
    curSku.value = skuData[0];
    // 强制塞入
    curSku.value.rentId = props.recordId;
  }
  isOrderOpen.value = true;
  isOpen.value = false;
}

const onUseOld = async () => {
  if (!cdkey.value.trim()) {
    message.error('请输入CDK')
    return
  }
  
  try {
    const res = await axios.post("web/all_cdk_use_old/", { cdkey: cdkey.value })
    if (res?.data?.status === 10000) {
      const routeMap = {
        1: "/rendResult",
        2: "/myGame",
        3: "/exclusive",
        4: "/gameStore"
      }
      router.push(routeMap[res.data.data.morg] || "/rendResult")
      emit('refresh')
      emit('update:open', false)
    }
  } catch (error) {
    message.error('CDK使用失败')
  }
}

const handleCancel = () => {
  emit('update:open', false)
  emit('close')
}

const handlePaymentSuccess = () => {
  // Close payment modal
  isOpen.value = false;
  isOrderOpen.value = false;
  // Pass the event up to the parent component
  emit('refresh')
  emit('update:open', false)
}
</script>


<style module>
.header {
  position: relative;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 16px;
}

.titleGroup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  left: -12px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin-right: 10px;
}

.rocketIcon {
  max-width: 40px;
  max-height: 52px;
  width: auto;
  height: auto;
  object-fit: contain;
  margin-right: 8px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  color: #ff2121;
  position: relative;
  top: 10px;
}

.tipBar {
  width: 377px;
  height: 52px;
  background: #f5f5f5;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  font-size: 18px;
  color: #666;
}

.coverContainer {
  width: 378px;
  height: 177px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
}

.coverImage {
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.priceSection {
  width: 377px;
  margin: 0 auto 20px;
}

.couponRow {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 20px;
}

.couponIcon {
  max-width: 34px;
  max-height: 40px;
  width: auto;
  height: auto;
  object-fit: contain;
  margin-right: 8px;
}

.discount {
  color: rgba(255, 135, 15, 1);
  margin-left: 20px;
}

.originalPrice {
  color: #999;
  /* text-align: right; */
  margin-left: auto;
}

.payButton {
  width: 377px;
  height: 63px;
  background: #1677ff;
  border-radius: 3px;
  color: white;
  font-size: 18px;
  border: none;
  margin: 0 auto 20px;
  display: block;
  cursor: pointer;
  transition: opacity 0.3s;
}

.payButton:hover {
  opacity: 0.9;
}

.cdkSection {
  position: relative;
  width: 377px;
  margin: 0 auto 20px;
}

.cdkInput {
  width: 100%;
  height: 51px;
  border-radius: 3px;
  padding-right: 130px;
  box-shadow:inset 0px 0px 6px  rgba(0, 0, 0, 0.16);
  /* box-shadow: 10px 10px 8px rgba(25, 144, 255, 0.1); */
}

.cdkButton {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 124px;
  height: 36px;
  /* background: #f5f5f5; */
  background: #1677ff;
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 17px;
  transition: background 0.3s;
}

.cdkButton:hover {
  background: #e8e8e8;
}

.note {
  color: rgba(255, 135, 15, 1);
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;
  margin-bottom: 20px;
}

.warningIcon {
  margin-right: 8px;
  font-size: 14px;
  position: relative;
  top: -0.5px;
}
</style>