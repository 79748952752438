<!-- 
  <RentAccountDialog
    :isOpen="isRentAccountDialog"
    :pkId="rentPkId"
    :rentType="'permanent'"
    @setIsOpen="setIsRentAccountDialog"
    @successCallBack="onRentAccountSuccess"
  />

  // 租号业务
  import RentAccountDialog from '@components/SelectAccountModal.vue';
  const rentAccountPkId = ref();
  const isRentAccountDialog = ref(false);

  const setIsRentAccountDialog = (flag) => {
    isRentAccountDialog.value = flag;
  }

  const onRentNumber = (yongjiuId) => {
    console.log(yongjiuId)
    rentAccountPkId.value = yongjiuId;
    isRentAccountDialog.value = true;
  }

  const onRentAccountSuccess = () => {
    // 租号成功，弹框提示弹窗
    router.push('/rendResult');
  }

 -->

<template>
  <a-modal
    :open="isOpen"
    @cancel="onCancel"
    :maskClosable="false"
    :destroyOnClose="true"
    width="100%"
    :wrapClassName="[$style.modal, 'account-modal']"
  >
    <template #title>
      <div :class="$style.title">
        {{ $t("permanentCard.selectAccountTitle") }}
      </div>
    </template>
    <template #footer>
      <div :class="$style.modalBtn">
        <a-button
          :class="$style.modalBtnTRL"
          type="primary"
          @click="confirmRent"
          >{{ $t("permanentCard.confirm") }}</a-button
        >
        <a-button :class="$style.modalBtnTRR" @click="onCancel">{{
          $t("permanentCard.cancel")
        }}</a-button>
      </div>
    </template>
    <div :class="$style.header">
      <div :class="$style.headerText">当前游戏：{{ curGame || "-" }}</div>
    </div>
    <div :class="$style.content">
      <a-select
        v-model:value="selectedAccount"
        :placeholder="$t('permanentCard.selectAccountPlaceholder')"
        :options="accountOptions"
        @select="handleSelectGame"
      />
    </div>
    <div :class="$style.footer">
      {{ $t("permanentCard.violationWarning") }}
    </div>
  </a-modal>
  <!-- <el-dialog :model-value="isOpen" @close="onCancel" :title="$t('permanentCard.selectAccountTitle')" width="30%">
    <p :class="$style.title">
      &nbsp;{{ $t('permanentCard.violationWarning') }}
    </p>
    <el-select v-model="selectedAccount" :placeholder="$t('permanentCard.selectAccountPlaceholder')" style="width: 80%">
      <el-option
        v-for="account in accountOptions"
        :key="account.id"
        :label="account.name"
        :value="account.id"
        :disabled="!account.is_kongxian"
      />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="onCancel">{{ $t('permanentCard.cancel') }}</el-button>
        <el-button type="primary" @click="confirmRent">{{ $t('permanentCard.confirm') }}</el-button>
      </span>
    </template>
  </el-dialog> -->
</template>

<script setup>
import { ref, watch } from "vue";
import axios from "@/axios";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const router = useRouter();
const { t } = useI18n();

const props = defineProps({
  isOpen: Boolean,
  pkId: Number,
  defaultValue: String, // 默认值
  curGame: String, // 当前游戏
  rentType: String, // 租赁类型：vip、permanent、switch
});
const emit = defineEmits(["setIsOpen", "successCallBack"]);
const selectedAccount = ref(undefined);
const accountOptions = ref([]);

// 根据租赁类型获取接口路径
const getApiPath = (apiType) => {
  const apiMap = {
    vip: {
      list: "/web/rentno/vip_rento/",
      select: "/web/rentno/vip_chioce_account/",
    },
    permanent: {
      list: "/web/rentno/zhhao_account_rental_log/",
      select: "/web/rentno/chioce_account/",
    },
    switch: {
      list: "/web/rentno/qiehuan_list/",
      select: "/web/rentno/qiehuan/",
    },
  };
  return apiMap[props.rentType]?.[apiType];
};

// 获取账号列表
watch(
  [() => props?.pkId, () => props?.isOpen, () => props?.rentType],
  async () => {
    if (!props?.pkId || !props?.isOpen || !props?.rentType) return;
    const apiPath = getApiPath("list");
    if (!apiPath) {
      console.error(`Invalid rentType: ${props.rentType}`);
      return;
    }
    const res = await axios.post(apiPath, { uid: props?.pkId }).catch(() => {});
    if (res?.data?.status === 10000) {
      accountOptions.value =
        res?.data?.data?.map?.((item) => ({
          value: item?.id,
          label: item?.name,
          disabled: !item.is_kongxian,
        })) || [];
      
      // 默认选中第一个 disabled === false 的选项
      const firstEnabledOption = accountOptions.value.find(option => !option.disabled);
      if (firstEnabledOption) {
        selectedAccount.value = firstEnabledOption.value;
      }
    } else {
      accountOptions.value = [];
      onCancel();
    }
  }
);

// 设置默认值
watch([() => props?.defaultValue, () => props?.isOpen], async () => {
  if (!props?.defaultValue || !props?.isOpen) return;
  selectedAccount.value = props?.defaultValue;
});

// 点击确认
const confirmRent = async () => {
  if (!selectedAccount.value) {
    message.warning(t("permanentCard.selectAccountWarning"));
    return;
  }
  const apiPath = getApiPath("select");
  if (!apiPath) {
    console.error(`Invalid rentType: ${props.rentType}`);
    return;
  }
  const response = await axios.post(apiPath, {
    uid: props?.pkId,
    account: selectedAccount.value,
  });
  if (response.data.status === 10000) {
    emit("setIsOpen", false);
    emit("successCallBack", response.data?.data?.endtime);
  }
};

const onCancel = () => {
  selectedAccount.value = undefined;
  emit("setIsOpen", false);
};
</script>

<style module>
.modal {
  width: 589px;
  margin: 0 auto;
  font-size: 18px;
  color: #242424;
}

.title {
  line-height: 26px;
  font-size: 18px;
  color: #242424;
  font-weight: 400;
}

.header {
  font-size: 18px;
  margin-bottom: 40px;
}

.footer {
  line-height: 24px;
  font-size: 16px;
  color: #ff2121;
}

.modalBtn {
  display: flex;
  justify-content: flex-end;
}

.modalBtnTRR {
  width: 80px;
  height: 40px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-inline-start: 0px !important;
}

.modalBtnTRL {
  width: 80px;
  height: 40px;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-inline-start: 0px !important;
  margin-right: 28px;
}
</style>

<style>
.account-modal .ant-modal-header {
  margin-bottom: 39px !important;
}

.account-modal .ant-modal-footer {
  margin-top: 38px !important;
}

.account-modal .ant-select {
  width: 413px !important;
  margin-bottom: 20px !important;
}

.account-modal .ant-select-selector {
  font-size: 17px !important;
  border-radius: 40px !important;
  height: 40px !important;
  font-size: 18px !important;
}

.account-modal .ant-select-selection-search-input {
  height: 40px !important;
}

.account-modal .ant-select-selection-placeholder {
  line-height: 40px !important;
}

.account-modal .ant-select-selection-item {
  line-height: 40px !important;
}

.account-modal .ant-input-search-button {
  height: 50px !important;
  width: 40px !important;
  font-size: 20px !important;
}
</style>
