<template>
  <div>
    <a-modal
      :open="isOpen"
      title="cdk兑换提醒"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <template #footer>
        <div :class="$style.modalBtnW">
          <a-button @click="onClickNew" :loading="isNewLoading"
            >兑换新账号</a-button
          >
          <a-button type="primary" @click="onClickOld" :loading="isOldLoading"
            >老账号加时长</a-button
          >
        </div>
      </template>
      <div :class="$style.content">
        <div :class="$style.title">您已兑换过此游戏:</div>
        <div :class="$style.text">
          1、可以点击下方按钮“兑换新账号”或者“加时长”
        </div>
        <div :class="$style.text">
          2、如有多个账号需要加时间，请前往“<a
            href="#"
            :class="$style.describeA"
            @click="onToRendResult"
            >租用中账号</a
          >”，点击加时长
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  isOpen: Boolean,
  setIsOpen: Function,
  onClickNewBack: Function,
  onClickOldBack: Function,
});

const isNewLoading = ref(false);
const isOldLoading = ref(false);

const onClickNew = async () => {
  isNewLoading.value = true;
  await props?.onClickNewBack()?.catch?.(() => {});
  isNewLoading.value = false;
};

const onClickOld = async () => {
  isOldLoading.value = true;
  await props?.onClickOldBack()?.catch?.(() => {});
  isOldLoading.value = false;
};

const onToRendResult = () => {
  window.open("/rendResult", "_blank");
};

const handleCancel = async () => {
  props?.setIsOpen?.(false);
};
</script>
<style module>
.modalBtnW {
  margin-left: auto;
}
.content {
  padding: 20px 20px;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
.text {
  margin-top: 15px;
}
.describeA {
  color: #237aff;
  text-decoration: none;
}
</style>