<template>
  <div>
    <a-modal
      :open="isOpen"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.modal"
    >
      <template #title>
        <div :class="$style.title">温馨提示</div>
      </template>
      <template #footer>
        <div :class="$style.modalBtn">
          <div :class="$style.modalBtnT">
            <a-button type="default" :class="$style.modalBtnTL" @click="onLogin" :disabled="true"
              >暂不支持直接兑换</a-button
            >
            <div :class="$style.modalBtnTR">
              <a-button
                :class="$style.modalBtnTRL"
                type="primary"
                @click="onLogin"
                >登陆后兑换</a-button
              >
              <a-button
                :class="$style.modalBtnTRR"
                type="primary"
                @click="onRegister"
                >注册</a-button
              >
            </div>
          </div>
          <div :class="$style.modalBtnB">
            <a-button type="default" :class="$style.modalBtnBB" @click="onLogin"
              >新用户首次注册即 送{{ amount || "-" }}元现金红包!</a-button
            >
          </div>
        </div>
      </template>
      <div :class="$style.header">
        <img :class="$style.headerImg" :src="userwIcon" />
        <div :class="$style.headerText">您还未登录</div>
      </div>
      <div :class="$style.content">
        <div :class="$style.contentItem">
          <img :class="$style.contentItemImg" :src="ytipIcon" />
          <div :class="$style.contentItemText">
            根据国家法律法归要求，防范未成年人沉迷游戏，需要注册后才能使用游戏产品。
          </div>
        </div>
        <div :class="$style.contentItem">
          <img :class="$style.contentItemImg" :src="ytipIcon" />
          <div :class="$style.contentItemText">
            Steam防顶号原理：自助收Steam验证码，通过验证码登录，防止玩的过程被顶号。
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import axios from "@/axios";

import userwIcon from "@assets/userwIcon.png";
import ytipIcon from "@assets/ytipIcon.png";

const store = useStore();
const isOpen = computed(
  () => store?.state?.authModal?.isBeforeLoginVisible || false
);
const amount = ref();

// 获取礼包金额
onMounted(async () => {
  const res = await axios.post("web/settings_rule/", {}).catch(() => { });
  if (res?.data?.status === 10000) {
    console.log(res?.data, 9090909);
    amount.value = res?.data?.data?.user_reg_dkj;
  }
});

const onLogin = () => {
  console.log("login");
  store.commit('SET_LOGINMODALTYPE', "login");
  store.dispatch("authModal/showLoginModal");
};

const onRegister = () => {
  store.commit("SET_LOGINMODALTYPE", "register");
  store.dispatch("authModal/hideBeforeModal");
  store.dispatch("authModal/showLoginModal");
};

const handleCancel = async () => {
  store.dispatch("authModal/hideBeforeModal");
};
</script>
<style module>
.modal {
  width: 695px;
  margin: 0 auto;
}

.title {
  font-size: 17px;
  color: #242424;
  font-weight: 400;
  margin-bottom: 27px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.headerImg {
  width: 60px;
  height: 60px;
  margin-right: 27px;
}

.headerText {
  font-size: 23px;
  color: #818181;
}

.content {
  padding-bottom: 15px;
}

.contentItem {
  display: flex;
  margin-bottom: 29px;
}

.contentItemImg {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.contentItemText {
  font-size: 16px;
  line-height: 24px;
  color: #2b2b2b;
}

.modalBtnT {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 31px;
}

.modalBtnTL {
  font-size: 14px;
  width: 159px;
  height: 39px;
  color: #9a9a9a;
  background-color: rgba(235, 235, 235, 0.238);
  border-radius: 39px;
}

.modalBtnTL:hover {
  background-color: rgba(235, 235, 235, 0.1) !important;
}

.modalBtnTR {
  display: flex;
}

.modalBtnTRL {
  width: 121px;
  height: 39px;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(35, 122, 255, 1);
  border-radius: 39px;
  margin-right: 17px;
}

.modalBtnTRL:hover {
  background-color: rgba(35, 122, 255, 0.7) !important;
}

.modalBtnTRR {
  width: 79px;
  height: 39px;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 135, 15, 1);
  border-radius: 39px;
  margin-inline-start: 0px !important;
}

.modalBtnTRR:hover {
  background-color: rgba(255, 135, 15, 0.7) !important;
}

.modalBtnBB {
  width: 100%;
  height: 65px;
  font-size: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#f5b811, #fa870f);
  border-radius: 10px;
}

.modalBtnBB:hover {
  border: none !important;
  color: #ffffff !important;
  background-color: rgba(255, 135, 15, 0.7) !important;
}
</style>