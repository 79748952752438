<template>
  <a-modal
    :open="isOpen"
    :width="652"
    :footer="null"
    :mask-closable="false"
    :destroy-on-close="true"
    :wrap-class-name="$style.modalWrap"
    @cancel="handleCancel"
  >
  
    <!-- 头部区域 -->
    <div :class="$style.modalHeader">
      <img 
        :src="signSuccessIcon" 
        :class="$style.headerIcon"
        alt="签到成功"
      />
      <div :class="$style.headerContent">
        <div :class="$style.rewardText">
          签到成功 +{{ formatAmount(rewardAmount) }}元
        </div>
        <div :class="$style.discountText">
          您还有{{ formatAmount(totalDiscount) }}元可全额抵扣全站游戏！
        </div>
      </div>
    </div>

    <!-- 游戏列表 -->
    <div :class="$style.gameGrid">
      <div :class="$style.cardGrid" v-for="(cur, index) in visibleGames" @click="$onClickGame(cur)">
          <img
            :src="getFullImageUrl(cur?.img)"
            :class="$style.cardGridImg"
            alt=""
          />
          <div :class="$style.cardGridCon">
            <div :class="$style.cardGridTitle">{{ cur?.gname ? cur.gname.slice(0, 9) + '.' : '' }}</div>
            <!-- <div :class="$style.cardGridTitleE">{{cur?.ename}}</div> -->
            <div :class="$style.cardGridMoney" v-if="cur.provider === 'zuhaowan'"> ¥{{cur?.sku_price_info?.show_price_info?.use_dkj_price.toFixed(2) || '-'}}<span :class="$style.gameResItemInPricePDO"> /时起 </span></div>
            <div :class="$style.cardGridMoney" v-else>¥{{cur?.sku_price_info?.show_price_info?.use_dkj_price.toFixed(2) || '-'}}</div>
          </div>
        </div>

    </div>

    <!-- 底部操作 -->
    <div :class="$style.modalFooter">
      <a-button
        :class="$style.refreshBtn"
        :loading="loading"
        @click="handleRefresh"
      >
        换一批
      </a-button>
      <a-button 
        type="primary" 
        :class="$style.confirmBtn"
        @click="handleUseNow"
      >
        立即使用
      </a-button>
    </div>
  </a-modal>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/axios'
import { getFullImageUrl } from '@/util/utils'
import signSuccessIcon from '@assets/signSuccessIcon.png'

const emit = defineEmits(['refresh', 'close'])

const store = useStore()
const router = useRouter()
const games = ref([])
const currentPage = ref(1)
const loading = ref(false)

// 计算属性
const isOpen = computed(() => store.state.signModal.isModalVisible)
const rewardAmount = computed(() => store.state.signModal.rewardAmount)
const totalDiscount = computed(() => store.state.signModal.totalDiscount)
const totalPages = computed(() => Math.ceil(games.value.length / 8))
const visibleGames = computed(() => {
  const start = (currentPage.value - 1) * 8
  const end = start + 8
  return games.value.slice(start, end)
})

// 生命周期
onMounted(async () => {
  await fetchGames()
})

const fetchedGameIds = ref([])

// 修改后的数据获取方法
const fetchGames = async () => {
  try {
    loading.value = true
    const res = await axios.post('web/games-recommend/', { 
      method: 'sign_success', 
      limit: 8,
      exclude_ids: fetchedGameIds.value // 新增排除参数
    })
    
    if (res?.data?.status === 10000) {
      const newGames = res.data.data || []
      
      // 记录新获取的游戏ID
      newGames.forEach(game => {
        if (game?.id && !fetchedGameIds.value.includes(game.id)) {
          fetchedGameIds.value.push(game.id)
        }
      })
      
      // 直接替换当前显示的游戏列表
      games.value = newGames
    }
  } catch (e) {
    console.error('游戏数据加载失败:', e)
  } finally {
    loading.value = false
  }
}

// 格式处理
const formatAmount = (value) => Number(value || 0).toFixed(2)
const formatPrice = (value) => Number(value || 0).toFixed(2)

// 事件处理
const handleGameClick = (game) => {
  router.push(`/game-detail/${game.id}`)
}

// 修改后的刷新方法
const handleRefresh = () => {
  fetchGames()
}

const handleUseNow = () => {
  store.commit("signModal/close_model")
  router.push('/gameStore')
}

const handleCancel = () => {
  store.commit("signModal/close_model")
  emit('close')
}
</script>

<style module>

.modalWrap :global(.ant-modal-content) {
  padding: 0 !important;
}

/* .modalWrap .ant-modal-content {
  padding: 0 !important;
} */

.modalWrap .ant-modal-body {
  padding: 0 !important;
}

.modalHeader {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  padding: 0 24px;
  width: 100%; /* 确保容器宽度 */
}

/* 调整图标样式 */
.headerIcon {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  margin-right: 1px; /* 替换之前的margin-left */
}

/* 文字容器调整 */
.headerContent {
  display: flex;
  flex-direction: column;
  align-items: left; /* 文字内容水平居中 */
  text-align: left; /* 保证文字内容居中 */
}

/* 文字样式微调 */
.rewardText {
  font-size: 26px;
  line-height: 1.2;
  color: #FF870F;
  margin-bottom: 8px;
  /* text-align: left; */
}

.discountText {
  font-size: 15px;
  color: #666;
  /* text-align: left; */
}

.gameGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;
  padding: 0px 24px 15px;
  /* width: 100%; */
}

/* @media (max-width: 640px) {
  .gameGrid {
    grid-template-columns: repeat(2, 1fr);
  }
} */


.cardGrid {
    /* width: 210px;
    height: 97px; */
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
  }
  .cardGridImg {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .cardGridCon {
    position: absolute;
    left: 12px;
    bottom: 8px;
    width: calc(100% - 24px);
  }
  .cardGridTitle {
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
  }
  .cardGridMoney {
    font-size: 13px;
    line-height: 13px;
    color: #FFE202;
    font-weight: bold;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
  }
.cardContent {
  padding: 12px;
}

.cardContent .gameTitle {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardContent .priceBox {
  margin-top: 8px;
  line-height: 1;
}

.cardContent .price {
  font-size: 16px;
  color: #FF6600;
  font-weight: bold;
}

.cardContent .priceUnit {
  font-size: 12px;
  color: #999;
  margin-left: 2px;
}

.modalFooter {
  display: flex;
  justify-content: center; /* 修改为居中对齐 */
  gap: 68px; /* 添加按钮间距 */
  padding: 22px 24px 24px;
}


/* 可选：如果希望按钮在小屏上换行 */
@media (max-width: 480px) {
  .modalFooter {
    flex-wrap: wrap;
  }
  .refreshBtn,
  .confirmBtn {
    width: 100%;
  }
}

.refreshBtn {
  width: 160px;
  height: 40px;
  /* border-radius: 20px;
  border: 1px solid #D9D9D9;
  color: #666; */

  /* width: 109px;
  height: 39px; */
  opacity: 0.24;
  border-radius: 23px;
  background: rgb(122, 122, 122);

  border: 0.5px solid rgba(112, 112, 112, 1);

}

.refreshBtn:hover {
  border-color: #BFBFBF;
}

.confirmBtn {
  width: 160px;
  height: 40px;
  border-radius: 20px;
  background: linear-gradient(135deg, #FF8800, #FF6600);
  border: none;
  font-weight: 500;
  transition: opacity 0.3s;
}

.confirmBtn:hover {
  opacity: 0.9;
}
</style>