<template>
  <div :class="$style.header">
    <div :class="$style.left">
      <div :class="$style.leftL" @click="$openInNewTab('https://www.zuhaoguanjia.com/myMember')">会员到期时间: {{ userinfo?.level_exp || "-" }}</div>
    </div>
    <div :class="$style.right">
      <template v-if="isAuthenticated">
        <div :class="$style.itemR" @click="$openInNewTab('https://www.zuhaoguanjia.com/news/54.html')">加入赚钱</div>
        <div :class="$style.itemR" @click="$openInNewTab('https://www.zuhaoguanjia.com/rentalRecords')">租用记录</div>
        <div :class="$style.itemR" @click="$openInNewTab('https://www.zuhaoguanjia.com/myCollect')">我的收藏</div>
        <div :class="$style.itemR" @click="$openInNewTab('https://www.zuhaoguanjia.com/exclusive')">独享账号</div>
        <div :class="$style.divder"></div>
        <div
          :class="[$style.itemR, $style.money]"
          @click="$openInNewTab('https://www.zuhaoguanjia.com/walletDetail')"
        >钱包：{{ userinfo?.amount_sum || "-" }}元</div>
        <div :class="$style.divder"></div>
        <a-dropdown :trigger="['click']" :class="$style.userInfo">
          <a @click.prevent :class="[$style.itemR, $style.user]">
            {{ userinfo?.username || "-" }}
            <DownOutlined :class="$style.downIcon" />
          </a>
          <template #overlay>
            <a-menu>
              <!-- 购买cdk 仅当 userinfo?.is_allow_gen_cdk 为 true 时显示 -->
              <a-menu-item key="1" v-if="userinfo?.gen_cdk" @click="$openInNewTab('https://www.zuhaoguanjia.com/buyCdk')">
                <a>购买cdk</a>
              </a-menu-item>
              <a-menu-item key="2" @click="$openInNewTab('https://www.zuhaoguanjia.com/recordCdk')">
                <a>cdk兑换记录</a>
              </a-menu-item>
              <a-menu-item key="3" @click="$openInNewTab('https://www.zuhaoguanjia.com/rentalRecords')">
                <a>租号记录</a>
              </a-menu-item>
              <a-menu-item key="4" @click="$openInNewTab('https://www.zuhaoguanjia.com/myOrder')">
                <a>我的订单</a>
              </a-menu-item>
              <a-menu-item key="5" @click="$openInNewTab('https://www.zuhaoguanjia.com/change-Phone')">
                <a>修改手机号</a>
              </a-menu-item>
              <a-menu-item key="6" @click="handleLogout">
                <a>{{ $t('header.logout') }}</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <div :class="$style.divder"></div>
      </template>
      <template v-else>
        <a @click="navigateToLogin" :class="$style.itemR">{{ $t('header.login') }}</a>
        <a @click="navigateToRegister" :class="$style.itemR">{{ $t('header.register') }}</a>
        <div :class="$style.divder"></div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { DownOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import headerD from "@assets/headerD.png";

const store = useStore();
const router = useRouter();
const { locale } = useI18n();
const currentLanguage = ref(locale.value);

const userinfo = computed(() => store.state.user.user);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);

// 切换语言
const toggleLanguage = () => {
  const newLang = currentLanguage.value === 'en' ? 'zh' : 'en';
  currentLanguage.value = newLang;
  locale.value = newLang;
  localStorage.setItem('preferred_lang', newLang);
  window.location.reload();
};

// 处理登出
const handleLogout = async () => {
  try {
    await store.dispatch('logout');
    $openInNewTab('https://www.zuhaoguanjia.com/gameStore');
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

// 导航到登录页面
const navigateToLogin = () => {
  store.commit('SET_LOGINMODALTYPE', "login");
  store.dispatch("authModal/showLoginModal");
};

// 导航到注册页面
const navigateToRegister = () => {
  store.commit('SET_LOGINMODALTYPE', "register");
  store.dispatch("authModal/showLoginModal");
};
</script>

<style module>
  .header {
    width: 100%;
    height: 35px;
    background-color: #3149ff;
    padding: 0 230px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .leftL {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    padding-right: 13px;
  }
  .leftC {
    width: 20px;
    margin-right: 9px;
    cursor: pointer;
    margin-right: 28px;
  }
  .right {
    display: flex;
    align-items: center;
  }
  .divder {
    height: 20px;
    width: 0;
    border-right: 1px solid #fff;
  }
  .itemR {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin-right: 28px;
    font-size: 14px;
    color: #ffffff;
  }
  .itemRI {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin-right: 28px;
    font-size: 14px;
    color: #f6d606;
  }
  .money {
    margin-left: 28px;
  }
  .user {
    margin-left: 28px;
  }
  .downIcon {
    margin-left: 10px;
  }
  .languageSwitcher {
    margin-left: 28px;
    cursor: pointer;
  }
  .userInfo {
    margin-right: 28px;
  }
  .gameResItemInPricePDO {
    font-size: 12px;
  }
</style>
