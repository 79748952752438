import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'amfe-flexible/index.js';
import i18nMixin from '@/lang/i18nMixin'; 
import i18n from '@/lang'


const app = createApp(App);
app.config.globalProperties.$openInNewTab = function (url) {
    // 检查 URL 是否以 http 或 https 开头
    if (url.startsWith('http://') || url.startsWith('https://')) {
        // 如果是完整的 URL，直接打开
        window.open(url, '_blank');
    } else {
        // 如果不是完整的 URL，使用 Vue Router 解析 URL
        const resolvedUrl = this.$router.resolve(url).href;
        window.open(resolvedUrl, '_blank');
    }
};

app.config.globalProperties.$onClickGame = (gameInfo) => {
    if (gameInfo?.provider === 'zuhaowan' || gameInfo?.provider === 1) {
      // window.open(`/zhwAccountList/${gameInfo?.id || gameInfo?.game_id}`, "_blank");
      window.open(`/gysGameDetail/${gameInfo?.id || gameInfo?.game_id}`, "_blank");
      return;
    }
    window.open(`/detail/${gameInfo?.id || gameInfo?.game_id}`, "_blank");
};

app.use(router);
app.use(store);
app.use(Antd);
app.use(ElementPlus);
app.use(i18n);
// 为了 全局能用到t对象，页面中直接{{ $t('footer.address') }} 
// js中import { useI18n } from 'vue-i18n';  const { t } = useI18n(); t('header.cdkExchange')
app.use(i18nMixin);
app.mount('#app');