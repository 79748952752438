<template>
  <div :class="$style.wrapOut">
    <div :class="$style.wrap">
      <div :class="$style.gameInfo">
        <div :class="$style.infoLeft">
          <SplideCarousel
            :is-full-url="true"
            :images="[
              gameData?.act?.imgurl,
              ...(gameData?.act?.hao_pics?.map?.((item) => item?.jkx_pic) ||
                []),
            ]"
          />
        </div>
        <div :class="$style.infoRight">
          <div :class="$style.rightTitle">{{ gameData?.act?.pn || "-" }}</div>
          <div :class="$style.rightEtitle">
            <div :class="$style.collect">
              {{ `热度：${gameData?.act?.c_recent_rank || "-"}` }}
            </div>
          </div>
          <div :class="$style.rightSeriesWrap">
            <div :class="$style.rightSeries">
              <div :class="$style.series">
                <div :class="$style.seriesTitle">游戏分类</div>
                <div :class="$style.seriesCon">
                  {{
                    `${gameData?.act?.game_name || ""}/${
                      gameData?.act?.game_zone_name || ""
                    }/${gameData?.act?.game_server_name || ""}`
                  }}
                </div>
              </div>
              <div :class="$style.series">
                <div :class="$style.seriesTitle">游戏编号</div>
                <div :class="$style.seriesCon">
                  {{ gameData?.act?.id || "-" }}
                </div>
              </div>
            </div>
            <div :class="$style.rightSeries">
              <div :class="$style.series">
                <div :class="$style.seriesTitle">租号限制</div>
                <div :class="$style.seriesConM">
                  <div :class="$style.seriesConMI">押金0.00元</div>
                  <div :class="$style.seriesConMI">
                    {{ gameData?.act?.bespeak_min || "-" }}小时起租
                  </div>
                  <div :class="$style.seriesConMI">可租时段：00:00-24:00</div>
                </div>
              </div>
            </div>
            <div :class="$style.rightSeries">
              <div :class="$style.series">
                <div :class="$style.seriesTitle">优惠活动</div>
                <div :class="$style.seriesConM">
                  <div
                    v-for="item of gameData?.act?.haoRentGiveList || []"
                    :class="$style.seriesConMII"
                  >
                    {{ item?.remark || "-" }}
                  </div>
                </div>
              </div>
              <div :class="$style.series">
                <div :class="$style.seriesTitle" style="color: #000000">
                  上号器
                </div>
                <div :class="$style.seriesConI" @click="onDownSh">
                  <DownloadOutlined />下载上号器
                </div>
              </div>
            </div>
          </div>
          <div :class="$style.rightSkuWrap">
            <div
              v-for="item in skuData"
              :class="[
                $style.rightSku,
                curSku?.sku_name === item?.sku_name && $style.activeSku,
              ]"
              @click="setCurSku(item)"
            >
              <div
                :class="[
                  $style.rightPrice,
                  curSku?.sku_name === item?.sku_name && $style.activePrice,
                ]"
              >
                ¥{{ item?.show_price_info?.use_dkj_price?.toFixed(2) || "-" }}
              </div>
              <div :class="$style.rightDePrice">
                已抵扣：{{
                  item?.show_price_info?.use_dkj_amount?.toFixed(2) || "-"
                }}
              </div>
              <div
                :class="[
                  $style.rightSkuName,
                  curSku?.sku_name === item?.sku_name && $style.acticeSkuName,
                ]"
              >
                {{ item?.sku_name || "-" }}
              </div>
            </div>
          </div>
          <div v-show="curSku?.sku_name === '时租'" :class="$style.rightText">
            <div :class="$style.rightTexto">选择时间</div>
            <div :class="$style.rightTextt">
              <div
                v-for="item of [1, 2, 3, 4, 5]"
                :class="$style.rightTexttI"
                @click="onClickTime(item)"
              >
                <img
                  v-show="timeRef === item"
                  :class="$style.rightTexttII"
                  :src="seleIcon"
                  alt=""
                />
                <div
                  v-show="timeRef !== item"
                  :class="$style.rightTexttIID"
                ></div>
                <div>{{ item }}小时</div>
              </div>
            </div>
            <a-input-number v-model:value="timeRef" :min="1" />
          </div>
          <div :class="$style.rightBtn">
            <a-button
              type="primary"
              :class="$style.rightBtnB"
              @click="openThirdPartyPayment"
              >立即租用</a-button
            >
            <a-button :class="$style.rightBtnV" @click="onClickCopyInfo"
              >复制信息</a-button
            >
          </div>
          <div :class="$style.rightTip">
            <div>温馨提示：</div>
            <div>
              不得使用外挂，仅个人使用，不能进行转租借或者假激活等商业盈利行为！
            </div>
          </div>
        </div>
      </div>
      <!-- 新游推荐 -->
      <div :class="$style.new">
        <GamesReBanner
          :left-icon="newIcon"
          :left-img="leftImg"
          :right-img="rightImg"
          left-title="新游推荐"
          :game-infos="getGameInfos(newGames)"
          @on-click-more="onClickMoreNew"
        />
      </div>
      <div :class="$style.gameDetail" style="padding-bottom: 0">
        <div :class="$style.gameDetailT">账号描述</div>
        <div :class="$style.gameDetailC">
          <div :class="$style.gameDetailCI">
            <div :class="$style.gameDetailCIT">角色名：</div>
            <div :class="$style.gameDetailCIC">
              {{ gameData?.act?.jsm || "-" }}
            </div>
          </div>
          <div :class="$style.gameDetailCI">
            <div :class="$style.gameDetailCIT">等级：</div>
            <div :class="$style.gameDetailCIC">
              {{ gameData?.act?.dw || "-" }}
            </div>
          </div>
          <div
            v-for="item of gameData?.act?.summary
              ? Object.keys(gameData?.act?.summary)
              : []"
            :class="$style.gameDetailCI"
          >
            <div :class="$style.gameDetailCIT">{{ item }}：</div>
            <div :class="$style.gameDetailCIC">
              {{ gameData?.act?.summary?.[item] || "-" }}
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.gameDetail" style="padding-bottom: 0">
        <div :class="$style.gameDetailT">热门属性</div>
        <div :class="$style.gameDetailC">
          <div
            v-for="item of gameData?.act?.account_tag_list"
            :class="$style.gameDetailCI"
          >
            <div :class="$style.gameDetailCID">#{{ item || "-" }}</div>
          </div>
        </div>
      </div>
      <div :class="$style.gameDetail" style="padding-bottom: 0">
        <div :class="$style.gameDetailT">号主描述</div>
        <div :class="$style.gameDetailCD">
          {{ gameData?.act?.youxi || "-" }}
        </div>
      </div>
      <div :class="$style.gameDetail" style="padding-bottom: 0">
        <div :class="$style.gameDetailT">账号截图</div>
        <div :class="$style.gameDetailCSp">
          <SplideCarousel
            :is-full-url="true"
            :images="
              gameData?.act?.hao_pics?.map?.((item) => item?.jkx_pic) || []
            "
          />
        </div>
      </div>
    </div>
    <PaymentModal
      :isOpen="isOpen"
      :isOrderOpen="isOrderOpen"
      :paymentType="paymentType"
      :orderInfo="orderInfo"
      @setIsOpen="backClosePayment"
      @setIsOrderOpen="(value) => (isOrderOpen = value)"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/axios";
import { DownloadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import useClipboard from "vue-clipboard3";

import GamesReBanner from "@/views/homeView/components/GamesReBanner.vue";
import SplideCarousel from "@components/splideCarousel.vue";
import newIcon from "@assets/newIcon.png";
import leftImg from "@assets/leftImg.png";
import rightImg from "@assets/rightImg.png";
import seleIcon from "@assets/seleIcon.png";

const { toClipboard } = useClipboard();
const route = useRoute();
const router = useRouter();
const gameData = ref();
const skuData = ref();
const orderInfo = ref({});
const newGames = ref([]);

const props = defineProps({
  gameId: String,
});

import PaymentModal from "@/components/BuyPlanModalv3.vue";
const isOpen = ref(false);
const isOrderOpen = ref(false);
const paymentType = ref(0);
const curSku = ref(null);
const timeRef = ref(1);

const tmp = ref();
// SKU 支付
const openSkuPayment = () => {
  paymentType.value = 0;
  // curSku.value = sku;
  isOpen.value = true;
};

const backClosePayment = (value) => {
  tmp.value = Date.now();
  isOpen.value = value;
};

// 点击复制信息
const onClickCopyInfo = async () => {
  const text = `【编号】：${gameData.value?.act?.id}
【游戏名称】：${gameData.value?.act?.game_name}
【时租价格】：${skuData?.value?.[0]?.show_price_info?.use_dkj_price?.toFixed(
    2
  )}元
  `;
  try {
    await toClipboard(text);
    message.success("复制成功");
  } catch (e) {
    console.error(e);
  }
};

// 切换游戏时，重制选中的curSku
watch(async () => {
  if (!route.params?.id) return;
  curSku.value = undefined;
});

// 获取游戏详情
watch(async () => {
  if (!route.params?.id) return;
  const res = await axios
    .post("web/gys/zhw_account_info/", {
      id: route.params?.id && +route.params?.id,
    })
    .catch(() => {});
  if (res?.data?.status === 10000) {
    gameData.value = res?.data?.data?.data || {};
  }
});

// 获取sku列表
watch(async () => {
  if (!route.params?.id) return;
  const res = await axios
    .post("web/gys/zhw_get_sku_info_view/", {
      zhw_account_id: route.params?.id,
      game_id: props?.gameId,
    })
    .catch(() => {});
  if (res?.data?.status === 10000) {
    skuData.value = (res?.data?.data || []).slice(0, 4);
    if (skuData.value.length > 0 && !curSku.value) {
      curSku.value = skuData.value[0];
    }
  }
});

// 获取新游推荐
onMounted(async () => {
  const res = await axios
    .post("web/games-recommend/", {
      method: "new_games_recommendations_ids",
      limit: 12,
    })
    .catch(() => {});
  if (res?.data?.status === 10000) {
    newGames.value = res?.data?.data || [];
  }
});

const getGameInfos = (infos) => {
  const arr = [];
  const step = Math.ceil(infos?.length / 4);
  for (let i = 0; i < step; i++) {
    arr.push(infos?.slice(i * 4, (i + 1) * 4));
  }
  return arr;
};

// 选择购买方案
const setCurSku = (cur) => {
  curSku.value = cur;
};

// 点击新游推荐更多
const onClickMoreNew = () => {
  router.push(`/gameStore`);
};

// 点击购买
const openThirdPartyPayment = async () => {
  if (!curSku?.value) {
    message.warning("请选择购买方式");
    return;
  }
  let timeCount;
  let realPrice = curSku.value?.show_price_info?.use_dkj_price;
  let Price = curSku.value?.show_price_info?.price;
  let deduction = curSku.value?.show_price_info?.use_dkj_amount;
  if (curSku.value?.sku_name === "时租") {
    timeCount = timeRef.value;
    const res = await axios
      .post("web/gys/eval_one_sku_info_view/", {
        zhw_account_id: route.params?.id,
        game_id: props?.gameId,
        time_count: timeRef.value,
      })
      .catch(() => {
        return;
      });
    realPrice = res?.data?.data?.use_dkj_price;
    Price = res?.data?.data?.price;
    deduction = res?.data?.data?.use_dkj_amount;
  } else {
    timeCount = curSku.value?.time_count;
  }
  paymentType.value = 3;
  orderInfo.value = {
    realPrice,
    Price,
    deduction,
    time_count: timeCount,
    time_remark: `${timeCount}小时`,
    game_id: props?.gameId,
    gys_account_number: route.params?.id,
    gname: curSku.value?.gname,
  };
  isOrderOpen.value = true;
  isOpen.value = false;
};

const onDownSh = () => {
  window.open("https://xxx.zuhaotong.com/#/downloadpc", "_blank");
};

// 选择时间
const onClickTime = (timeCur) => {
  timeRef.value = timeCur;
};
</script>

<style module>
.wrapOut {
  padding-top: 10px;
}
.wrap {
  padding: 10px 230px;
  transform: scale(0.85);
  transform-origin: 50% 0;
  /* margin-bottom: -500px; */
}
.gameInfo {
  display: flex;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 18px 28px;
}
.infoLeft {
  flex-shrink: 0;
  width: 656px;
  height: 530px;
  margin-right: 30px;
  margin-bottom: 125px;
}
.infoRight {
  flex-grow: 1;
  width: 0;
}
.rightTitle {
  font-size: 34px;
  color: #2f2f2f;
}
.rightEtitle {
  margin-top: 10px;
}
.collect {
  text-align: end;
  flex-shrink: 0;
  font-size: 19px;
  font-weight: 400;
  color: #ff870f;
}
.rightSeriesWrap {
  background-color: #fafafa;
  padding: 20px;
  margin-top: 20px;
}
.rightSeries {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.rightSeries:last-child {
  margin-bottom: 0;
}
.series {
  height: 25px;
  display: flex;
  align-items: center;
}
.seriesTitle {
  color: #767676;
  margin-right: 42px;
}
.seriesCon {
  color: #2b2b2b;
  font-weight: 400;
  white-space: nowrap; /* 防止内容换行 */
}
.seriesConI {
  font-weight: 400;
  width: 130px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  color: #237aff;
  border-radius: 2px;
  background-color: rgba(35, 122, 255, 0.16);
  cursor: pointer;
}
.seriesConM {
  display: flex;
  align-items: center;
}
.seriesConMI {
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  padding: 0 12px;
  background-color: rgba(201, 201, 201, 0.19);
  margin-right: 9px;
}
.seriesConMII {
  width: 77px;
  height: 30px;
  line-height: 30px;
  border-radius: 2px;
  background-color: rgba(255, 135, 15, 0.19);
  color: rgba(255, 135, 15, 1);
  margin-right: 16px;
  text-align: center;
  border: 1px solid rgba(255, 135, 15, 1);
}
.rightSkuWrap {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  /* overflow-x: auto; */
}
.rightSku {
  flex: 1;
  flex-shrink: 0;
  /* width: 171px; */
  height: 110px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  background-color: #f8f8f8;
  position: relative;
  margin-right: 18px;
  cursor: pointer;
}
.rightSku:last-child {
  margin-right: 0;
}
.rightPrice {
  font-size: 24px;
  color: #171c61;
  text-align: center;
  margin-top: 15px;
}
.rightDePrice {
  font-size: 14px;
  color: #c6c6c6;
  text-align: center;
  margin-top: 8px;
}
.rightSkuName {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  margin-top: auto;
  color: #272727;
  background-color: #eaeaea;
}
.activeSku {
  background-color: #fffbf8;
  border: 2px solid #f56912;
}
.activePrice {
  color: #ff1d1d;
}
.acticeSkuName {
  color: #ff1d1c;
  background-color: #fde4d3;
}
.rightText {
  display: flex;
  align-items: center;
  margin-top: 15px;
  height: 42px;
  line-height: 42px;
  background-color: #fffbf8;
  font-size: 18px;
}
.rightTexto {
  color: #767676;
  margin-left: 17px;
  margin-right: 29px;
}
.rightTextt {
  display: flex;
  align-items: center;
}
.rightTexttI {
  flex-shrink: 0;
  margin-right: 25px;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.rightTexttII {
  width: 17px;
  height: 17px;
  margin-right: 9px;
}
.rightTexttIID {
  width: 17px;
  height: 17px;
  margin-right: 9px;
  border-radius: 17px;
  background-color: rgba(255, 135, 15, 0.31);
}
.rightBtn {
  margin-top: 15px;
}
.rightBtnB {
  font-size: 19px;
  width: 177px;
  height: 51px;
  background-color: rgba(255, 73, 49, 1);
  border-radius: 5px;
  margin-right: 16px;
}
.rightBtnB:hover {
  background-color: rgba(255, 73, 49, 0.7) !important;
}
.rightBtnV {
  font-size: 19px;
  width: 177px;
  height: 51px;
  border-radius: 5px;
  border: none;
  color: rgba(35, 122, 255, 1);
  background-color: rgba(234, 242, 253, 1);
}
.rightBtnV:hover {
  background-color: rgba(234, 242, 253, 0.7) !important;
  color: rgba(35, 122, 255, 0.7) !important;
}
.rightTip {
  margin-top: 21px;
  font-size: 16px;
  color: #5a5a5a;
}
.rightTip div {
  line-height: 30px;
}
.new {
  margin-top: 27px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 16px;
}
.gameDetail {
  margin-top: 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  padding: 16px;
}
.gameDetailT {
  width: 119px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 5px;
  background-color: #237aff;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}
.gameDetailCD {
  font-size: 21px;
  font-weight: 400;
  color: #5a5a5a;
  padding-bottom: 20px;
}
.gameDetailC {
  display: flex;
  flex-wrap: wrap;
}
.gameDetailCI {
  display: flex;
  align-items: center;
  width: 25%;
  font-size: 21px;
  font-weight: 400;
  color: #5a5a5a;
  margin-bottom: 20px;
}
.gameDetailCID {
  color: #ff870f;
}
.gameDetailCIT {
  flex-shrink: 0;
  height: 30px;
  line-height: 30px;
}
.gameDetailCIC {
  flex-grow: 1;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gameDetailCSp {
  padding: 0 50px;
  height: 800px;
  padding-bottom: 200px;
}
</style>
<style scoped>
:deep .ant-input-number {
  margin-left: auto;
}
:deep .ant-input-number-input {
  height: 35px;
  line-height: 35px;
  font-size: 15px;
}
</style>