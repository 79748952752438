<template>
  <div>
    <a-modal
      :open="isOpen && paymentType in [0, 1]"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.modal"
    >
      <template #title>
        <div :class="$style.modalTitle">{{ paymentType === 1 ? '会员购买' : curSku?.gname ? `《${curSku?.gname}》` : '购买方案' }}</div>
      </template>

      <template #footer>
        <div :class="$style.modalBtn">
          <a-button key="submit" :class="$style.modalSubBtn" type="primary" :loading="loading" @click="handleBuy">立即购买</a-button>
          <!-- <a-button key="back">会员免费玩</a-button> -->
        </div>
      </template>
      
      <div :class="$style.title">
        <img :class="$style.titleImg" :src="trumpetIcon" />
        <div :class="$style.titleCon">永久版可永久租玩单个游戏；会员可畅玩全站3000+款游戏，价值10万+元！</div>
      </div>

      
      <div :class="$style.content">
        <div
          v-if="paymentType === 0"
          :class="[$style.contentI, curItem?.sku_name === curSku?.sku_name && $style.activeSku]"
          @click="setCurItem(curSku)"
        >
          <div :class="$style.contentITit">{{curSku?.sku_name || '-'}}</div>
          <div :class="$style.contentIPrice">¥{{curSku?.show_price_info?.price?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIDe">¥{{curSku?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIFP">
            <div :class="$style.contentIFPT">¥{{curSku?.show_price_info?.use_dkj_price?.toFixed(2) || '-'}}</div>
            <div :class="$style.contentIFPB">立省¥{{curSku?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          </div>
        </div>
        <div
          v-for="item in vipData"
          :class="[$style.contentI, curItem?.vip_sku_value === item?.vip_sku_value && $style.activeSku]"
          @click="setCurItem(item)"
        >
          <div :class="$style.contentITit">{{item?.vip_sku_value || '-'}}</div>
          <div :class="$style.contentIPrice">¥{{item?.price?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIDe">¥{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          <div :class="$style.contentIFP">
            <div :class="$style.contentIFPT">¥{{item?.use_dkj_price?.toFixed(2) || '-'}}</div>
            <div :class="$style.contentIFPB">立省¥{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      :open="isOpen && paymentType === 2"
      @cancel="handleCancel"
      :maskClosable="false"
      :footer="null"	
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.modalCdk"
    >
      <template #title>
        <div :class="$style.modalTitle">CDK充值</div>
      </template>
      <div :class="$style.modalConTit">
        <div :class="$style.modalConTitL">任何支付问题请在右上角点击联系客服</div>
        <div :class="$style.modalConTitR">如果支付失败，扣款将在5分钟内原路退回</div>
      </div>
      <div :class="$style.modalConCon">
        <div :class="$style.modalConConI" style="border-right: 1px solid #E3E3E3">
          <div :class="$style.modalConConILT">
            <div>{{ cdkRechargeRules?.cdk_recharge_rule1 || '' }}</div>
            <div>{{ cdkRechargeRules?.cdk_recharge_rule2 || '' }}</div>
            <div>{{ cdkRechargeRules?.cdk_recharge_rule3 || '' }}</div>
          </div>
          <div :class="$style.modalConConILC">
            <div :class="$style.modalConConILCL">
              <div :class="$style.modalConConILCLL">购买名称</div>
              <div :class="$style.modalConConILCLV">CDK余额充值</div>
            </div>
            <div :class="$style.modalConConILCL">
              <div :class="$style.modalConConILCLL">充值金额</div>
              <div :class="$style.modalConConILCLV"><a-input-number v-model:value="rechargeAmount" :min="100" /></div>
            </div>
            <div :class="$style.modalConConILCF">
              <div :class="$style.modalConConILCFL">
                <div :class="$style.modalConConILCFLL">所得金额</div>
                <a-tooltip>
                  <template #title>{{ cdkGetAmount ? `${cdkGetAmount}元` : '-' }}</template>
                  <div :class="$style.modalConConILCFLV">¥{{cdkGetAmount || "-"}}元</div>
                </a-tooltip>
              </div>
              <div :class="$style.modalConConILCFL">
                <div :class="$style.modalConConILCFLL">订单金额</div>
                <a-tooltip>
                  <template #title>{{ rechargeAmount ? `${rechargeAmount}元` : '-' }}</template>
                  <div :class="$style.modalConConILCFLV">¥{{rechargeAmount || "-"}}元</div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.modalConConI">
          <div :class="$style.modalConConII">
            <div :class="$style.modalConConIRT">选择支付方式</div>
            <div :class="$style.modalConConIRP" @click="() => { cdkPayType = 'alipay' }">
              <img :class="$style.modalConConIRPZImg" :src="zfbIcon" />
              <div :class="$style.modalConConIRPT">
                <div :class="$style.modalConConIRPTT">支付宝</div>
                <div :class="$style.modalConConIRPTD">支付宝安全支付</div>
              </div>
              <img v-if="cdkPayType === 'alipay'" :class="$style.modalConConIRPSI" :src="seleIcon" />
              <div v-else :class="$style.modalConConIRPI"></div>
            </div>
            <div :class="$style.modalConConIRPDir"></div>
            <div :class="$style.modalConConIRP"  @click="() => { cdkPayType = 'wechatpay' }">
              <img :class="$style.modalConConIRPWImg" :src="wxIcon" />
              <div :class="$style.modalConConIRPT">
                <div :class="$style.modalConConIRPTT">微信支付</div>
                <div :class="$style.modalConConIRPTD">亿万用户的选择，更快更安全</div>
              </div>
              <img v-if="cdkPayType === 'wechatpay'" :class="$style.modalConConIRPSI" :src="seleIcon" />
              <div v-else :class="$style.modalConConIRPI"></div>
            </div>
            <a-button :class="$style.modalConConIRBtn" type="primary" shape="round" @click="onCdkPay(cdkPayType === 'alipay' ? 0 : 1)">
              确认支付
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      :open="isOpen && paymentType === 3"
      @cancel="handleCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.modal"
    >
      <template #title>
        <div :class="$style.modalTitle">定额支付</div>
      </template>

      <template #footer>
        <div :class="$style.modalBtn">
          <a-button key="submit" :class="$style.modalSubBtn" type="primary" :loading="loading" @click="handleBuy">立即购买</a-button>
          <!-- <a-button key="back">会员免费玩</a-button> -->
        </div>
      </template>
      
    </a-modal>

    <!-- 订单信息弹窗 -->
    <a-modal
      :open="isOrderOpen"
      @cancel="handleOrderCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.orderModal"
      :footer="null"
    >
      <template #title>
        <div :class="$style.modalTitle">订单信息</div>
      </template>
      <div :class="$style.modalConTit">
        <div :class="$style.modalConTitL">任何支付问题请在右上角点击联系客服</div>
        <div :class="$style.modalConTitR">如果支付失败，扣款将在5分钟内原路退回</div>
      </div>
      <div :class="$style.modalConCon">
        <div :class="$style.modalConConI" style="border-right: 1px solid #E3E3E3">
          <!-- 会员订单展示项 -->
          <div v-if="!curItem?.sku_name && paymentType in [0, 1]" style="width: 100%">
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">会员等级</div>
              <div :class="$style.orderVal">{{curItem?.vip_sku_value || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">会员时长</div>
              <div :class="$style.orderVal">{{curItem?.time_remark || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">订单金额</div>
              <div :class="$style.orderVal">
                ¥{{curItem?.sku_name ? curItem?.show_price_info?.price?.toFixed(2) || '-' : curItem?.price?.toFixed(2) || '-'}}
              </div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">使用抵扣金</div>
              <div :class="$style.orderVal">
                ¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_amount?.toFixed(2) || '-' : curItem?.use_dkj_amount?.toFixed(2) || '-'}}
              </div>
            </div>
            <div :class="$style.orderItem">
              <div :class="[$style.orderLab, $style.orderLabB]">实际金额</div>
              <div :class="[$style.orderVal, $style.orderValB]">
                ¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_price?.toFixed(2) || '-' : curItem?.use_dkj_price?.toFixed(2) || '-'}}
              </div>
            </div>
          </div>
          <!-- sku订单展示项 -->
          <div v-if="!!curItem?.sku_name && paymentType in [0, 1]" style="width: 100%">
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">游戏名称</div>
              <a-tooltip>
                <template #title>{{ curItem?.gname || '-' }}</template>
                <div :class="[$style.orderVal, $style.orderValName]">{{curItem?.gname || '-'}}</div>
              </a-tooltip>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">租号时长</div>
              <div :class="$style.orderVal">{{curItem?.sku_name || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">租号时间</div>
              <div :class="$style.orderVal">以订单实际支付时间为准</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">订单金额</div>
              <div :class="$style.orderVal">
                ¥{{curItem?.sku_name ? curItem?.show_price_info?.price?.toFixed(2) || '-' : curItem?.price?.toFixed(2) || '-'}}
              </div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">使用抵扣金</div>
              <div :class="$style.orderVal">
                ¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_amount?.toFixed(2) || '-' : curItem?.use_dkj_amount?.toFixed(2) || '-'}}
              </div>
            </div>
            <div :class="$style.orderItem">
              <div :class="[$style.orderLab, $style.orderLabB]">实际金额</div>
              <div :class="[$style.orderVal, $style.orderValB]">
                ¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_price?.toFixed(2) || '-' : curItem?.use_dkj_price?.toFixed(2) || '-'}}
              </div>
            </div>
          </div>
          <!-- cdk充值 -->
          <div v-if="paymentType == 2" style="width: 100%">
            <!-- <div :class="$style.modalConConILT">
              <div>{{ cdkRechargeRules?.cdk_recharge_rule1 || '' }}</div>
              <div>{{ cdkRechargeRules?.cdk_recharge_rule2 || '' }}</div>
              <div>{{ cdkRechargeRules?.cdk_recharge_rule3 || '' }}</div>
            </div>
            <div :class="$style.modalConConILC">
              <div :class="$style.modalConConILCL">
                <div :class="$style.modalConConILCLL">购买名称</div>
                <div :class="$style.modalConConILCLV">CDK余额充值</div>
              </div>
              <div :class="$style.modalConConILCL">
                <div :class="$style.modalConConILCLL">充值金额</div>
                <div :class="$style.modalConConILCLV"><a-input-number v-model:value="rechargeAmount" :min="100" /></div>
              </div>
              <div :class="$style.modalConConILCF">
                <div :class="$style.modalConConILCFL">
                  <div :class="$style.modalConConILCFLL">所得金额</div>
                  <a-tooltip>
                    <template #title>{{ cdkGetAmount ? `${cdkGetAmount}元` : '-' }}</template>
                    <div :class="$style.modalConConILCFLV">¥{{cdkGetAmount || "-"}}元</div>
                  </a-tooltip>
                </div>
                <div :class="$style.modalConConILCFL">
                  <div :class="$style.modalConConILCFLL">订单金额</div>
                  <a-tooltip>
                    <template #title>{{ rechargeAmount ? `${rechargeAmount}元` : '-' }}</template>
                    <div :class="$style.modalConConILCFLV">¥{{rechargeAmount || "-"}}元</div>
                  </a-tooltip>
                </div>
              </div>
            </div> -->
          </div>
          <!-- sku订单展示项 -->
          <div v-if="paymentType == 3" style="width: 100%">
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">游戏名称</div>
              <a-tooltip>
                <template #title>{{ orderInfo?.gname || '-' }}</template>
                <div :class="[$style.orderVal, $style.orderValName]">{{orderInfo?.gname || '-'}}</div>
              </a-tooltip>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">租号时长</div>
              <div :class="$style.orderVal">{{ orderInfo?.time_remark || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">租号时间</div>
              <div :class="$style.orderVal">以订单实际支付时间为准</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">订单金额</div>
              <div :class="$style.orderVal">
                ¥{{ orderInfo?.Price || '-'}}
              </div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">使用抵扣金</div>
              <div :class="$style.orderVal">
                ¥{{ orderInfo?.deduction || '-'}}
              </div>
            </div>
            <div :class="$style.orderItem">
              <div :class="[$style.orderLab, $style.orderLabB]">实际金额</div>
              <div :class="[$style.orderVal, $style.orderValB]">
                ¥{{ orderInfo?.realPrice || '-'}}
              </div>
            </div>
          </div>
          <!-- 支持直接支付的订单信息展示 -->
          <div v-if="paymentType === 4 && orderInfo" style="width: 100%">
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">游戏名称</div>
              <a-tooltip>
                <template #title>{{ orderInfo?.gname || '-' }}</template>
                <div :class="[$style.orderVal, $style.orderValName]">{{orderInfo?.gname || '-'}}</div>
              </a-tooltip>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">租号时长</div>
              <div :class="$style.orderVal">{{orderInfo?.time_remark || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">订单金额</div>
              <div :class="$style.orderVal">¥{{orderInfo?.Price?.toFixed(2) || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="$style.orderLab">使用抵扣金</div>
              <div :class="$style.orderVal">¥{{orderInfo?.deduction?.toFixed(2) || '-'}}</div>
            </div>
            <div :class="$style.orderItem">
              <div :class="[$style.orderLab, $style.orderLabB]">实际金额</div>
              <div :class="[$style.orderVal, $style.orderValB]">¥{{orderInfo?.realPrice?.toFixed(2) || '-'}}</div>
            </div>
          </div>
        </div>
        <div :class="$style.modalConConI">
          <div :class="$style.modalConConII">
            <div :class="$style.modalConConIRT">选择支付方式</div>
            <div :class="$style.modalConConIRP" @click="() => { cdkPayType = 'alipay' }">
              <img :class="$style.modalConConIRPZImg" :src="zfbIcon" />
              <div :class="$style.modalConConIRPT">
                <div :class="$style.modalConConIRPTT">支付宝</div>
                <div :class="$style.modalConConIRPTD">支付宝安全支付</div>
              </div>
              <img v-if="cdkPayType === 'alipay'" :class="$style.modalConConIRPSI" :src="seleIcon" />
              <div v-else :class="$style.modalConConIRPI"></div>
            </div>
            <div :class="$style.modalConConIRPDir"></div>
            <div :class="$style.modalConConIRP"  @click="() => { cdkPayType = 'wechatpay' }">
              <img :class="$style.modalConConIRPWImg" :src="wxIcon" />
              <div :class="$style.modalConConIRPT">
                <div :class="$style.modalConConIRPTT">微信支付</div>
                <div :class="$style.modalConConIRPTD">亿万用户的选择，更快更安全</div>
              </div>
              <img v-if="cdkPayType === 'wechatpay'" :class="$style.modalConConIRPSI" :src="seleIcon" />
              <div v-else :class="$style.modalConConIRPI"></div>
            </div>
            <a-button :class="$style.modalConConIRBtn" type="primary" shape="round" @click="onClickPay(cdkPayType === 'alipay' ? 0 : 1)">
              确认支付
            </a-button>
          </div>
        </div>
      </div>
      <!-- <div :class="$style.orderDirB"></div>
      <div :class="$style.orderButT">选择支付方式</div>
      <img :class="$style.orderBuyImg" :src="alipay" @click="onClickPay(0)" />
      <img :class="$style.orderBuyImg" :src="wechat" @click="onClickPay(1)"/> -->
    </a-modal>

    <!-- 支付确认弹窗 -->
    <a-modal
      :open="isBuyOpen"
      @cancel="handleBuyCancel"
      :maskClosable="false"
      :destroyOnClose="true"
      width="100%"
      :wrapClassName="$style.orderBuyModal"
      :footer="null"
    >
      <template #title>
        <div :class="$style.modalTitle">支付确认</div>
      </template>
      <div :class="$style.orderTip">
        <div :class="$style.orderTipC">任何支付问题请右上角点击联系微信客服</div>
        <div :class="$style.orderTipR">如果支付失败，抵扣金将在5分钟内返回</div>
      </div>
      <!-- <div :class="[$style.orderItem, $style.buyItem]">
        <div :class="$style.orderLab">支付方式：</div>
        <div :class="$style.orderVal">{{payType === 0 ? '支付宝' : '微信'}}</div>
      </div>
      <div :class="$style.payAmount">
        应付金额：<span :class="$style.payAmountP">¥{{curItem?.sku_name ? curItem?.show_price_info?.use_dkj_price?.toFixed(2) || '-' : curItem?.use_dkj_price?.toFixed(2) || '-'}}</span>
      </div> -->
      <div :class="$style.qrCodeUrl">
        <a-qrcode :value="qrCodeUrl" :size="qrSize" />
        <div v-if="isQrMask" :class="$style.qrCodeMask">
          <p :class="$style.qrCodeExpired">二维码已过期</p>
        </div>
      </div>
      <div :class="$style.payBtTip">
        请使用<span :class="$style.payBtTipT"> "{{payType === 0 ? '支付宝' : '微信'}}/浏览器" </span>扫一扫完成支付
      </div>
    </a-modal>
  </div>
</template>
<script setup>
  // 购买方案弹窗
  import { ref, onMounted, defineProps, watch, nextTick, computed, onBeforeUnmount } from "vue";
  import axios from "@/axios";
  import { useRoute, useRouter } from 'vue-router'
  import { message } from "ant-design-vue";

  import { waitTime } from "@/util/utils.js";
  import trumpetIcon from "@assets/trumpetIcon.png";
  import alipay from "@assets/alipay.png";
  import wechat from "@assets/wechat.png";
  import zfbIcon from "@assets/zfbIcon.png";
  import wxIcon from "@assets/wxIcon.png";
  import seleIcon from "@assets/seleIcon.png";

  const route = useRoute();
  const router = useRouter();

  const loading = ref(false); // 用于控制按钮的 loading 状态
  const curItem = ref();
  const vipData = ref();
  const skuData = ref();
  const payType = ref(); // 0:支付宝  1:微信
  const isBuyOpen = ref(false);
  const qrCodeUrl = ref(null);
  const qrSize = ref();
  const isQrMask = ref(false); // 是否展示二维码过期
  let qrCount = 0;
  const chargePrice = ref(0); //cdk充值金额
  const cdkRechargeRules = ref({}); // cdk代理充值规则
  const rechargeAmount = ref(); // cdk充值金额
  const cdkGetAmount = ref(); // cdk所得金额
  const cdkPayType = ref('alipay'); // cdk支付方式 alipay、wechatpay

  const props = defineProps({
    isOpen: Boolean,
    isOrderOpen: Boolean,
    curSku: Object || undefined,
    paymentType: Number, // 0: SKU支付, 1: 会员支付, 2: CDK充值支付, 3: 三方定额支付 4: sku直接支付弹窗 
    orderInfo: Object || undefined, // 三方定额支付时传递的订单信息
  });
  const emit = defineEmits(['setIsOpen', 'setIsOrderOpen', 'paymentSuccess']); // 子组件向父组件发送事件


  watch(() => props.isOpen, async (newVal) => {
    if (newVal) {
      // 请求开始时设置 loading 状态
      loading.value = true;
      try {
        // 发送请求
        const res = await axios.post("web/get_members_buy_plan/", {});
        if (res?.data?.status === 10000) {
          vipData.value = (res?.data?.data || []).slice(0, 3);
          if (props.paymentType === 1) {
            curItem.value = vipData.value[0]
          }
        }

        if (!route.params?.id) return;
        const res1 = await axios.post("web/skus/", { game_id: route.params?.id}).catch(() => {});
        if (res1?.data?.status === 10000) {
          skuData.value = (res1?.data?.data?.items || []).slice(0, 1);
        }
      } catch (error) {
        console.error("请求失败：", error);
      } finally {
        // 请求结束后取消 loading 状态
        loading.value = false;
      }

    }
  });

  // 默认选中父组件选择的sku，这里要重新刷新，但是都是假设取第一个
  watch(() => props.curSku, async () => {
    if (!props?.curSku) return;
    curItem.value = { ...props.curSku };
  })

  watch([rechargeAmount, cdkRechargeRules], async () => {
    const amount = calculateTotalAmount(cdkRechargeRules.value, rechargeAmount.value);
    cdkGetAmount.value = amount;
  })

  // 二维码尺寸
  onMounted(async () => {
    const long = document.documentElement.clientWidth * (256 / 1920);
    qrSize.value = long;
  });

  // 获取cdk代理充值规则
  watch(() => props?.paymentType, async () => {
    if (props?.paymentType !== 2) return;
    const res = await axios.post("web/cdkey_recharge_rules/", {});
    if (res?.data?.status === 10000) {
      cdkRechargeRules.value = res?.data?.data;
    }
  })

  // cdk确认支付
  const onCdkPay = async (pType) => {
    console.log(cdkPayType.value);
    // 判断重置金额，是否低于100，是否填写
    if (!rechargeAmount.value || rechargeAmount.value < 100) {
      message.warning('充值金额不能小于100');
      return;
    }
    // 创建订单
    const orderInfo = await createOrder(pType);
    if (!orderInfo) return;
    isBuyOpen.value = true;
    // await nextTick();
    qrCodeUrl.value = orderInfo?.url;
    checkPaynoInfo(orderInfo?.pay_no, pType);
  }

  // 选择购买方案
  const setCurItem = (cur) => {
    curItem.value = cur;
    // props.orderInfo.value = undefined;
  }


  const handleBuy = () => {
    console.log(props?.paymentType, 80989080)
    if (props.paymentType === 4) {
      if (!props.orderInfo) {
        message.warning("订单异常，请刷新后重试");
        return;
      }
      emit('setIsOrderOpen', true);
      return;
    }

    // 其他支付方式的逻辑
    if (props?.paymentType in [0, 1]) {
      if (!curItem.value || JSON.stringify(curItem.value) === '{}') {
        message.warning("请选择购买方案");
        return;
      }
    } else if (props?.paymentType === 2) {
      if (!chargePrice.value) {
        message.warning("请输入充值金额");
        return;
      }
      if (chargePrice.value < 100) {
        message.warning("充值金额不能小于100元");
        return;
      }
    } else if (props?.paymentType === 3) {
      if (!props?.orderInfo) {
        message.warning("订单异常请刷新后重试");
        return;
      }
    }

    emit('setIsOrderOpen', true);
  };

  // 创建订单
  const createOrder = async (pType) => {
    // 0: SKU支付, 1: 会员支付, 2: CDK充值支付, 3: 三方定额支付 4: 直接支付弹窗 5: 直接二维码
    let result
    if ([0, 1].includes(props?.paymentType)) {
      // 创建订单
      result = await axios.post("web/orders/generate/", {
        skuId: curItem?.value?.id,
        vip_sku_value: curItem?.value?.vip_sku_value,
        payment_type: pType,
        order_type: curItem?.value?.sku_name ? 0 : 1,
        // order_type: props?.paymentType,
        realPrice: curItem?.value?.sku_name ? curItem?.value?.show_price_info?.use_dkj_price : curItem?.value?.use_dkj_price, // 真实支付价格
        Price: curItem?.value?.sku_name ? curItem?.value?.show_price_info?.price : curItem?.value?.price, // 原始价格
        deduction: curItem?.value?.sku_name ? curItem?.value?.show_price_info?.use_dkj_amount : curItem?.value?.use_dkj_amount, // 抵扣金使用金额
        rentId: curItem?.value?.rentId,
      }).catch(() => {});
    } else if (props?.paymentType === 2) {
      // 判断重置金额，是否低于100，是否填写
      if (!rechargeAmount.value || rechargeAmount.value < 100) {
        message.warning('充值金额不能小于100');
        return;
      }
      result = await axios.post("web/orders/generate/", {
        // skuId: curItem?.value?.id,
        // vip_sku_value: curItem?.value?.vip_sku_value,
        payment_type: pType,
        order_type: props?.paymentType,
        realPrice: rechargeAmount.value, // 真实支付价格
        Price: cdkGetAmount.value, // 原始价格
        deduction: 0, // 抵扣金使用金额
      }).catch(() => {});
    } else if (props?.paymentType === 3) {
      // 通过orderinfo 直接支付
      // result = await axios.post("web/orders/generate/", {
      //   payment_type: pType,
      //   order_type: props?.paymentType,
      //   realPrice: props?.orderInfo?.realPrice, // 真实支付价格
      //   Price: props?.orderInfo?.Price, // 原始价格
      //   deduction: props?.orderInfo?.deduction, // 抵扣金使用金额
      //   game_id: props?.orderInfo?.game_id, // 游戏id
      //   time_count: props?.orderInfo?.time_count, // 订单时长数量
      //   time_remark: props?.orderInfo?.time_remark, // 时长描述
      //   gys_account_number: props?.orderInfo?.gys_account_number, //要租赁账号
      // }).catch(() => {});

      result = await axios.post("web/orders/generate/", {
        payment_type: pType,
        order_type: props?.paymentType,
        ...props?.orderInfo,
      }).catch(() => {});
    } else if (props?.paymentType === 4) {
      // 通过orderinfo支付
      result = await axios.post("web/orders/generate/", {
        skuId: props?.orderInfo?.skuId,
        vip_sku_value: props?.orderInfo?.vip_sku_value,
        payment_type: pType,
        order_type: 0,
        realPrice: props?.orderInfo?.realPrice, // 真实支付价格
        Price: props?.orderInfo?.Price, // 原始价格
        deduction: props?.orderInfo?.deduction, // 抵扣金使用金额
      }).catch(() => {});
    } else {
      message.error('不支持的支付方式')
      return
    }
    
    if (result?.data?.status !== 10000) return undefined;
    return result?.data?.data;
  }

  // 轮询订单信息
  const checkPaynoInfo = async (payNo, pType) => {
    // 自动轮训最大次数
    if (qrCount === 1000) {
      isQrMask.value = true;
      qrCount = 0;
      return;
    };
    // 手动关闭轮训
    if (qrCount > 1000) {
      qrCount = 0;
      return;
    };
    await waitTime(1000);
    const res = await axios.post("web/orders/query_order/", { pay_no: payNo }).catch(() => {});
    qrCount++;
    // 支付成功
    if (res?.data?.status === 10000 && res?.data?.data?.status === 100) {
      console.log('支付成功');
      qrCount = 0;
      // 发送刷新事件，通知父组件刷新数据
      emit('paymentSuccess');
      // 关闭弹窗
      isBuyOpen.value = false;
      isQrMask.value = false;
      
      if (res?.data?.data?.type === 0) {
        if (res?.data?.data?.rent_id) {
          router.push("/rendResult");
        } else if (res?.data?.data?.sku_value === '永久') {
          router.push("/myGame");
        } else if (res?.data?.data?.sku_value === '独享') {
          router.push("/exclusive");
        } else {
          router.push("/rendResult");
        }
      } else if (res?.data?.data?.type === 1) {
        router.push("/myMember");
      } else if (res?.data?.data?.type === 2) {
        router.push("/buyCdk");
      } else if (res?.data?.data?.type === 3) {
        router.push("/myOrder");
      } else {
        router.push("/myOrder");
      }
      return;
    };
    // 支付失败 || 支付超时
    if (res?.data?.status === 10000 && res?.data?.data?.status === 404) {
      const orderInfo = await createOrder(pType);
      // qrCount = 0;
      if (!orderInfo) return;
      qrCodeUrl.value = orderInfo?.url;
      checkPaynoInfo(orderInfo?.pay_no, pType);
      return;
    };
    checkPaynoInfo(payNo, pType);
  }

  // 点击支付宝||微信支付
  const onClickPay = async (pType) => {
    payType.value = pType;
    // 查询是否存在老订单
    let res;
    if (props?.paymentType === 3) {
      res = await axios.post("web/orders/check-data/", {
        skuid: props?.orderInfo?.skuId,
        gameid: props?.orderInfo?.game_id,
        payment_type: pType,
      }).catch(() => {});
    } else {
      res = await axios.post("web/orders/check-data/", {
        skuid: curItem?.value?.id,
        vip_sku_value: curItem?.value?.vip_sku_value,
        gameid: curItem?.value?.game_id,
        payment_type: pType,
      }).catch(() => {});
    }
    
    if (res?.data?.status !== 10000) return;
    // 存在老订单
    if(res?.data?.data?.is_exist_order) {
      isBuyOpen.value = true;
      // await nextTick();
      qrCodeUrl.value = res?.data?.data?.url;
      checkPaynoInfo(res?.data?.data?.pay_no, pType);
      return;
    }
    // 创建订单
    const orderInfo = await createOrder(pType);
    if (!orderInfo) return;
    isBuyOpen.value = true;
    // await nextTick();
    qrCodeUrl.value = orderInfo?.url;
    checkPaynoInfo(orderInfo?.pay_no, pType);
  }

  const handleCancel = async () => {
    curItem.value = { ...props.curSku };
    emit("setIsOpen", false);
    // props.isOpen = false;
  };

  const handleOrderCancel = () => {
    emit('setIsOrderOpen', false);
  };

  const handleBuyCancel = () => {
    isBuyOpen.value = false;
    handleOrderCancel();
    handleCancel();
    if (isQrMask.value) {
      qrCount = 0;
      isQrMask.value = false;
    } else {
      qrCount = 1000;
    }
  };

  function calculateTotalAmount(setting_obj_rule, amount) {
    let totalAmount = amount;
    if (setting_obj_rule.cdk_recharge_amount_3 > 0 && amount >= setting_obj_rule.cdk_recharge_amount_3) {
        totalAmount += setting_obj_rule.cdk_recharge_reward_amount_3;
    } else if (setting_obj_rule.cdk_recharge_amount_2 > 0 && amount >= setting_obj_rule.cdk_recharge_amount_2) {
        totalAmount += setting_obj_rule.cdk_recharge_reward_amount_2;
    } else if (setting_obj_rule.cdk_recharge_amount_1 > 0 && amount >= setting_obj_rule.cdk_recharge_amount_1) {
        totalAmount += setting_obj_rule.cdk_recharge_reward_amount_1;
    }
    return totalAmount;
  }

  // 组件销毁前停止轮询
  onBeforeUnmount(() => {
    qrCount = 9999; // 强制停止轮询
  });
</script>

<style module>
  .modal {
    width: 1100px;
    margin: 0 auto;
  }
  .modalCdk {
    width: 798px;
    margin: 0 auto;
  }
  .modalTitle {
    font-size: 18px;
    font-weight: 400;
  }
  .modalBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalSubBtn {
    font-size: 25px;
    width: 200px;
    height: 58px;
    background-color: rgba(255, 73, 49, 1);
    border-radius: 5px;
    margin-right: 14px;
  }
  .modalSubBtn:hover {
    background-color: rgba(255, 73, 49, .7) !important;
  }
  .title {
    background-color: #FEF3E7;
    font-size: 21px;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    margin: 21px 0;
  }
  .titleImg {
    width: 25px;
    height: 25px;
    margin-right: 22px;
  }
  .content {
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
  }
  .contentI {
    border: 1px solid rgba(180, 180, 180, .2);
    width: 270px;
  }
  .contentITit {
    background-color: #FAF4E2;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentIPrice {
    background-color: #FAFAFA;
    color: #646464;
    font-size: 24px;
    height: 76px;
    line-height: 76px;
    text-align: center;
  }
  .contentIDe {
    color: #F5B812;
    font-size: 24px;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentIFP {
    background-color: #FAFAFA;
    padding: 18px 0 15px 0;
  }
  .contentIFPT {
    font-size: 29px;
    color: #FF2727;
    text-align: center;
    margin-bottom: 6px;
  }
  .contentIFPB {
    font-size: 16px;
    color: #888888;
    text-align: center;
    text-decoration: line-through;
  }
  .activeSku {
    background-color: #FFFBF8;
    border: 2px solid #F56912;
  }
  .orderModal {
    width: 798px;
    margin: 0 auto;
  }
  .orderBuyModal {
    width: 441px;
    margin: 0 auto;
  }
  .orderTip {
    font-size: 15px;
    background: #FEF8F1;
    padding: 19px;
    margin-top: 25px;
    margin-bottom: 37px;
  }
  .orderTipC {
    margin-bottom: 16px ;
    line-height: 21px;
    color: #FF870F;
    text-align: center;
  }
  .orderTipR {
    line-height: 21px;
    text-align: center;
    color: #FF2121;
  }
  .orderDir {
    height: 1px;
    margin: 20px 0;
    background-color: #eee;
  }
  .orderDirB {
    height: 1px;
    margin-bottom: 20px;
    background-color: #eee;
  }
  .orderItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #242424;
    line-height: 21px;
    margin-bottom: 25px;
    padding-right: 40px;
    padding-left: 10px;
  }
  .orderLab {
    flex-shrink: 0;
    margin-right: 5px;
  }
  .orderLabB {
    color: #FF2121;
    margin-right: 5px;
  }
  .orderVal {
    flex-grow: 1;
    text-align: end;
    word-break: break-all;
    vertical-align: middle;
  }
  .orderValName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .orderValB {
    color: #FF2121;
    /* font-size: 18px;
    font-weight: 700;
    line-height: 20px; */
  }
  .orderButT {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 700;
    color: #333;
  }
  .orderBuyImg {
    display: block;
    height: 40px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .buyItem {
    margin: 20px 0;
  }
  .payAmount {
    height: 38px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    background-color: #EFF0EF;
  }
  .payAmountP {
    font-weight: 700;
    color: #FF6F00;
    font-size: 16px;
  }
  .qrCodeUrl {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
  }
  .payBtTip {
    text-align: center;
    font-size: 17px;
    color: #333;
    font-weight: 400;
  }
  .payBtTipT {
    color: #237AFF;
  }
  .qrCodeMask {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    background: rgba(255, 255, 255, 0.96);
    text-align: center;
  }
  .modalConTit {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 13px 54px 13px 22px;
    border-radius: 10px;
    background-color: rgba(255, 135, 15, 0.06);
    font-size: 15px;
    margin-bottom: 22px;
  }
  .modalConTitL {
    line-height: 21px;
    color: #FF870F;
  }
  .modalConTitR {
    line-height: 21px;
    color: #FF2121;
  }
  .modalConCon {
    display: flex;
    height: 262px;
  }
  .modalConConI {
    height: 100%;
    width: 0;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .modalConConILT {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #FBF1E2;
    color: #938C83;
    font-size: 20px;
    width: 80%;
    padding: 5px 0;
    border-radius: 5px;
  }
  .modalConConILC {
    margin-top: auto;
    width: 80%;
  }
  .modalConConILCL {
    display: flex;
    align-items: center;
    font-size: 18px;
    width: 100%;
    margin-bottom: 10px;
  }
  .modalConConILCLL {
    color: #CECECE;
    margin-right: 20px;
  }
  .modalConConILCLV {
    color: #6F6F6F;
  }
  .modalConConILCF {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    width: 100%;
  }
  .modalConConILCFL {
    display: flex;
    align-items: center;
  }
  .modalConConILCFLL {
    flex-shrink: 0;
    color: #CECECE;
    margin-right: 10px;
  }
  .modalConConILCFLV {
    width: 70px;
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #6F6F6F;
  }
  .modalConConII {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  .modalConConIRT {
    font-size: 15px;
    line-height: 21px;
    color: #222222;
    font-weight: 500;
    margin-top: 12px;
    width: 100%;
    margin-bottom: 33px;
  }
  .modalConConIRP {
    display: flex;
    align-items: center;
    padding-right: 18px;
    cursor: pointer;
  }
  .modalConConIRPZImg {
    width: 42px;
    height: 42px;
    margin-right: 12px;
  }
  .modalConConIRPWImg {
    width: 40px;
    height: 35px;
    margin-right: 14px;
  }
  .modalConConIRPTT {
    font-size: 16px;
    line-height: 24px;
    color: #1D1D1D;
    font-weight: 500;
    margin-bottom: 5px;
    user-select: none;
  }
  .modalConConIRPTD {
    font-size: 9px;
    line-height: 13px;
    color: #9B9B9B;
    user-select: none;
  }
  .modalConConIRPI {
    width: 17px;
    height: 17px;
    border-radius: 17px;
    background-color: #EAEAEA;
    margin-left: auto;
    user-select: none;
  }
  .modalConConIRPDir {
    background-color: #E8E8E8;
    height: 1px;
    margin: 25px 0 10px 54px;
  }
  .modalConConIRPSI {
    width: 17px;
    height: 17px;
    margin-left: auto;
    user-select: none;
  }
  .modalConConIRBtn {
    margin: auto auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 273px;
    height: 38px;
    font-size: 14px;
    color: #fff;
    background-color: rgba(253, 145, 1, 1);
  }
  .modalConConIRBtn:hover {
    background-color: rgba(253, 145, 1, .7) !important;
  }
</style>