<template>
  <!-- 这个组件不渲染任何内容 -->
</template>

<script>
export default {
  name: 'SeoMetaInfo',
  props: {
    title: {
      type: String,
      default: '租号管家'
    },
    description: {
      type: String,
      default: '租号管家 - 专业的游戏账号租赁平台'
    },
    keywords: {
      type: String,
      default: '租号管家,游戏账号,账号租赁,游戏租号'
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          document.title = newVal;
        }
      }
    },
    description: {
      immediate: true,
      handler(newVal) {
        this.updateMetaTag('description', newVal);
      }
    },
    keywords: {
      immediate: true,
      handler(newVal) {
        this.updateMetaTag('keywords', newVal);
      }
    }
  },
  methods: {
    updateMetaTag(name, content) {
      // 尝试查找现有的元标签
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      
      // 如果元标签不存在，则创建一个
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.name = name;
        document.head.appendChild(metaTag);
      }
      
      // 设置或更新内容
      metaTag.content = content;
    }
  }
}
</script> 