<template>
    <div>
      <a-modal
        :open="isOpen"
        @cancel="handleBuyCancel"
        :maskClosable="false"
        :destroyOnClose="true"
        width="441px"
        :wrapClassName="$style.orderBuyModal"
        :footer="null"
      >
        <template #title>
          <div :class="$style.modalTitle">支付确认</div>
        </template>
        <div :class="$style.orderTip">
          <div :class="$style.orderTipC">任何支付问题请右上角点击联系微信客服</div>
          <div :class="$style.orderTipR">如果支付失败，抵扣金将在5分钟内返回</div>
        </div>
  
        <div :class="$style.qrCodeUrl">
          <a-qrcode :value="qrCodeUrl" :size="qrSize" />
          <div v-if="isQrMask" :class="$style.qrCodeMask">
            <p :class="$style.qrCodeExpired">二维码已过期</p>
          </div>
        </div>
        <div :class="$style.payBtTip">
          请使用<span :class="$style.payBtTipT">“{{ payType === 0 ? '支付宝' : '微信' }}”</span>扫一扫完成支付
        </div>
      </a-modal>
    </div>
  </template>
  
  <script setup>
  import { ref, watch, onMounted } from "vue";
  import axios from "@/axios";
  import { waitTime } from "@/util/utils.js";
  
  const props = defineProps({
    isOpen: Boolean,
    qrCodeUrl: String,
    payType: Number, // 0:支付宝  1:微信
    payNo: String, // 支付单号
  });
  
  const emit = defineEmits(["setIsOpen", "paymentSuccess"]); // 向父组件发送事件
  
  const isQrMask = ref(false); // 二维码过期遮罩
  const qrSize = ref(50); // 二维码大小
  let qrCount = 0; // 轮询计数器
  let pollingInterval = null; // 定时器句柄
  
  // 监听 isOpen 属性，控制弹窗显示
  watch(
    () => props.isOpen,
    (newVal) => {
      if (newVal) {
        startPolling(props.payNo); // 打开时开始轮询
      } else {
        stopPolling(); // 关闭时停止轮询
      }
    }
  );

    // 二维码尺寸
  onMounted(async () => {
    const long = document.documentElement.clientWidth * (256 / 1920);
    qrSize.value = long;
  });
  // 开始轮询订单信息
  const startPolling = (payNo) => {
    if (!payNo) return;
  
    stopPolling(); // 确保清除之前的轮询
    qrCount = 0;
    isQrMask.value = false;
  
    pollingInterval = setInterval(async () => {
      if (qrCount >= 1000 || isQrMask.value) {
        // 达到最大轮询次数或二维码已过期
        isQrMask.value = true;
        stopPolling();
        return;
      }
  
      const res = await axios
        .post("web/orders/query_order/", { pay_no: payNo })
        .catch(() => null);
  
      qrCount++;
      if (res?.data?.status === 10000) {
        const orderStatus = res?.data?.data?.status;
        if (orderStatus === 100) {
          // 支付成功
          emit("paymentSuccess");
          stopPolling();
          handleBuyCancel();
        } else if (orderStatus === 404) {
          // 支付失败或超时
          isQrMask.value = true;
          stopPolling();
        }
      }
    }, 1000); // 每秒轮询一次
  };
  
  // 停止轮询
  const stopPolling = () => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
      pollingInterval = null;
    }
    qrCount = 0;
  };
  
  // 关闭弹窗
  const handleBuyCancel = () => {
    emit("setIsOpen", false);
    stopPolling();
  };
  </script>
<style module>
.modal {
  width: 1100px;
  margin: 0 auto;
}
.modalCdk {
  width: 798px;
  margin: 0 auto;
}
.modalTitle {
  font-size: 18px;
  font-weight: 400;
}
.modalBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalSubBtn {
  font-size: 25px;
  width: 200px;
  height: 58px;
  background-color: rgba(255, 73, 49, 1);
  border-radius: 5px;
  margin-right: 14px;
}
.modalSubBtn:hover {
  background-color: rgba(255, 73, 49, .7) !important;
}
.title {
  background-color: #FEF3E7;
  font-size: 21px;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  margin: 21px 0;
}
.titleImg {
  width: 25px;
  height: 25px;
  margin-right: 22px;
}
.content {
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
}
.contentI {
  border: 1px solid rgba(180, 180, 180, .2);
  width: 270px;
}
.contentITit {
  background-color: #FAF4E2;
  color: #1A1A1A;
  font-size: 21px;
  font-weight: bold;
  height: 52px;
  line-height: 52px;
  text-align: center;
}
.contentIPrice {
  background-color: #FAFAFA;
  color: #646464;
  font-size: 24px;
  height: 76px;
  line-height: 76px;
  text-align: center;
}
.contentIDe {
  color: #F5B812;
  font-size: 24px;
  height: 52px;
  line-height: 52px;
  text-align: center;
}
.contentIFP {
  background-color: #FAFAFA;
  padding: 18px 0 15px 0;
}
.contentIFPT {
  font-size: 29px;
  color: #FF2727;
  text-align: center;
  margin-bottom: 6px;
}
.contentIFPB {
  font-size: 16px;
  color: #888888;
  text-align: center;
  text-decoration: line-through;
}
.activeSku {
  background-color: #FFFBF8;
  border: 2px solid #F56912;
}
.orderModal {
  width: 798px;
  margin: 0 auto;
}
.orderBuyModal {
  width: 641px;
  margin: 0 auto;
}
.orderTip {
  font-size: 15px;
  background: #FEF8F1;
  padding: 19px;
  margin-top: 25px;
  margin-bottom: 37px;
}
.orderTipC {
  margin-bottom: 16px ;
  line-height: 21px;
  color: #FF870F;
  text-align: center;
}
.orderTipR {
  line-height: 21px;
  text-align: center;
  color: #FF2121;
}
.orderDir {
  height: 1px;
  margin: 20px 0;
  background-color: #eee;
}
.orderDirB {
  height: 1px;
  margin-bottom: 20px;
  background-color: #eee;
}
.orderItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #242424;
  line-height: 21px;
  margin-bottom: 25px;
  padding-right: 40px;
  padding-left: 10px;
}
.orderLab {
  flex-shrink: 0;
  margin-right: 5px;
}
.orderLabB {
  color: #FF2121;
  margin-right: 5px;
}
.orderVal {
  flex-grow: 1;
  text-align: end;
  word-break: break-all;
  vertical-align: middle;
}
.orderValName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.orderValB {
  color: #FF2121;
  /* font-size: 18px;
  font-weight: 700;
  line-height: 20px; */
}
.orderButT {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 700;
  color: #333;
}
.orderBuyImg {
  display: block;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
}
.buyItem {
  margin: 20px 0;
}
.payAmount {
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  background-color: #EFF0EF;
}
.payAmountP {
  font-weight: 700;
  color: #FF6F00;
  font-size: 16px;
}
.qrCodeUrl {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
}
.payBtTip {
  text-align: center;
  font-size: 17px;
  color: #333;
  font-weight: 400;
}
.payBtTipT {
  color: #237AFF;
}
.qrCodeMask {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  background: rgba(255, 255, 255, 0.96);
  text-align: center;
}
.modalConTit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 54px 13px 22px;
  border-radius: 10px;
  background-color: rgba(255, 135, 15, 0.06);
  font-size: 15px;
  margin-bottom: 22px;
}
.modalConTitL {
  line-height: 21px;
  color: #FF870F;
}
.modalConTitR {
  line-height: 21px;
  color: #FF2121;
}
.modalConCon {
  display: flex;
  height: 262px;
}
.modalConConI {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalConConILT {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FBF1E2;
  color: #938C83;
  font-size: 20px;
  width: 80%;
  padding: 5px 0;
  border-radius: 5px;
}
.modalConConILC {
  margin-top: auto;
  width: 80%;
}
.modalConConILCL {
  display: flex;
  align-items: center;
  font-size: 18px;
  width: 100%;
  margin-bottom: 10px;
}
.modalConConILCLL {
  color: #CECECE;
  margin-right: 20px;
}
.modalConConILCLV {
  color: #6F6F6F;
}
.modalConConILCF {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 100%;
}
.modalConConILCFL {
  display: flex;
  align-items: center;
}
.modalConConILCFLL {
  flex-shrink: 0;
  color: #CECECE;
  margin-right: 10px;
}
.modalConConILCFLV {
  width: 70px;
  overflow-x: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #6F6F6F;
}
.modalConConII {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
}
.modalConConIRT {
  font-size: 15px;
  line-height: 21px;
  color: #222222;
  font-weight: 500;
  margin-top: 12px;
  width: 100%;
  margin-bottom: 33px;
}
.modalConConIRP {
  display: flex;
  align-items: center;
  padding-right: 18px;
  cursor: pointer;
}
.modalConConIRPZImg {
  width: 42px;
  height: 42px;
  margin-right: 12px;
}
.modalConConIRPWImg {
  width: 40px;
  height: 35px;
  margin-right: 14px;
}
.modalConConIRPTT {
  font-size: 16px;
  line-height: 24px;
  color: #1D1D1D;
  font-weight: 500;
  margin-bottom: 5px;
  user-select: none;
}
.modalConConIRPTD {
  font-size: 9px;
  line-height: 13px;
  color: #9B9B9B;
  user-select: none;
}
.modalConConIRPI {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  background-color: #EAEAEA;
  margin-left: auto;
  user-select: none;
}
.modalConConIRPDir {
  background-color: #E8E8E8;
  height: 1px;
  margin: 25px 0 10px 54px;
}
.modalConConIRPSI {
  width: 17px;
  height: 17px;
  margin-left: auto;
  user-select: none;
}
.modalConConIRBtn {
  margin: auto auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 273px;
  height: 38px;
  font-size: 14px;
  color: #fff;
  background-color: rgba(253, 145, 1, 1);
}
.modalConConIRBtn:hover {
  background-color: rgba(253, 145, 1, .7) !important;
}
</style>