/**
 * SEO相关辅助函数
 */

/**
 * 动态设置页面SEO信息
 * @param {Object} options - SEO配置选项
 * @param {string} options.title - 页面标题
 * @param {string} options.description - 页面描述
 * @param {string} options.keywords - 页面关键词
 */
export const setSeoInfo = (options) => {
  const { title, description, keywords } = options;
  
  // 设置标题
  if (title) {
    document.title = title.includes('租号管家') ? title : `${title} - 租号管家`;
  }
  
  // 设置描述
  if (description) {
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.name = 'description';
      document.head.appendChild(metaDescription);
    }
    metaDescription.content = description;
    
    // 同时更新OpenGraph描述
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogDescription) {
      ogDescription.content = description;
    }
  }
  
  // 设置关键词
  if (keywords) {
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.name = 'keywords';
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.content = keywords;
  }
};

/**
 * 设置游戏详情页SEO信息
 * @param {Object} gameInfo - 游戏信息
 */
export const setGameSeoInfo = (gameInfo) => {
  if (!gameInfo) return;
  const title = `${gameInfo.gname || gameInfo.title || '游戏详情'}_${gameInfo.pingtai || ''}租号_租号管家`;
  const description = `租号管家 ${gameInfo.gname || gameInfo.title || '游戏'} ${gameInfo.describe ? '，' + gameInfo.describe : '账号租赁服务'}`;
  const keywords = `${gameInfo.gname || '游戏'},${gameInfo.ch_keywords || '游戏'},租号管家,游戏账号租赁,${gameInfo.pingtai || ''}`;
  
  setSeoInfo({
    title,
    description,
    keywords
  });
};

/**
 * 设置新闻详情页SEO信息
 * @param {Object} newsInfo - 新闻信息
 */
export const setNewsSeoInfo = (newsInfo) => {
  if (!newsInfo) return;
  
  const title = `${newsInfo.title || '新闻详情'} - 租号管家`;
  const description = `租号管家 - ${newsInfo.title || '新闻详情'}${newsInfo.summary ? '，' + newsInfo.summary : ''}`;
  const keywords = `${newsInfo.tags || '新闻'},租号管家,游戏资讯`;
  
  setSeoInfo({
    title,
    description,
    keywords
  });
};

export default {
  setSeoInfo,
  setGameSeoInfo,
  setNewsSeoInfo
}; 