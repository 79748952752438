<template>
  <div>
    <!-- 添加SEO组件 -->
    <SeoMetaInfo
      :title="seoTitle"
      :description="seoDescription"
      :keywords="seoKeywords"
    />
    <router-view v-if="$route.meta.isShowLayout" />
    <div v-else>
      <div class="content-header">
        <!-- 根据路由路径判断是否为网吧页面，加载对应的Header和NavBar组件 -->
        <template v-if="isWbPage">
          <WbHeader />
          <WbNavBar />
        </template>
        <template v-else>
          <Header />
          <NavBar />
        </template>
      </div>
      <div class="content-wrapper">
        <a-config-provider :locale="zhCN">
          <router-view />
        </a-config-provider>
        <!-- <LayoutFooter /> -->
        <CustomerService v-if="!isPdd" />
      </div>
      <div class="footer-wrapper">
        <LayoutFooter />
      </div>
      
    </div>
    <RealNameModal />
    <LoginRegModal />
    <BeforeLoginModal />
    <GobalMessageModal />
    <SignInModalv2 />
  </div>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import NavBar from './views/layout/NavBar.vue';
import WbNavBar from './views/layout/WbNavBar.vue';
import LayoutFooter from './views/layout/LayoutFooter.vue';
import CustomerService from './components/CustomerService.vue';
import RealNameModal from './components/RealNameModal.vue';
import LoginRegModal from './components/LoginRegModal.vue';
import BeforeLoginModal from './components/BeforeLoginModal.vue';
import GobalMessageModal from './components/GobalMessageModal.vue';
import Header from './views/layout/Header.vue';
import WbHeader from './views/layout/WbHeader.vue';
import SignInModalv2 from './components/SignInModalv2.vue';
import SeoMetaInfo from './components/SeoMetaInfo.vue';

// 判断当前路由是否为网吧页面
const isWbPage = computed(() => {
  return route.path.startsWith('/wb/');
});

const route = useRoute();

// 控制是否显示客服组件
const isPdd = ref(process.env.VUE_APP_IS_PDD === 'true');

// SEO 相关
const seoTitle = computed(() => {
  return route.meta.title ? `${route.meta.title} - 租号管家` : '租号管家';
});

const seoDescription = computed(() => {
  return route.meta.description || '租号管家 - 专业的游戏账号租赁平台';
});

const seoKeywords = computed(() => {
  return route.meta.keywords || '租号管家,游戏账号,账号租赁,游戏租号';
});

// 监听路由变化
watch(() => route.path, () => {
  // 可以在这里执行额外的SEO相关逻辑
}, { immediate: true });

// 在组件挂载时执行
onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const invitationCode = urlParams.get('user_invitation_code');

  if (invitationCode) {
    console.log('user_invitation_code add', invitationCode)
    localStorage.setItem('user_invitation_code', invitationCode);
  }
});
</script>

<style>
  /* 全局样式 */
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .content-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .content-wrapper {
    width: 100%;
    min-height: 150vh;
    margin-top: 90px;
    padding-bottom: 93px;
    /* transform: scale(0.7);
    transform-origin: 50% 0; */
  }

  /* 大屏幕上的最大宽度 */
  /* @media (min-width: 1200px) {
    .content-wrapper {
      max-width: 1350px; 
    }
  } */

  /* 小屏幕上的最大宽度百分比 */
  /* @media (max-width: 1199px) {
    .content-wrapper {
      max-width: 95%; 
    }
  } */
  .ant-menu-horizontal {
    border-bottom: none !important;
  }
</style>
<style scoped>
  :deep .ant-table-wrapper .ant-table-thead >tr>th {
    background-color: #F7F7F7;
  }
  :deep .ant-table-wrapper .ant-table-tbody >tr >td {
    background-color: #FCFCFC;
  }
</style>