<template>
  <div>
    <a-modal
      v-model:open="isOpen"
      title="实名认证"
      @ok="handleOk"
      @cancel="handleCancel"
      cancel-text="取消"
      ok-text="确认"
      :maskClosable="false"
      :confirmLoading="isLoading"
      :destroyOnClose="true"
    >
      <div :class="$style.tip">应国家网络管理要求，未满18周岁不可以使用游戏服务，需要实名认证号才能使用。</div>
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
      >
        <a-form-item
          label="姓名"
          name="realname"
          v-bind="validateInfos.realname"
        >
          <a-input v-model:value="formState.realname" />
        </a-form-item>
        <a-form-item
          label="身份证号"
          name="id_num"
          v-bind="validateInfos.id_num"
        >
          <a-input
            v-model:value="formState.id_num"
            @blur="validate('id_num', { trigger: 'blur' }).catch(() => {})"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script setup>
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { Form, message } from "ant-design-vue";
import { checkIdCard } from "@/util/utils";
import axios from "@/axios";

const store = useStore();
const useForm = Form.useForm;
const isOpen = computed(() => store?.state?.realnameVerificationModal.isRealOpen || false);
const isLoading = ref(false);

// 添加跟踪被拒绝身份证的状态
const rejectedIdCard = ref("");
const rejectionCount = ref(0); // 记录同一身份证被拒次数
const forceSubmit = ref(false);

const validateIdNum = async (_rule, value) => {
  if (!checkIdCard(value)) {
    return Promise.reject("请输入正确的身份证号");
  } else {
    return Promise.resolve();
  }
};

const formState = reactive({
  realname: "",
  id_num: "",
});
const rulesRef = reactive({
  realname: [
    {
      required: true,
      message: "请输入姓名",
    },
  ],
  id_num: [
    {
      required: true,
      message: "请输入身份证号",
    },
    {
      validator: validateIdNum,
      trigger: "blur",
    },
  ],
});

const { validate, validateInfos, resetFields } = useForm(formState, rulesRef);

// 监听身份证号变化
watch(() => formState.id_num, (newValue) => {
  // 如果身份证号变了，重置强制提交状态
  if (newValue !== rejectedIdCard.value) {
    forceSubmit.value = false;
    rejectedIdCard.value = "";
    rejectionCount.value = 0;
  }
});

const handleOk = async () => {
  const values = await validate();
  isLoading.value = true;
  
  // 构建请求参数，如果是同一个被拒绝的身份证且拒绝次数达到2次，添加强制提交参数
  const requestData = { ...values };
  if (forceSubmit.value && formState.id_num === rejectedIdCard.value) {
    requestData.force_submit = true;
  }
  
  const res = await axios.post("web/verify_idcard/", requestData)
  // 如果是身份证重复提交的错误
  if (res?.data?.status !== 10000) {
    // 记录被拒绝的身份证
    if (formState.id_num === rejectedIdCard.value) {
      // 如果是同一个身份证号，增加计数
      rejectionCount.value++;
      // 只有当同一个身份证被拒绝两次后，才启用强制提交
      if (rejectionCount.value >= 2) {
        forceSubmit.value = true;
      }
    } else {
      // 新的被拒绝身份证
      rejectedIdCard.value = formState.id_num;
      rejectionCount.value = 1;
      forceSubmit.value = false;
    }
  }
  
  isLoading.value = false;
  if (res?.data?.status === 10000) {
    // 成功后重置所有状态
    resetFields();
    forceSubmit.value = false;
    rejectedIdCard.value = "";
    rejectionCount.value = 0;
    store.dispatch("realnameVerificationModal/closeRealModal");
  }
};

const handleCancel = async () => {
  resetFields();
  forceSubmit.value = false;
  rejectedIdCard.value = "";
  rejectionCount.value = 0;
  store.dispatch("realnameVerificationModal/closeRealModal");
};
</script>
<style module>
.tip {
  font-size: 15px;
  background-color: #FDECDB;
  padding: 10px;
  padding-right: 0;
  border-radius: 4px;
  margin: 20px 0;
}
</style>