<!-- 父组件中使用示例 -->
<template>
  <!-- 触发按钮 -->
  <a-button type="primary" @click="handleOpenModal('1508188')">打开升级弹窗</a-button>

  <!-- 弹窗组件 -->
  <UpgradeModal 
    v-model:open="modalVisible"
    :recordId="currentRecordId"
    @close="handleModalClose"
  />
</template>

<script setup>
import { ref } from 'vue';
import UpgradeModal from '@/components/UpYongJiuModal.vue';

const modalVisible = ref(false);
const currentRecordId = ref('');

// 打开弹窗
const handleOpenModal = (recordId) => {
  currentRecordId.value = recordId;
  modalVisible.value = true;
  
}

// 关闭回调处理
const handleModalClose = () => {
  console.log('弹窗已关闭');
  // 这里可以添加关闭后的逻辑，如刷新数据等
}
</script>