<template>
  <div :class="$style.mobileContainer">
    <!-- 游戏购买模块 -->
    <div :class="$style.mobileSection">
      <div :class="$style.sectionTitle">购买游戏CDK</div>
      <div :class="$style.formItem">
        <div :class="$style.label">CDK余额:</div>
        <div :class="$style.labelS">
          {{ user?.cdk_amount || '0.00' }} 元
        </div>
      </div>
      <div :class="$style.formItem">
        <div :class="$style.label">选择游戏:</div>
        <div :class="$style.labelS">
          <a-select
            v-model:value="curGame"
            show-search
            placeholder="搜索游戏"
            :options="gameOptions"
            :filter-option="false"
            @search="handleSearchGame"
            @select="handleSelectGame"
          />
        </div>
      </div>
      
      <div :class="$style.formItem">
        <div :class="$style.label">选择SKU:</div>
        <div :class="$style.labelS">
          <a-select
            v-model:value="curSku"
            placeholder="选择SKU"
            :options="skuOptions"
            @select="handleSelectSku"
          />
        </div>
      </div>
      
      



      <div :class="$style.priceRow">
        <div :class="$style.priceItem">
          <div :class="$style.priceLabel">单价:</div>
          <a-input-number 
            v-model:value="unitPrice" 
            :min="0"
            :class="$style.priceInput"
            :disabled="true"
          />
        </div>
        <div :class="$style.priceItem">
          <div :class="$style.priceLabel">数量:</div>
          <a-input-number 
            v-model:value="amount" 
            :min="1" 
            :class="$style.priceInput"
          />
        </div>
      </div>

      <div :class="$style.priceRow">
        <div :class="$style.priceItem">
          <div :class="$style.priceLabel">金额:</div>
          <a-input-number 
            v-model:value="moneyAmount" 
            :min="0"
            :class="$style.priceInput"
            :disabled="true"
          />
        </div>
        <div :class="$style.priceItem">
          <div :class="$style.priceLabel">推荐售价:</div>
          <a-input-number 
            v-model:value="reMoney" 
            :class="$style.priceInput"
            :disabled="true"
          />
        </div>
      </div>

      <a-button 
        type="primary" 
        block 
        :class="$style.buyButton"
        @click="onClickBuy"
      >
        立即购买
      </a-button>
    </div>

    <!-- 搜索模块 -->
    <div :class="$style.mobileSection">
      <a-input-search
        v-model:value="searchParams.cdknb"
        placeholder="输入CDKey搜索"
        @search="handleSearch"
      />
      <a-input-search
        v-model:value="searchParams.gname"
        placeholder="输入游戏名称搜索"
        @search="handleSearch"
      />
    </div>

    <!-- 未使用CDK列表 -->
    <div :class="$style.mobileSection">
      <div :class="$style.sectionHeader">
        <span :class="$style.sectionTitle">未使用CDK</span>

      </div>

      <a-table 
        :columns="unUseColumns"
        :data-source="dataSource?.list || []"
        :pagination="pagination"
        :loading="loading"
        :class="$style.antTable"
        @change="handleTableChange"
      >
        <template #cdk="{ text }">
          <div :class="$style.cdkCell">
            <span>{{ text }}</span>
            <div><a-button type="link" size="small" @click="onCopyCdk(text)">复制</a-button></div>
            
          </div>
        </template>

        <template #action="{ record }">
          <a-button 
            size="small" 
            danger 
            :loading="unUseBtnLoading"
            @click="onRefundUnUse(record)"
          >
            退款
          </a-button>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from 'vuex';
import { useWindowSize } from '@vueuse/core';
import useClipboard from 'vue-clipboard3';
import { message } from "ant-design-vue";
import axios from "@/axios";
import { usePagination } from 'vue-request';


const { toClipboard } = useClipboard();
const store = useStore();
const { width } = useWindowSize();

const user = computed(() => store.getters.getUser);

// 移动端检测
const isMobile = computed(() => width.value < 768);

// 游戏选择相关
const curGame = ref();
const curSku = ref();
const gameOptions = ref([]);
const skuOptions = ref([]);
const originalGameOptions = ref([]);

// 价格相关
const unitPrice = ref(0);
const amount = ref(2);
const moneyAmount = ref(0);
const reMoney = ref(0);

// 搜索参数
const searchParams = ref({
  cdknb: "",
  gname: ""
});

// 分页相关
const {
  data: dataSource,
  run,
  loading,
  current,
  pageSize,
  total,
} = usePagination(
  (params) => axios.post("web/belong_user_proxy_cdkeys/", {
    ...params,
    used: 0
  }),
  {
    formatResult: (res) => ({
      list: res?.data?.data?.items || [],
      total: res?.data?.data?.total || 0,
    }),
    pagination: {
      currentKey: 'page',
      pageSizeKey: 'limit',
    },
  }
);

const pagination = computed(() => ({
  total: total.value,
  current: current.value,
  pageSize: pageSize.value,
  pageSizeOptions: ['5', '10', '20', '50'],
  showSizeChanger: true,
  showTotal: total => `共 ${total} 条`,
}));

// 表格列配置
const unUseColumns = [
  {
    title: 'CDK',
    dataIndex: 'cknb',
    key: 'cdk',
    width: '21%',
    slots: { customRender: 'cdk' }
  },
  { 
    title: '游戏名称', 
    dataIndex: 'game',
    key: 'game',
    width: '20%',
    ellipsis: true
  },
  { 
    title: '类型',
    dataIndex: 'morg_display',
    width: '20%',
    key: 'morg_display',
  },
  { 
    title: '时长(天)', 
    dataIndex: 'otime',
    width: '6%',
    key: 'otime',
  },
  {
    title: '操作',
    key: 'action',
    width: '15%',
    slots: { customRender: 'action' }
  }
];

// 初始化加载
onMounted(() => {
  fetchGameData({}, (data) => {
    gameOptions.value = data; // 初始化当前选项
    originalGameOptions.value = data; // 初始化原始数据
  });
});


const handleSelectSku = (val) => {
    const selectedSku = skuOptions.value.find((sku) => sku.value === val);
    console.log(selectedSku);
    if (selectedSku) {
      unitPrice.value = selectedSku.price; // 设置单价
      console.log(89898, selectedSku.tuijian_dingjia)
      reMoney.value = selectedSku.tuijian_dingjia
      amount.value = 1; // 默认购买数量为 1
      // moneyAmount.value = unitPrice.value * amount.value; // 计算总金额
    }
  };
// 加载表格数据
const loadUnUseData = async () => {
  try {
    const res = await axios.post("web/belong_user_proxy_cdkeys/", {
      ...params0.value,
      used: 0
    });
    
    if (res.data?.status === 10000) {
      return res;
    }
  } catch (error) {
    message.error("数据加载失败");
  }
};

// 分页变化处理
const handleTableChange = (pag) => {
  run({
    ...searchParams.value,
    page: pag.current,
    limit: pag.pageSize,
  });
};

// 搜索处理
const handleSearch = () => {
  run({
    ...searchParams.value,
    page: 1,
    limit: pageSize.value,
  });
};

// 游戏搜索
const handleSearchGame = (val) => {
  const searchValue = val.toLowerCase();
  gameOptions.value = originalGameOptions.value.filter(item => 
    item.label.toLowerCase().includes(searchValue)
  );
};

// 购买操作
const onClickBuy = async () => {
  if (!curGame.value || !curSku.value) {
    message.error("请先选择游戏和SKU");
    return;
  }

  try {
    await axios.post("web/proxy_generate_cdk/", {
      skuid: curSku.value,
      total_price: moneyAmount.value,
      num: amount.value,
    });
    
    message.success("购买成功");
    store.dispatch('fetchUserInfo');
    handleSearch(); // 刷新列表
  } catch (error) {
    message.error("购买失败");
    handleSearch(); // 刷新列表
  }
};

// 选择游戏
const handleSelectGame = (val) => {
  curSku.value = null;
  fetchSkuData(val);
};

// 获取SKU数据
const fetchSkuData = async (gameId) => {
  if (!gameId) return;
  const res = await axios.post("web/get_user_proxy_buy_cdk_sku_enum/", { 
    game_id: gameId
  });
  if (res?.data?.status === 10000) {
    skuOptions.value = res.data.data.map(item => ({
      value: item.id,
      label: item.sku_name,
      price: item.cdk_price,
      tuijian_dingjia: item.tuijian_dingjia
    }));
  }
};

// 监听价格变化
watch([unitPrice, amount], ([newPrice, newAmount]) => {
  if (newPrice && newAmount) {
    moneyAmount.value = Number((newPrice * newAmount).toFixed(2));
  }
});

// 退款操作
const unUseBtnLoading = ref(false);
const onRefundUnUse = async (record) => {
  unUseBtnLoading.value = true;
  try {
    await axios.post("web/proxy_cdkey_hs/", { nid: record.id });
    handleSearch(); // 刷新列表
  } catch (error) {
    handleSearch(); // 刷新列表
  } finally {
    unUseBtnLoading.value = false;
  }
};

// 复制功能
const onCopyCdk = async (text) => {
  try {
    await toClipboard(text);
    message.success("已复制到剪贴板");
  } catch (e) {
    message.error("复制失败");
  }
};

  // 监听单价变化
  watch(unitPrice, (newVal) => {
    if (newVal && amount.value) {
      moneyAmount.value = newVal * amount.value;
    }
  });

  // 监听数量变化
  watch(amount, (newVal) => {
    if (newVal && unitPrice.value) {
      moneyAmount.value = Math.round(newVal * unitPrice.value * 100) / 100;
    }
  });

  // 获取游戏选择options
  let timeout;
  const fetchGameData = (values = {}, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    async function fake() {
      const res = await axios.post("web/get_user_proxy_buy_cdk_game_enum/", {
        ...values,
      }).catch(() => {});
      if (res?.data?.status === 10000) {
        const options = res?.data?.data?.map?.(item => ({ value: item?.game_id, label: item?.game_name }));
        console.log(res?.data);
        callback(options);
      }
    }

    timeout = setTimeout(fake, 300);
  }

</script>

<style module>
/* 样式部分保持不变 */
.mobileContainer {
  padding: 12px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.mobileSection {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
}

.sectionTitle {
  font-size: 100px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 30px;
}

.label {
  font-size: 60px;
  color: rgba(0, 0, 0, 0.88);
  /* margin-bottom: 8px; */
  margin-right: 20px;
  width: 20%;
}

.labelS{
  width: 80%;
}

.formItem {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

/* 使用 :global 穿透作用域 */ 
.formItem :global(.ant-select){
  width: 100% !important; 
}

.priceRow {
  display: flex;
  gap: 12px;
  margin: 16px 0;
}

.priceItem {
  display: flex;
  width: 50%;
  align-items: center;
  margin-bottom: 16px;
}

.priceLabel {
  font-size: 60px;
  margin-bottom: 4px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.88);
}

.buyButton {
  margin-top: 20px;
}


/* 表格区域 */
.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.tableWrapper {
  overflow-x: auto;
}



.thCell {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.dataRow:hover {
  background-color: #fafafa;
}

.cdkCell {
  /* display: flex; */
  align-items: center;
  gap: 8px;
}

.copyBtn {
  padding: 0;
  height: auto;
}

.ellipsisCell {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingCell {
  text-align: center;
  padding: 24px !important;
  color: rgba(0, 0, 0, 0.4);
}

.noData {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 24px !important;
}

.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

</style>