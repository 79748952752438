<!-- 我的会员 -->
<template>
  <div>
    <div :class="$style.wrap">
      <div :class="$style.content">
        <div :class="$style.contentLeft">
          <!-- <div :class="$style.contentLeftTitW">
          </div> -->
          <div :class="$style.contentLeftTit">会员租用记录</div>
          <div :class="$style.contentLeftTitT">到期日：{{userinfo?.level_exp || '-'}}</div>
          <div :class="$style.contentLeftCon">
            <a-spin v-if="leftLoading" />
            <div v-else="!leftLoading" v-for="item of memberLog" :key="item.id" :class="$style.contentLeftItem">
              <div :class="$style.contentLeftItemN">{{ item?.member || '-' }}</div>
              <div :class="$style.contentLeftItemZ">
                {{item?.etime || '-'}}
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.conDir"></div>
        <MyGameRight />
      </div>
      <Recommend />
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from "vue";
  import { useStore } from "vuex";
  import axios from "@/axios";

  import Recommend from '@/views/homeView/Recommend.vue';
  import MyGameRight from '@components/MyGameRight.vue';

  const store = useStore();
  const userinfo = computed(() => store?.state?.user?.user);
  const leftLoading = ref(false);
  const memberLog = ref([]);

  // 获取我的会员租用记录列表
  onMounted(async () => {
    leftLoading.value = true;
    const res = await axios.post("web/member_logs/", { limit: 999 }).catch(() => {});
    leftLoading.value = false;
    if (res?.data?.status === 10000) {
      memberLog.value = res?.data?.data?.items || [];
    }
  });
</script>

<style module>
  .wrap {
    padding: 13px 230px 0;
  }
  .content {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    display: flex;
    padding: 28px 0;
    margin-bottom: 20px;
  }
  .conDir {
    border-right: 1px solid #E8E8E8;
    margin-left: 26px;
    margin-right: 22px;
  }
  .contentLeft {
    width: 300px;
    flex-shrink: 0;
    padding-left: 35px;
  }
  /* .contentLeftTitW {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  } */
  .contentLeftTit {
    font-weight: bold;
    font-size: 24px;
    color: #2E2E2E;
  }
  .contentLeftTitT {
    font-size: 14px;
    color: #2E2E2E;
    margin-top: 10px;
  }
  .contentLeftCon {
    text-align: center;
    margin-top: 55px;
    overflow-y: auto;
    max-height: 770px;
  }
  .contentLeftItem {
    width: 265px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 28px;
    margin-bottom: 28px;
    border-bottom: 1px solid #EBEBEB;
  }
  .contentLeftItemN {
    font-size: 20px;
    color: #0F0F0F;
  }
  .contentLeftItemZ {
    flex-shrink: 0;
    font-size: 16px;
    color: #FF870F;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
</style>
