import store from '@/store/index.js';
import axios from '@/axios';
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/homeView';
import UserRegister from '../components/RegisterUsername.vue';
import PhoneRegister from '../components/RegisterPhone.vue';
import EmailRegister from '../components/RegisterEmail.vue';
import Login from '../views/Login.vue';
import Recover from '../views/Recover.vue';
import UseCDK from '../views/cdk/useCdk.vue';
import BuyCdk from '../views/cdk/buyCdk.vue';
import RecordCdk from '../views/cdk/recordCdk.vue';
import News from '../views/news/index.vue';
import ChangePhone from '../views/ChangePhone.vue';

import NotFound from '../views/NotFound.vue';
import UserProfile from '../views/user_centor/UserProfile.vue';
import ProductDetail from '../views/product_centor/ProductDetail.vue';
import Detail from '../views/detail/index.vue';
import MyGame from '../views/myGame/index.vue';
import RendResult from '../views/rendResult/index.vue';
import WalletDetail from '../views/walletDetail/index.vue';
import GameStore from '../views/gameStore/index.vue';
import MyOrder from '../views/myOrder/index.vue';
import Exclusive from '../views/exclusive/index.vue';
import MyCollect from '../views/myCollect/index.vue';
import MyMember from '../views/myMember/index.vue';
import RentalRecords from '../views/rentalRecords/index.vue';
import ZhwAccountList from '../views/zhwAccountList/index.vue';
import ZhwAccountDetail from '../views/zhwAccountDetail/index.vue';
import gysGameDetail from '../views/gysGameDetail/index.vue';
import GYSRendResult from '../views/gysRendResult/index.vue'
import RentHistory from '../views/dongnanyaView/HistoryRent.vue';
import RentAcive from '../views/dongnanyaView/ActiveRent.vue';
import PermanentRights from '../views/dongnanyaView/YongjiuQuanyiList.vue';
import CdkUse from '../views/dongnanyaView/Cdk.vue';
import TestPay from '../views/TestPay.vue';
import TestModal from '../views/TestModal.vue';
import BuyCdkMB from '@/views/cdk/buyCdkMB.vue';


const routes = [
    // 网吧专用页面路由
    {
        path: '/wb/:id',
        component: () => import('../views/wbhome/index.vue'),
        meta: {
            title: '网吧专用页面_租号管家',
            description: '租号管家 - 网吧专用页面，为网吧用户提供专属服务',
            keywords: '网吧专用,租号管家,游戏账号租赁'
        }
    },
    { 
        path: '/registerUsername', 
        meta: { 
            isShowLayout: true,
            title: '注册_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 用户名注册页面，轻松注册账号，畅玩各类游戏',
            keywords: '用户名注册,租号管家,注册账号'
        }, 
        component: UserRegister 
    },
    { 
        path: '/registerPhone', 
        meta: { 
            isShowLayout: true,
            title: '注册_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 手机号注册页面，安全便捷的手机注册方式',
            keywords: '手机号注册,租号管家,注册账号'
        }, 
        component: PhoneRegister 
    },
    { 
        path: '/registerEmail', 
        meta: { 
            isShowLayout: true,
            title: '注册_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 邮箱注册页面，使用邮箱快速注册账号',
            keywords: '邮箱注册,租号管家,注册账号'
        }, 
        component: EmailRegister 
    },
    { 
        path: '/register', 
        meta: { 
            isShowLayout: true,
            title: '注册_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 账号注册页面，轻松注册畅玩各类游戏',
            keywords: '账号注册,租号管家,注册账号'
        }, 
        component: PhoneRegister 
    },

    { 
        path: '/login', 
        meta: { 
            isShowLayout: true,
            title: '登录_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 账号登录页面，安全便捷的登录方式',
            keywords: '账号登录,租号管家,用户登录'
        }, 
        component: Login 
    },
    { 
        path: '/recover', 
        meta: { 
            isShowLayout: true,
            title: '账号找回_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 账号找回页面，快速找回您的账号',
            keywords: '账号找回,租号管家,密码重置'
        }, 
        component: Recover 
    },
    // { path: '/profile', component: UserProfile, meta: { requiresAuth: true } },
    // { path: '/profile', component: UserProfile, },
    // { path: '/product/:id', component: ProductDetail }, // 新增
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: '租号管家官网_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家，国内权威游戏服务平台，涵盖游戏帐号出租，游戏帐号交易等一系列服务。覆盖端游、手游、Steam全品类，严选账号+保险护航，帐密直登畅玩，7×24h专业客服保障，安全省心更便捷！',
            keywords: '租号管家,游戏账号租赁,游戏账号,账号租用'
        }
    },
    { 
        path: '/:pathMatch(.*)*', 
        name: 'NotFound', 
        component: NotFound,
        meta: {
            title: '页面未找到',
            description: '租号管家 - 页面未找到',
            keywords: '租号管家,404,页面未找到'
        }
    },
    { 
        path: '/detail/:id', 
        component: Detail,
        meta: {
            title: '游戏详情_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 游戏详情页，提供详细的游戏账号信息',
            keywords: '游戏详情,租号管家,游戏账号'
        }
    }, // 游戏详情
    { 
        path: '/myGame', 
        component: MyGame, 
        meta: { 
            requiresAuth: true,
            title: '我的游戏_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 我的游戏页面，管理您租用的游戏账号',
            keywords: '我的游戏,租号管家,游戏账号'
        }
    }, // 我的游戏
    { 
        path: '/rendResult', 
        component: RendResult, 
        meta: { 
            requiresAuth: true,
            title: '租用成功_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 租用成功页面，查看租用结果',
            keywords: '租用成功,租号管家,账号租用'
        }
    }, // 租号成功结果页
    { 
        path: '/gysRendResult', 
        component: GYSRendResult, 
        meta: { 
            requiresAuth: true,
            title: 'GYS租用记录',
            description: '租号管家 - GYS租用记录页面，查看您的租用历史',
            keywords: 'GYS租用记录,租号管家,租用历史'
        }
    }, // gys租用记录
    { 
        path: '/walletDetail', 
        component: WalletDetail, 
        meta: { 
            requiresAuth: true,
            title: '钱包详情_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 钱包详情页面，管理您的账户余额',
            keywords: '钱包详情,租号管家,账户余额'
        }
    }, // 钱包详情
    {
      path: '/gameStore',
      component: GameStore,
      props: route => ({ qrCategory: route.query.category }),
      meta: {
        title: '租号商城_Steam租号_绝地求生租号_手游端游出租_租号管家',
        description: 'Steam租号、手游租号、端游租号、页游租号。永久租号：不限时间，不限设备，随时可登。小时租号：一小时起租，免押金，最低1元起。装备多，皮肤多，等级高。',
        keywords: '游戏商城,租号管家,游戏账号,账号租赁'
      }
    }, // 游戏商城
    { 
        path: '/myOrder', 
        component: MyOrder, 
        meta: { 
            requiresAuth: true,
            title: '我的订单_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 我的订单页面，查看订单详情和历史记录',
            keywords: '我的订单,租号管家,订单历史'
        }
    }, // 我的订单
    { 
        path: '/exclusive', 
        component: Exclusive, 
        meta: { 
            requiresAuth: true,
            title: '我的独享_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 我的独享页面，享受独享账号服务',
            keywords: '我的独享,租号管家,独享账号'
        }
    }, // 我的独享
    { 
        path: '/myCollect', 
        component: MyCollect, 
        meta: { 
            requiresAuth: true,
            title: '我的收藏_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 我的收藏页面，查看您收藏的游戏账号',
            keywords: '我的收藏,租号管家,游戏收藏'
        }
    }, // 我的收藏
    { 
        path: '/myMember', 
        component: MyMember, 
        meta: { 
            requiresAuth: true,
            title: '我的会员_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 我的会员页面，查看会员特权和状态',
            keywords: '我的会员,租号管家,会员特权'
        }
    }, // 我的会员
    { 
        path: '/rentalRecords', 
        component: RentalRecords, 
        meta: { 
            requiresAuth: true,
            title: '租用记录_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 租用记录页面，查看您的租用历史',
            keywords: '租用记录,租号管家,租用历史'
        }
    }, // 租用记录
    
    { 
        path: '/news/:id.html', 
        name: 'News', 
        component: News,
        meta: {
            title: '新闻详情_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 新闻详情页，了解最新游戏资讯',
            keywords: '新闻详情,租号管家,游戏资讯'
        }
    },
    { 
        path: '/zhwAccountList/:id', 
        component: ZhwAccountList,
        meta: {
            title: '租号玩账号列表_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 租号玩账号列表，挑选适合您的游戏账号',
            keywords: '租号玩账号,租号管家,账号列表'
        }
    }, // 租号玩账号列表
    {
      path: '/zhwAccountDetail/:id',
      component: ZhwAccountDetail,
      props: route => ({ gameId: route.query.gameId }),
      meta: {
        title: '租号玩账号详情_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
        description: '租号管家 - 租号玩账号详情，查看账号详细信息',
        keywords: '租号玩账号详情,租号管家,账号信息'
      }
    }, // 租号玩账号详情
    {
      path: '/gysGameDetail/:id',
      component: gysGameDetail,
      props: route => ({ gameId: route.query.gameId }),
      meta: {
        title: 'GYS游戏详情_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
        description: '租号管家 - GYS游戏详情页，提供详细的游戏信息',
        keywords: 'GYS游戏详情,租号管家,游戏信息'
      }
    },
    // {
    //     path: "/ecommerce/products",
    //     name: "products",
    //     meta: { title: "Products", authRequired: true },
    //     component: () =>
    //         import ("../views/ecommerce/products"),
    // },
    { 
        path: '/cdk', 
        component: UseCDK,
        meta: {
            title: 'CDK兑换_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - CDK兑换页面，使用CDK兑换游戏特权',
            keywords: 'CDK兑换,租号管家,游戏特权'
        }
    },
    { 
        path: '/buyCdk', 
        component: BuyCdk, 
        meta: { 
            requiresAuth: true,
            title: '购买CDK_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 购买CDK页面，获取游戏特权',
            keywords: '购买CDK,租号管家,游戏特权'
        }
    },
    { 
        path: '/buyCdkMb', 
        component: BuyCdkMB, 
        meta: { 
            requiresAuth: true,
            title: '购买CDK_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 手机购买CDK页面，随时获取游戏特权',
            keywords: '购买CDK,租号管家,手机购买'
        }
    },
    { 
        path: '/recordCdk', 
        component: RecordCdk, 
        meta: { 
            requiresAuth: true,
            title: 'CDK兑换记录_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - CDK兑换记录页面，查看您的兑换历史',
            keywords: 'CDK兑换记录,租号管家,兑换历史'
        }
    }, // cdk兑换记录
    { 
        path: '/TestPay', 
        component: TestPay,
        meta: {
            title: '支付测试',
            description: '租号管家 - 支付测试页面',
            keywords: '支付测试,租号管家'
        }
    },
    { 
        path: '/TestModal', 
        component: TestModal,
        meta: {
            title: '模态框测试',
            description: '租号管家 - 模态框测试页面',
            keywords: '模态框测试,租号管家'
        }
    },
    { 
        path: '/change-phone', 
        component: ChangePhone, 
        meta: { 
            requiresAuth: true,
            title: '修改手机号_租号管家_Steam租号_帐号交易_买号_卖号_永久租号_帐号出租',
            description: '租号管家 - 修改手机号页面，安全修改您的手机号',
            keywords: '修改手机号,租号管家,账号安全'
        }
    },
]

const routes1 = [
    // 东南亚相关业务投流测试
    { 
        path: '/dny/register', 
        meta: { 
            isShowLayout: true,
            title: '账号注册 - 东南亚版',
            description: '租号管家 - 东南亚版账号注册页面',
            keywords: '东南亚,账号注册,租号管家'
        }, 
        component: EmailRegister 
    },
    { 
        path: '/dny/login', 
        meta: { 
            isShowLayout: true,
            title: '账号登录 - 东南亚版',
            description: '租号管家 - 东南亚版账号登录页面',
            keywords: '东南亚,账号登录,租号管家'
        }, 
        component: Login 
    },
    { 
        path: '/dny/rent_history', 
        component: RentHistory, 
        meta: { 
            requiresAuth: true,
            title: '租用历史 - 东南亚版',
            description: '租号管家 - 东南亚版租用历史页面',
            keywords: '东南亚,租用历史,租号管家'
        } 
    },
    { 
        path: '/dny/rent_active', 
        component: RentAcive, 
        meta: { 
            requiresAuth: true,
            title: '活跃租用 - 东南亚版',
            description: '租号管家 - 东南亚版活跃租用页面',
            keywords: '东南亚,活跃租用,租号管家'
        } 
    },
    { 
        path: '/dny/yongjiuquanyi', 
        component: PermanentRights, 
        meta: { 
            requiresAuth: true,
            title: '永久权益 - 东南亚版',
            description: '租号管家 - 东南亚版永久权益页面',
            keywords: '东南亚,永久权益,租号管家'
        } 
    },
    { 
        path: '/dny/cdk_use', 
        component: CdkUse,
        meta: {
            title: 'CDK使用 - 东南亚版',
            description: '租号管家 - 东南亚版CDK使用页面',
            keywords: '东南亚,CDK使用,租号管家'
        }
    },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // 始终滚动到顶部
      return { top: 0 }
    },
})

// 导航守卫
router.beforeEach(async(to, from, next) => {
    const res = await axios.post("web/user_sign/").catch(() => {});
    console.log(res)
    if (res?.data?.status === 10000) {
      // 弹窗
      store.commit("signModal/open_model", { rewardAmount: res?.data?.data?.sign_dkj, totalDiscount: res?.data?.data?.deduction_amount })
    } else if (res?.data?.status === 10999) {
      // 删除认证信息
      localStorage.removeItem('access_token');
      // delete axiosInstance.defaults.headers.common['Authorization'];
      store.commit('SET_USER', null);  // 清除用户信息
      store.commit('SET_AUTHENTICATED', false);
    } else {

    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('access_token');
        if (!token) {
            // 如果没有 token，跳转到登录页面
            // return next({
            //     path: '/login',
            //     query: { redirect: to.fullPath }
            // });
            store.commit('SET_LOGINMODALTYPE', "login");
            store.dispatch("authModal/showLoginModal");
        }
    }

    // 更新动态页面标题 (针对需要动态设置的路由)
    if (to.path.startsWith('/detail/') || to.path.startsWith('/zhwAccountDetail/') || to.path.startsWith('/gysGameDetail/')) {
        // 这里可以根据实际情况动态设置页面标题，例如从store中获取当前游戏名称
        // 或者在组件中通过nextTick更新标题
    }

    if (to.path.startsWith('/news/')) {
        // 新闻页面标题可以在组件中动态设置
    }

    // 头部导航切换
    store.commit('SET_SELECTEDKEYS', [to?.path || '']);
    next(); // 确保正常导航
});



export default router