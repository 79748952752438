<!-- 租号成功结果页 -->
<template>
  <div :class="$style.wrap">
    <div :class="$style.top">
      <div :class="$style.topBgc"></div>
      <div :class="$style.topLeft">
        <div :class="$style.topLeftTit">租号管家</div>
        <div :class="$style.topLeftDes">你要的游戏  这都有</div>
        <img :class="$style.topLeftImg" :src="renwuImg" />
      </div>
      <div :class="$style.topRUp">
        <div :class="$style.ReGameList">
          <div :class="$style.topItem" @click="$onClickGame(item)" v-for="item in gameData">
            <Image
              :class="$style.topItemImg"
              :src="getFullImageUrl(item?.img)"
              :preview="false"
            />
            <div :class="$style.cardGridCon">
              <div :class="$style.cardGridTitle">{{ item?.gname ? item.gname.slice(0, 14) : '' }}</div>
              <div :class="$style.cardGridTitleE">{{ item?.ename ? item.ename.slice(0, 25) : '' }}</div>
            </div>
            <div :class="$style.topItemName" v-if="item?.provider === 'zuhaowan' || item?.provider === 1">
              <div :class="$style.topItemNameN">
                <div :class="$style.topItemFLD"> 小时版 </div>
                <div :class="$style.gameResItemInPriceP">
                  ¥{{ item?.sku_price_info?.show_price_info?.use_dkj_price.toFixed(2) }}
                </div>
                <div :class="$style.gameResItemInPriceBR">
                  /时起
                </div>
              </div>

              <div :class="$style.topItemNameP">
                <div :class="$style.topItemNamePT">立即租用</div>
              </div>
            </div>
            <div :class="$style.topItemName" v-else>
              <div :class="$style.topItemNameN">
                <div :class="$style.topItemFLD"> 永久版 </div>
                <div :class="$style.gameResItemInPriceP">
                  ¥{{ item?.sku_price_info?.show_price_info?.use_dkj_price.toFixed(2) }}
                </div>
                <div :class="$style.gameResItemInPriceBR">
                  {{
                    item?.sku_price_info?.show_price_info?.game_discount_rate
                      ? Math.floor(
                          item?.sku_price_info?.show_price_info
                            ?.game_discount_rate
                        )
                      : 0
                  }}%OFF
                </div>
              </div>

              <div :class="$style.topItemNameP">
                <div :class="$style.topItemNamePT">原价：{{item?.sku_price_info?.show_price_info?.price || '-'}}元</div>
              </div>
            </div>
          </div>
          
        </div>
        <div :class="$style.gameCurList">
          <div :class="[$style.gameCurListL, gameEnums?.length === 15 && $style.gameCurListLm]">
            <div
              v-for="item of gameEnums"
              :class="[
                $style.gameCurListI,
                gameEnums?.length === 15 && $style.gameCurListIm,
                // curPageGame === item?.game_name && $style.gameCurListIActive,
              ]"
              @click="$onClickGame(item)"
            >
              <div
                :class="[
                  $style.gameCurListII,
                  // curPageGame === item?.game_name && $style.gameCurListIIActive,
                ]"
              >
                <Image
                  :class="$style.gameCurListIII"
                  :src="getFullImageUrl(item?.game_icon_img)"
                  :preview="false"
                />
              </div>
              <a-tooltip>
                <template #title>{{ item?.game_name || "-" }}</template>
                <div :class="$style.gameCurListIT">
                  {{ item?.game_name || "-" }}
                </div>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>      
    </div>
    <div :class="$style.tip">
      <template v-if="!isPdd">
        <img :class="$style.tipI" :src="trumpetIcon" alt="" />
        <div :class="$style.tipT">招募平台推广员，边聊天边赚钱！联系微信：zuhao8999</div>
        <div :class="$style.tipWarning">不得使用外挂或其它三方软件，违反平台规则将进行处罚。</div>
      </template>
      <template v-else>
        <img :class="$style.tipI" :src="trumpetIcon" alt="" />
        <div :class="$style.tipT">不得使用外挂或其它三方软件，违反平台规则将进行处罚。</div>
      </template>
    </div>
    <div :class="$style.content" v-if="hasData || !hasGysData">
      <div :class="$style.contentH">
        <div :class="$style.contentHDW" @click="$openInNewTab('/news/57.html')">
          <img :class="$style.contentHD" :src="cdDownIcon" />
          <div :class="$style.contentHA">存档更换</div>
        </div>
        <div :class="$style.contentHB">您可以移档，上传下载你的存档，欢迎使用！</div>
        <div :class="$style.contentHDir"></div>
        <div :class="$style.contentHCW" @click="$openInNewTab('https://www.tengyoujiasu.com')">
          <img :class="$style.contentHC" :src="jsqIcon" />
          <div :class="$style.contentHE">腾游加速器</div>
        </div>
        <div :class="$style.contentHF">免费48小时加速器兑换码：ZH888</div>
      </div>
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource?.items"
        :pagination="false"
        :loading="loading"
        :scroll="{ y: 400 }"
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'gname'">
            <div v-if="text">
              <!-- 点击跳转到游戏详情页面 -->
              <div 
                style="cursor: pointer;"
                @click="$openInNewTab(`/detail/${record.gname_id}`)"
              >
                <span :class="$style.itemTa" >{{ text }}</span>
              </div>
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'saccount'">
            <div v-if="text">
              <div :class="$style.itemYQ" @click="fetchYanqi(record.id)">延期/加时长</div>
              <div v-if="record.is_allow_qiehuan_account" :class="$style.itemYH" @click="onClickSwitch(record.id, record.gname)">切换账号</div>
              <div :class="$style.itemT">{{ text }}</div>
              <div :class="$style.itemCopy" @click="onCopy(text)">复制账号</div>
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'spassword'">
            <div v-if="text">
              <div @click="$openInNewTab('/news/5.html')" :class="$style.itemFDH">防顶号必看</div>
              <div :class="$style.itemYZM" @click="fetchYzma(record.id)">验证码/令牌</div>
              <div :class="$style.itemT">{{ text }}</div>
              <div :class="$style.itemCopy" @click="onCopy(text)">复制密码</div>
            </div>
            <div v-else>-</div>
          </template>
        </template>
      </a-table>
      <div :class="$style.contentF">
        <img :class="$style.contentFA" :src="bzwdIcon" />
        <div :class="$style.contentFB">帮助文档</div>
        <div @click="$openInNewTab('/news/5.html')" :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">防顶号说明</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
        <div @click="$openInNewTab('/news/57.html')" :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">存档说明</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
        <div @click="$openInNewTab('/news/1.html')" :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">Steam加好友</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
        <div @click="$openInNewTab('/news/69.html')" :class="$style.contentFF">
          <div :class="$style.contentFFT">免费48h加速器</div>
          <img :class="$style.contentFFI" :src="dRightIcon" />
        </div>
      </div>
    </div>

    <div :class="$style.content" v-if="hasGysData">
      <div :class="$style.contentH">
        <div :class="$style.contentHDW" @click="$openInNewTab('/news/70.html')">
          <img :class="$style.contentHD" :src="cdDownIcon" />
          <div :class="$style.contentHA">手游上号器下载</div>
        </div>
        <div :class="$style.contentHB">王者荣耀等手游请下载手游上号器。</div>
        <div :class="$style.contentHDir"></div>
        <div :class="$style.contentHCW" @click="$openInNewTab('/news/70.html')">
          <img :class="$style.contentHC" :src="cdDownIcon" />
          <div :class="$style.contentHE">端游上号器下载</div>
        </div>
        <div :class="$style.contentHF">CSGO、黑神话悟空等电脑玩游戏请下载!</div>
      </div>
      <a-table
        :columns="gys_columns"
        :row-key="record => record.id"
        :data-source="gysdataSource?.items"
        :pagination="false"
        :loading="gysloading"
        :scroll="{ y: 400 }"
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'gname'">
            <div v-if="text">
              <!-- 点击跳转到游戏详情页面 -->
              <div 
                style="cursor: pointer;"
                @click="$openInNewTab(`/gysGameDetail/${record.gname_id}`)"
              >
                <div :class="$style.itemTa" >{{ text }}</div>
                <div>{{ record.sku_name }}</div>
              </div>
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'cdkey'">
            
            
            <div v-if="record.account_info?.remark">
              <div :class="$style.itemT">{{ record?.order || record?.cdkey }}</div>
              <div :class="$style.itemT">{{ record.account_info.remark }}</div>
              <div :class="$style.itemYZM" @click="fetchZhwYzma(record.id)">获取验证码</div>
            </div>
            <div v-else>
              <div :class="$style.itemT">{{ record?.order || record?.cdkey }}</div>
            </div>
          </template>
          <template v-if="column.dataIndex === 'gys_rent_return_cdk'">
              <div :class="$style.itemTRED">帐号问题请10分钟内反馈</div>
              <div :class="$style.itemT">{{ text }}</div>
              <div :class="$style.itemCopy" @click="onCopy(text)">复制上号码</div>
          </template>
          <template v-if="column.dataIndex === 'remark1'">
            <div :class="$style.itemT">复制左侧CDK，下载上号器粘贴开始玩！（建议关闭杀毒软件避免上号器被拦截）</div>
            <div :class="$style.itemCopy" @click="$openInNewTab('https://zu.didikaihei.cn/')">下载上号器</div>
          </template>
        </template>
      </a-table>
      <div :class="$style.contentF">
        <img :class="$style.contentFA" :src="bzwdIcon" />
        <div :class="$style.contentFB" @click="$openInNewTab('/recordCdk')">CDK兑换记录</div>
        <div :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT" @click="$openInNewTab('/recordCdk')">在这里查看您所有的租号兑换记录!</div>
          <!-- <img :class="$style.contentFFI" :src="dRightIcon" /> -->
        </div>

      </div>
    </div>
    <div :class="$style.activityWrap">
      <ActivityRewardTip />
    </div>
    <Recommend />
    <RentAccountDialog
      :isOpen="isRentAccountDialog"
      :pkId="rentPkId"
      :curGame="curGame"
      :rentType="'switch'"
      @setIsOpen="setIsRentAccountDialog"
      @successCallBack="onRentAccountSuccess"
    />
    <VerificationCode
      :is-open="idVerificationCodeOpen"
      :set-isOpen="(flag) => { idVerificationCodeOpen = flag}"
      :codeInfo="codeInfo"
    />
    <SnapcdkDelayModal
      :rentPkId="rentPkId"
      :is-open="isSnapcdkDelayOpen"
      :reset-list="run"
      :set-isOpen="(flag) => { isSnapcdkDelayOpen = flag}"
    />
    <DelaySuccessModal
      :is-open="isDelaySuccessOpen"
      :end-time="endTime"
      :set-isOpen="
        (flag) => {
          isDelaySuccessOpen = flag;
        }
      "
    />
    <UpgradeModal 
      v-model:open="isUpgradeModalOpen"
      :recordId="rentPkId"
      @close="handleUpgradeModalClose"
      @refresh="refreshRentalData"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from "vue";
  import { usePagination } from 'vue-request';
  import useClipboard from 'vue-clipboard3';
  import { message } from 'ant-design-vue';
  import { useRouter } from 'vue-router'
  import axios from "@/axios";
  
  // 控制是否为拼多多环境
  const isPdd = ref(process.env.VUE_APP_IS_PDD === 'true');
  import RentAccountDialog from '@components/SelectAccountModal.vue';
  import VerificationCode from '@components/VerificationCode.vue';
  import ActivityRewardTip from '@/components/ActivityRewardTip.vue';
  import SnapcdkDelayModal from './components/SnapcdkDelayModal.vue';
  import DelaySuccessModal from './components/DelaySuccessModal.vue';

  import Recommend from '@/views/homeView/Recommend.vue';
  import Image from "@components/Image.vue";
  import { getFullImageUrl } from "@/util/utils.js";
  import gwcIcon from "@assets/gwcIcon.png";
  import trumpetIcon from "@assets/trumpetIcon.png";
  import cdDownIcon from "@assets/cdDownIcon.png";
  import jsqIcon from "@assets/jsqIcon.png";
  import bzwdIcon from "@assets/bzwdIcon.png";
  import dRightIcon from "@assets/dRightIcon.png";
  import renwuImg from "@assets/renwuImg.png";

  const { toClipboard } = useClipboard();
  const router = useRouter();
  const gameData = ref();
  const curGame = ref();
  const idVerificationCodeOpen = ref(false);
  const codeInfo = ref({});
  const isSnapcdkDelayOpen = ref(false);
  const isDelaySuccessOpen = ref(false);
  const endTime = ref(undefined);
  // 升级弹窗
  import UpgradeModal from '@/components/UpYongJiuModal.vue';
  const isUpgradeModalOpen = ref(false);
  const handleUpgradeModalClose = (flag) => {
    isUpgradeModalOpen = false
  };

  const hasData = computed(() => dataSource?.value?.items?.length > 0);
  const hasGysData = computed(() => gysdataSource?.value?.items?.length > 0);

  // 小方块
  const gameEnums = ref([]);
  onMounted(async () => {
    const res = await axios.post("web/get_game_show_rule_list/", { type: "rental_recommendations_mini_custom_ids" });
    gameEnums.value = res?.data?.data?.splice(0, 15) || [];
  });

  const columns = [
    {
      title: '游戏名称',
      dataIndex: 'gname',
      width: '20%',
      ellipsis: true,
      
    },
    {
      title: '级别',
      dataIndex: 'level',
      width: '10%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '游戏平台',
      dataIndex: 'pingtai',
      width: '8%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '账号',
      dataIndex: 'saccount',
      width: '15%',
    },
    {
      title: '密码',
      dataIndex: 'spassword',
      width: '15%',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: '10%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: '结束时间',
      dataIndex: 'etime',
      width: '10%',
      customRender: ({ text }) => {
        return text || '-';
      }
    },
    {
      title: 'CDKEYY/订单号',
      dataIndex: 'cdkey',
      width: '12%',
      customRender: ({ text, record }) => {
        if (text) {
          return text;
        } else if (record?.order) {
          return record?.order;
        } else {
          return '-';
        }
      }
    },
  ];

  const gys_columns = [
    {
      title: '游戏名称',
      dataIndex: 'gname',
      width: '20%',
      ellipsis: true,
      
    },
    // {
    //   title: 'sku',
    //   dataIndex: 'skuname',
    //   width: '15%',
    //   ellipsis: true,
      
    // },
    {
      title: '租用时间',
      dataIndex: 'stime',
      width: '20%',
    },
    {
      title: '订单码',
      dataIndex: 'cdkey',
      width: '15%',
      customRender: ({ text, record }) => {
        if (text) {
          return text;
        } else if (record?.order) {
          return record?.order;
        } else {
          return '-';
        }
      }
    },
    {
      title: '租号时长',
      dataIndex: 'langtime_remark',
      width: '10%',
    },
    {
      title: '上号CDK',
      dataIndex: 'gys_rent_return_cdk',
      width: '30%',
    },
    {
      title: '上号说明',
      dataIndex: 'remark1',
      width: '30%',
      customRender: ({ text }) => {
        return text || '复制左侧CDK，下载上号器粘贴开始玩！（建议关闭杀毒软件避免上号器被拦截）';
      }
    },

  ];

  // 租号
  const rentPkId = ref();
  const isRentAccountDialog = ref(false);

  const setIsRentAccountDialog = (flag) => {
    isRentAccountDialog.value = flag;
  }

  const onClickSwitch = (accountId, gameName) => {
    rentPkId.value = accountId;
    curGame.value = gameName;
    isRentAccountDialog.value = true;
  }

  const onRentAccountSuccess = () => {
    // 租号成功，弹框提示弹窗
    // router.push('/rendResult');
    run();
  }

  const fetchYanqi = async (recordId) => {
      const response = await axios.post('web/account/yanqiv2/', { yqid: recordId });
      if (response?.data?.status === 10000) {
        if (response?.data?.data?.is_lins) {
          // 打开【延期一】弹窗
          // 延期成功后，【弹窗二】延期成功
          rentPkId.value = recordId;
          // isSnapcdkDelayOpen.value = true;
          isUpgradeModalOpen.value = true;
        } else {
          // 弹窗提示时间
          isDelaySuccessOpen.value = true;
          endTime.value = response?.data?.data?.endtime;
        } 
      }
      run();
  }

  const fetchYzma = async (recordId) => {
    const response = await axios.post('web/account/yzma/', { yzid: recordId });
    if (response?.data?.status === 10000) {
      idVerificationCodeOpen.value = true;
      codeInfo.value = {
        code: response?.data?.data?.verification_code,
        msg: response?.data?.msg,
      }
    }
  }

  const fetchZhwYzma = async (recordId) => {
    const response = await axios.post('web/account/yzma/', { zhw_uid: recordId });
    if (response?.data?.status === 10000) {
      idVerificationCodeOpen.value = true;
      codeInfo.value = {
        code: response?.data?.data?.verification_code,
        msg: response?.data?.msg,
      }
    }
  }

  // 获取租用记录
  const queryRentData = (params) => {
    return axios.post('web/rent_no/', { limit: 9999 });
  };

  const {
    data: dataSource,
    run,
    loading,
  } = usePagination(queryRentData, {
    formatResult: res => res?.data?.data,
  });

  // 获取租用记录
  const querygysRentData = (params) => {
    return axios.post('web/gys/zhw_rent_no/', { limit: 9999 });
  };

  const {
    data: gysdataSource,
    run: gysrun,
    loading: gysloading,
  } = usePagination(querygysRentData, {
    formatResult: res => res?.data?.data,
  });


  // 复制
  const onCopy = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

  const onClickTW = () => {
    window.open('https://www.tengyoujiasu.com');
  }

    // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'rental_recommendations_ids', limit: 4 }).catch(() => {});
    if (res?.data?.status === 10000) {
      gameData.value = res?.data?.data || [];
    }
  });

  // 刷新租赁数据
  const refreshRentalData = () => {
    // 刷新普通租赁数据
    run();
    // 刷新GYS租赁数据
    gysrun();
  }

</script>

<style module>
  .wrap {
    padding: 13px 230px 0;
  }
  .top {
    display: flex;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    border-radius: 10px;
    position: relative;
  }
  .topBgc {
    position: absolute;
    top: 0;
    left: 0;
    width: 470px;
    height: 100%;
    border-radius: 10px;
    background-image: url('../../assets/rendResTopBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .topLeft {
    z-index: 9;
    width: 183px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
  }
  .topLeftTit {
    font-size: 30px;
    font-weight: 700;
    margin-top: 23px;
    color: #000000;
  }
  .topLeftDes {
    margin-top: 8px;
    font-size: 12px;
    color: #75747f;
  }
  .topLeftImg {
    width: 183px;
    height: 212px;
  }
  .topRUp {
    margin-top: 2px;

    /* flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin-top: 20px; */
  }
  .ReGameList {
    display: flex;
  }

  .topItem {
    cursor: pointer;
    z-index: 9;
    width: 278px;
    margin-right: 40px;
    margin-top: 15px;
  }
  .topItemImg {
    cursor: pointer;
    width: 100%;
    height: 129px !important;
    border-radius: 5px;
    margin-bottom: 13px;
  }
  .topItemName {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-right: 4px;
  }

  
  .topItemNameN {
    color: #363636;
    cursor: pointer;
    display: flex;
  }
  .topItemNameNC {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 2px;
    width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
  .topItemNameNE {
    font-size: 7px;
    line-height: 15px;
    cursor: pointer;
  }
  .topItemNameP {
    flex-shrink: 0;
    cursor: pointer;
  }
  .topItemNamePT {
    cursor: pointer;
    width: 69px;
    line-height: 20px;
    /* height: 16px; */
    /* line-height: 16px; */
    text-align: center;
    font-size: 8px;
    color: #989898;
    background-color: rgba(173, 173, 173, 0.22);
    border-radius: 16px;
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .topItemNamePN {
    cursor: pointer;
    /* width: 86px; */
    text-align: center;
    font-size: 9px;
    line-height: 13px;
    color: #939393;
    text-decoration: line-through solid #939393;
  }
  .topItemDir {
    cursor: pointer;
    width: 100%;
    height: 1px;
    background-color: #F8F8F8;
    margin-bottom: 14px;
  }
  .topItemF {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .topItemFLD {
    width: 85px;
    /* height: 20px; */
    background-image: url('../../assets/dkBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 26px;
    font-size: 16px;
    color: #934C05;
    padding-left: 12px;
    margin-bottom: 6px;
  }
  .topItemFLT {
    font-size: 9px;
    height: 13px;
    color: #363636;
    line-height: 13px;
    font-weight: 400;
  }
  .topItemFLP {
    font-size: 18px;
    color: #FF3636;
    font-weight: bold;

    /* font-weight: 600; */
    height: 17px;
    line-height: 17px;
  }
  .topItemFR {
    width: 107px;
    height: 44px;
    background-image: url('../../assets/gmBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .topItemFRImg {
    width: 19px;
    height: 18px;
    margin-right: 14px;
  }
  .topItemFRT {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
  }
  .tip {
    display: flex;
    align-items: center;
    margin: 12px 0 16px;
    height: 50px;
    border-radius: 12px;
    background-color: rgba(255, 135, 15, 0.10);
    padding-left: 19px;
    padding-right: 15px;
  }
  .tipWarning {
    color: #FF3B3B;
    font-size: 22px;
    font-weight: 500;
    margin-left: auto; /* 添加这行代码实现右浮动 */
  }
  .tipI {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  .tipT {
    font-size: 22px;
    color: #FF3B3B;
    font-weight: bold;
  }
  .content {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    padding: 5px 0 10px;
    margin-bottom: 15px;
  }
  .activityWrap {
    margin-bottom: 15px;
  }
  .contentH {
    display: flex;
    align-items: center;
    padding-left: 22px;
    font-size: 21px;
    margin-bottom: 5px;
  }
  .contentHDW {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentHD {
    width: 23px;
    height: 23px;
    margin-right: 19px;
  }
  .contentHA {
    color: #767676;
    line-height: 33px;
    margin-right: 34px;
  }
  .contentHB {
    color: #000;
    line-height: 33px;
    margin-right: 88px;
  }
  .contentHDir {
    height: 40px;
    width: 1px;
    background-color: #E3E3E3;
    margin-right: 74px;
  }
  .contentHCW {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentHC {
    width: 23px;
    height: 23px;
    margin-right: 28px;
  }
  .contentHE {
    color: #767676;
    line-height: 33px;
    margin-right: 55px;
  }
  .contentHF {
    color: #000;
    line-height: 33px;
  }
  .itemYQ {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #FF870F;
    border: 1px solid #FF870F;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .itemYH {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #237AFF;
    border: 1px solid #237AFF;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .itemT {
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 5px;
  }
  .itemTRED {
    font-size: 20px;
    line-height: 30px;
    color: #ff0000;
    margin-bottom: 5px;
  }
  .itemTa {
    font-size: 20px;
    line-height: 30px;
    color: #0000ff;
    margin-bottom: 5px;
    /* 新增换行属性 */
    white-space: normal;    /* 允许换行 */
    word-wrap: break-word;  /* 长单词或URL强制换行 */
    overflow-wrap: break-word; /* 更现代的换行属性 */
    /* 可选：限制最大宽度避免撑开布局 */
    max-width: 300px; 
}
  .itemCopy {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #197AFF;
    border-radius: 4px;
    cursor: pointer;
  }
  .itemFDH {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #FF5A5A;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .itemYZM {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #F5B812;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .contentF {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-left: 28px;
    font-size: 22px;
    border-top: 1px solid #E3E3E3;
  }
  .contentFA {
    width: 23px;
    height: 23px;
    margin-right: 23px;
  }
  .contentFB {
    color: #767676;
    margin-right: 36px;
  }
  .contentFFM {
    margin-right: 182px;
  }
  .contentFF {
    display: flex;
    align-items: center;
    color: #237AFF;
    cursor: pointer;
  }
  .contentFFT {
    margin-right: 19px;
  }
  .contentFFI {
    width: 17px;
    height: 15px;
  }
  .gameResItemInPricePDO {
    font-size: 12px;
  }
  /* 小方块游戏图标 */
  .gameCurList {
    display: flex;
    justify-content: space-between;
    margin-right: 35px;
    margin-bottom: 10px;
  }
  .gameCurListL {
    flex-grow: 1;
    display: flex;
  }
  .gameCurListI {
    width: 70px;
    cursor: pointer;
    margin-right: 15px;
    font-size: 13px;
    color: #2b2b2b;
  }
  .gameCurListLm {
    justify-content: space-between;
  }
  .gameCurListIm {
    margin-right: 0 !important;
  }
  .gameCurListIAll {
    width: 70px;
    margin-left: 15px;
    font-size: 13px;
    color: #2b2b2b;
  }

  .gameCurListII {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #e3e3e3;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .gameCurListIIActive {
    border: 2px solid #237aff;
  }

  .gameCurListIActive {
    color: #237aff;
  }

  .gameCurListIII {
    width: 100% !important;
    height: auto; /* 高度自动调整，保持比例 */
    object-fit: contain; /* 确保图片完整显示，保持比例，可能会有空白区域 */
    display: block; /* 避免图片被当作行内元素，影响布局 */
  }

  .gameCurListIT {
    width: 70px;
    text-align: center;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .gameResItemInPriceP {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    color: #ff3636;
    margin-right: 2px;
    margin-left: 6px;
    line-height: 30px;
  }
  .gameResItemInPriceBR {
    font-size: 12px;
    line-height: 30px;
    color: #07b200;
    padding-top: 2px;
    font-weight: bold;
    /* vertical-align: bottom;
    display : inline-block ; */
  }

  .cardGrid {
    width: 330px;
    height: 167px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }
  .cardGrid:last-child {
    margin-right: 0;
  }
  .cardGridImg {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .cardGridCon {
    position: absolute;
    top: 100px;
    margin-left: 10px;
  }
  .cardGridTitle {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; /* 根据父容器实际宽度调整 */
  }

  .cardGridTitleE {
    font-size: 11px;
    line-height: 11px;
    color: #fff;
    margin-bottom: 8px;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; /* 根据父容器实际宽度调整 */
  }

</style>
