<!-- 租号成功结果页 -->
<template>
  <div :class="$style.wrap">
    <div :class="$style.top">
      <div :class="$style.topBgc"></div>
      <div :class="$style.topLeft">
        <div :class="$style.topLeftTit">租号管家</div>
        <div :class="$style.topLeftDes">你要的游戏  这都有</div>
        <img :class="$style.topLeftImg" :src="renwuImg" />
      </div>
      <div :class="$style.topItem" v-for="item in gameData">
        <Image
          :class="$style.topItemImg"
          :src="getFullImageUrl(item?.img)"
          :preview="false"
        />
        <div :class="$style.topItemName">
          <div :class="$style.topItemNameN">
            <a-tooltip>
              <template #title>{{item?.gname || '-'}}</template>
              <div :class="$style.topItemNameNC">{{item?.gname || '-'}}</div>
            </a-tooltip>
            <div :class="$style.topItemNameNE">{{item?.ename || '-'}}</div>
          </div>
          <div :class="$style.topItemNameP">
            <div :class="$style.topItemNamePT">永久版原价</div>
            <div :class="$style.topItemNamePN">¥{{item?.sku_price_info?.show_price_info?.price || '-'}}</div>
          </div>
        </div>
        <div :class="$style.topItemDir"></div>
        <div :class="$style.topItemF">
          <div :class="$style.topItemFL">
            <div :class="$style.topItemFLD">可抵扣：¥{{item?.sku_price_info?.show_price_info?.use_dkj_amount || '-'}}</div>
            <!-- <div :class="$style.topItemFLT">新用户首单优惠后</div> -->
            <div :class="$style.topItemFLP">¥{{item?.sku_price_info?.show_price_info?.use_dkj_price || '-'}}</div>
          </div>
          <div :class="$style.topItemFR" @click="$openInNewTab(`/detail/${item?.id}`)">
            <img :class="$style.topItemFRImg" :src="gwcIcon" />
            <div :class="$style.topItemFRT">立即购买</div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.tip">
      <img :class="$style.tipI" :src="trumpetIcon" alt="" />
      <div :class="$style.tipT">招募平台推广员，边聊天边赚钱！联系微信：zuhao8999</div>
    </div>
    <div :class="$style.content">
      <div :class="$style.contentH">
        <div :class="$style.contentHDW" @click="$openInNewTab('/news/70.html')">
          <img :class="$style.contentHD" :src="cdDownIcon" />
          <div :class="$style.contentHA">手游上号器下载</div>
        </div>
        <div :class="$style.contentHB">王者荣耀等手游请下载手游上号器。</div>
        <div :class="$style.contentHDir"></div>
        <div :class="$style.contentHCW" @click="$openInNewTab('/news/70.html')">
          <img :class="$style.contentHC" :src="cdDownIcon" />
          <div :class="$style.contentHE">端游上号器下载</div>
        </div>
        <div :class="$style.contentHF">CSGO、黑神话悟空等电脑玩游戏请下载!</div>
      </div>
      <a-table
        :columns="columns"
        :row-key="record => record.id"
        :data-source="dataSource?.items"
        :pagination="false"
        :loading="loading"
        :scroll="{ y: 400 }"
      >
        <template #bodyCell="{ text, column, record }">
          <template v-if="column.dataIndex === 'gname'">
            <div v-if="text">
              <!-- 点击跳转到游戏详情页面 -->
              <div 
                style="cursor: pointer;"
                @click="$openInNewTab(`/gysGameDetail/${record.gname_id}`)"
              >
                <span :class="$style.itemTa" >{{ text }}</span>
              </div>
            </div>
            <div v-else>-</div>
          </template>
          <template v-if="column.dataIndex === 'gys_rent_return_cdk'">
            <div v-if="text">
              <div :class="$style.itemT">{{ text }}</div>
              <div :class="$style.itemCopy" @click="onCopy(text)">复制账号</div>
            </div>
            <div v-else>-</div>
          </template>
        </template>
      </a-table>
      <div :class="$style.contentF">
        <img :class="$style.contentFA" :src="bzwdIcon" />
        <div :class="$style.contentFB">CDK兑换记录</div>
        <div :class="[$style.contentFFM, $style.contentFF]">
          <div :class="$style.contentFFT">在这里查看您所有的租号兑换记录!</div>
          <!-- <img :class="$style.contentFFI" :src="dRightIcon" /> -->
        </div>

      </div>
    </div>
    <div :class="$style.activityWrap">
      <ActivityRewardTip />
    </div>
    <Recommend />

  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { usePagination } from 'vue-request';
  import useClipboard from 'vue-clipboard3';
  import { message } from 'ant-design-vue';
  import { useRouter } from 'vue-router'
  import axios from "@/axios";
  import ActivityRewardTip from '@/components/ActivityRewardTip.vue';

  import Recommend from '@/views/homeView/Recommend.vue';
  import Image from "@components/Image.vue";
  import { getFullImageUrl } from "@/util/utils.js";
  import gwcIcon from "@assets/gwcIcon.png";
  import trumpetIcon from "@assets/trumpetIcon.png";
  import cdDownIcon from "@assets/cdDownIcon.png";
  import jsqIcon from "@assets/jsqIcon.png";
  import bzwdIcon from "@assets/bzwdIcon.png";
  import dRightIcon from "@assets/dRightIcon.png";
  import renwuImg from "@assets/renwuImg.png";

  const { toClipboard } = useClipboard();
  const router = useRouter();
  const gameData = ref();
  const curGame = ref();
  const idVerificationCodeOpen = ref(false);
  const codeInfo = ref({});
  const isSnapcdkDelayOpen = ref(false);
  const isDelaySuccessOpen = ref(false);
  const endTime = ref(undefined);

  const columns = [
    {
      title: '游戏名称',
      dataIndex: 'gname',
      width: '20%',
      ellipsis: true,
      
    },
    {
      title: '上号CDK',
      dataIndex: 'gys_rent_return_cdk',
      width: '25%',
    },
    {
      title: '租号时长',
      dataIndex: 'langtime_remark',
      width: '10%',
    },
    {
      title: 'CDKEYY/订单号',
      dataIndex: 'cdkey',
      width: '20%',
      customRender: ({ text, record }) => {
        if (text) {
          return text;
        } else if (record?.order) {
          return record?.order;
        } else {
          return '-';
        }
      }
    },
    {
      title: '上号说明',
      dataIndex: 'remark',
      width: '30%',
      customRender: ({ text }) => {
        return text || '复制左侧CDK，下载上号器粘贴开始玩！（建议关闭杀毒软件避免上号器被拦截）';
      }
    },

  ];

  // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'rental_recommendations_ids', limit: 4 }).catch(() => {});
    if (res?.data?.status === 10000) {
      gameData.value = res?.data?.data || [];
    }
  });

  // 获取租用记录
  const queryRentData = (params) => {
    return axios.post('web/gys/zhw_rent_no/', { limit: 9999 });
  };

  const {
    data: dataSource,
    run,
    loading,
  } = usePagination(queryRentData, {
    formatResult: res => res?.data?.data,
  });

  // 复制
  const onCopy = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

</script>

<style module>
  .wrap {
    padding: 13px 230px 0;
  }
  .top {
    display: flex;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    border-radius: 10px;
    position: relative;
  }
  .topBgc {
    position: absolute;
    top: 0;
    left: 0;
    width: 470px;
    height: 100%;
    border-radius: 10px;
    background-image: url('../../assets/rendResTopBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .topLeft {
    z-index: 9;
    width: 183px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
  }
  .topLeftTit {
    font-size: 30px;
    font-weight: 700;
    margin-top: 23px;
    color: #000000;
  }
  .topLeftDes {
    margin-top: 8px;
    font-size: 12px;
    color: #75747f;
  }
  .topLeftImg {
    width: 183px;
    height: 212px;
  }
  .topItem {
    z-index: 9;
    width: 278px;
    margin-right: 40px;
    margin-top: 15px;
  }
  .topItemImg {
    width: 100% !important;
    height: 129px !important;
    border-radius: 5px;
    margin-bottom: 13px;
  }
  .topItemName {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-right: 4px;
  }
  .topItemNameN {
    color: #363636;
  }
  .topItemNameNC {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 2px;
    width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .topItemNameNE {
    font-size: 7px;
    line-height: 15px;
  }
  .topItemNameP {
    flex-shrink: 0;
  }
  .topItemNamePT {
    width: 69px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 8px;
    color: #989898;
    background-color: rgba(173, 173, 173, 0.22);
    border-radius: 16px;
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .topItemNamePN {
    /* width: 86px; */
    text-align: center;
    font-size: 9px;
    line-height: 13px;
    color: #939393;
    text-decoration: line-through solid #939393;
  }
  .topItemDir {
    width: 100%;
    height: 1px;
    background-color: #F8F8F8;
    margin-bottom: 14px;
  }
  .topItemF {
    display: flex;
    justify-content: space-between;
  }
  .topItemFLD {
    width: 92px;
    height: 15px;
    background-image: url('../../assets/dkBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 15px;
    font-size: 8px;
    color: #934C05;
    padding-left: 8px;
    margin-bottom: 6px;
  }
  .topItemFLT {
    font-size: 9px;
    height: 13px;
    color: #363636;
    line-height: 13px;
    font-weight: 400;
  }
  .topItemFLP {
    font-size: 18px;
    color: #FF3636;
    font-weight: bold;

    /* font-weight: 600; */
    height: 17px;
    line-height: 17px;
  }
  .topItemFR {
    width: 107px;
    height: 44px;
    background-image: url('../../assets/gmBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .topItemFRImg {
    width: 19px;
    height: 18px;
    margin-right: 14px;
  }
  .topItemFRT {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
  }
  .tip {
    display: flex;
    align-items: center;
    margin: 12px 0 16px;
    height: 50px;
    border-radius: 12px;
    background-color: rgba(255, 135, 15, 0.10);
    padding-left: 19px;
  }
  .tipI {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  .tipT {
    font-size: 22px;
    color: #FF3B3B;
    font-weight: bold;
  }
  .content {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    padding: 5px 0 10px;
    margin-bottom: 15px;
  }
  .activityWrap {
    margin-bottom: 15px;
  }
  .contentH {
    display: flex;
    align-items: center;
    padding-left: 22px;
    font-size: 21px;
    margin-bottom: 5px;
  }
  .contentHDW {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentHD {
    width: 23px;
    height: 23px;
    margin-right: 19px;
  }
  .contentHA {
    color: #767676;
    line-height: 33px;
    margin-right: 34px;
  }
  .contentHB {
    color: #000;
    line-height: 33px;
    margin-right: 88px;
  }
  .contentHDir {
    height: 40px;
    width: 1px;
    background-color: #E3E3E3;
    margin-right: 74px;
  }
  .contentHCW {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contentHC {
    width: 23px;
    height: 23px;
    margin-right: 28px;
  }
  .contentHE {
    color: #767676;
    line-height: 33px;
    margin-right: 55px;
  }
  .contentHF {
    color: #000;
    line-height: 33px;
  }
  .itemYQ {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #FF870F;
    border: 1px solid #FF870F;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .itemYH {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #237AFF;
    border: 1px solid #237AFF;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .itemT {
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 5px;
  }
  .itemTa {
    font-size: 20px;
    line-height: 30px;
    color: #0000ff;
    margin-bottom: 5px;
  }
  .itemCopy {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #197AFF;
    border-radius: 4px;
    cursor: pointer;
  }
  .itemFDH {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #FF5A5A;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .itemYZM {
    width: 123px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 19px;
    color: #fff;
    background-color: #F5B812;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  .contentF {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-left: 28px;
    font-size: 22px;
    border-top: 1px solid #E3E3E3;
  }
  .contentFA {
    width: 23px;
    height: 23px;
    margin-right: 23px;
  }
  .contentFB {
    color: #767676;
    margin-right: 36px;
  }
  .contentFFM {
    margin-right: 182px;
  }
  .contentFF {
    display: flex;
    align-items: center;
    color: #237AFF;
    cursor: pointer;
  }
  .contentFFT {
    margin-right: 19px;
  }
  .contentFFI {
    width: 17px;
    height: 15px;
  }
</style>
