// 认证弹窗
const authModule = {
    namespaced: true,  // 使用命名空间
    state: () => ({
      isLoginModalVisible: false,
      isBeforeLoginVisible: false, // 登陆前置弹窗visible
    }),
    mutations: {
      SHOW_LOGIN_MODAL(state) {
        state.isLoginModalVisible = true;
      },
      HIDE_LOGIN_MODAL(state) {
        state.isLoginModalVisible = false;
      },
      SHOW_BEFORE_MODAL(state) {
        state.isBeforeLoginVisible = true;
      },
      HIDE_BEFORE_MODAL(state) {
        state.isBeforeLoginVisible = false;
      },
    },
    actions: {
      showLoginModal({ commit }, payload) {
        // 是否显示登陆前置弹窗
        if (payload?.isShowBefore) {
          commit('SHOW_BEFORE_MODAL');
          return;
        }
        commit('SHOW_LOGIN_MODAL');
      },
      hideLoginModal({ commit }) {
        commit('HIDE_LOGIN_MODAL');
      },
      showBeforeModal({ commit }) {
        commit('SHOW_BEFORE_MODAL');
      },
      hideBeforeModal({ commit }) {
        commit('HIDE_BEFORE_MODAL');
      },
    }
  };
  
  export default authModule;
  